import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Sentiments from "./Sentiments";
import Rankings from "./Rankings";
import { useResolvedPath } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function BrandReachSentimentMeta({ data, isReach }) {
  const { pathname } = useResolvedPath();
  const selectedOptionShow = pathname.includes("brand-monitoring")
    ? "Brand"
    : "Product";
  return (
    <>
      <h3>
        {`${selectedOptionShow} ${isReach ? "Reach" : "Sentiment"} Score
        Breakups`}
      </h3>
      <p style={{ color: "gray", fontStyle: "italic", marginTop: "10px" }}>
        Note: The displayed metadata is from one of the datasets in the selected
        timeframe, while the scores on the UI represent the average of the same
        timeframe.
      </p>
      <hr />
      <div className="mx-4">
        <TableContainer>
          {isReach && (
            <>
              <h5>Reach</h5>
              <hr />
              <div className="mx-1">
                {data?.productRankingsArray && (
                  <>
                    <h6>A. Rankings </h6>
                    <hr />
                    <Rankings rankings={data?.productRankingsArray} />
                    <hr />
                  </>
                )}
                <h6>
                  {data?.productRankingsArray ? "B" : "A"}. Average Reach Scores
                </h6>
                <hr />
                <Table
                  sx={{ minWidth: 650 }}
                  size="medium"
                  aria-label="simple table"
                >
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell align="right">Score</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {data.rank.map((row) => (
                      <StyledTableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.score}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </>
          )}
          {!isReach && (
            <>
              <h5>Sentiment</h5>
              <hr />
              <div className="mx-1">
                {data?.sentimentMeta && (
                  <>
                    <h6>A. Sentiment Analysis</h6>
                    <hr />
                    <Sentiments sentiments={data?.sentimentMeta} />
                  </>
                )}
                {data?.sentimentMeta && (
                  <>
                    <hr />
                    <h6>B. Average Sentiment Scores</h6>
                    <hr />
                  </>
                )}

                <Table sx={{ minWidth: 800 }} aria-label="simple table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>Name</StyledTableCell>
                      {Array.from({
                        length: Object.keys(data.favorabilities[0]).filter(
                          (key) => key.startsWith("sentiment")
                        ).length,
                      }).map((_, i) => (
                        <StyledTableCell align="right" key={i}>
                          Score {i + 1}
                        </StyledTableCell>
                      ))}
                      <StyledTableCell align="right">Avg</StyledTableCell>
                      {Object.keys(data.favorabilities[0]).includes(
                        "score"
                      ) && <StyledTableCell>Score</StyledTableCell>}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {data.favorabilities.map((row) => (
                      <StyledTableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          {row.name}
                        </StyledTableCell>
                        {Array.from({
                          length: Object.keys(data.favorabilities[0]).filter(
                            (key) => key.startsWith("sentiment")
                          ).length,
                        }).map((_, i) => (
                          <StyledTableCell align="right" key={i}>
                            {row[`sentiment${i + 1}`]}
                          </StyledTableCell>
                        ))}
                        <StyledTableCell align="right">
                          {row.average}
                        </StyledTableCell>
                        {row.score && (
                          <StyledTableCell align="right">
                            {row?.score}
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </>
          )}
        </TableContainer>
      </div>
    </>
  );
}
