import React, { useRef, useContext, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import api from "../../../../BrandOnBoarding/api";
import Modal from "../../../../Component/Modal/Modal";
import TextInput from "../../../../Component/UI/TextInput/TextInput";
import { AuthContext } from "../../../../context/auth-context";
import classes from "./AddFilter.module.css";

export default function AddFilter({ config, open, onClose, type = "brand" }) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = React.useState("");
  const [isTouched, setIsTouched] = useState(false);
  const [error, setError] = React.useState(false);

  const descriptionInputRef = useRef();

  const { user } = useContext(AuthContext);

  // LUM - 129
  function onNameChange(event) {
    const value = event.target.value;
    setName(value);

    // Mark the field as touched when the user interacts
    if (!isTouched) setIsTouched(true);

    // Error handling
    if (value.trim().length === 0) {
      setError("Filter name is required!");
    } else {
      setError(false);
    }
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      const response = await api.post(`/users/search-filter/${user.id}`, {
        name,
        description: descriptionInputRef.current.value,
        type: type,
        config: config,
      });
      if (response.status === 201) {
        toast.success("Search filter added successfully");
        onClose();
      } else {
        toast.error("Failed to add search filter");
        onClose();
      }
    } catch (error) {
      toast.error("Failed to add search filter");
      onClose();
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <ToastContainer />
      <Modal
        title="Add Filter"
        open={open}
        handleClose={onClose}
        showActions={!loading}
        confirmBtnText="Save"
        confirmBtnDisabled={error || !name.trim()}
        actionsClassName={classes.actions}
        handleConfirm={handleSubmit}
      >
        <form className={classes.form}>
          <TextInput
            className="mb-4"
            label="Name"
            value={name}
            onChange={onNameChange}
            error={isTouched && error}
            placeholder="Enter filter name"
          />
          <TextInput
            label="Description (optional)"
            placeholder="Enter filter description"
            ref={descriptionInputRef}
          />
          {loading && <div className={classes.loading}>Please wait...</div>}
        </form>
      </Modal>
    </>
  );
}
