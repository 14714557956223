import React, { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { CardTable } from "./CardTable";
import RadialGraph from "./Gauge";
import { extractDomain, processAlertsData } from "../../../utils/helpers";
import { useNavigate } from "react-router";
import { Button, Spinner } from "react-bootstrap";
import EmptyView from "../../../Component/EmptyView/EmptyView";
import { useSelector } from "react-redux";
import { getHomepageNews } from "../../../services/apiService";
import AiIcon from "../../../assets/svgs/AIIcon";
import Markdown from "markdown-to-jsx";
import { Warning } from "@mui/icons-material";
import classes from "./SummaryCard.module.css";
import MUIButton from "./../../../Component/UI/MUIButton/MUIButton";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";

const ALERTS_RULE = [
  "Increase/Decrease RBI Score",
  "Increase/Decrease Core Attributes Score",
  "Increase/Decrease Purchase Criteria Score",
  "Increase/Decrease Positive Attribute Signal_Strength",
  "Increase/Decrease Negative Attribute Signal_Strength",
  "New Top 3 Positive Attributes",
  "New Top 3 Negative Attributes",
  "New Positive Attribute Arrival",
  "New Negative Attribute Arrival",
];

const alertMetricsMapping = {
  avg_brand_index_score: "Index Score",
  avg_brand_favorability_score: "Sentiment Score",
  avg_brand_reach_score: "Reach Score",
};

const dateMapping = {
  Current: "Current",
  "3Days": "Last 3 Days",
  "7Days": "Last 7 Days",
  Month: "Last 30 Days",
  "3Month": "Last 90 Days",
};

const SummaryCard = ({ data, isProduct, domainUrl, frequency, llmDomains }) => {
  const navigate = useNavigate();
  const brands = useSelector((state) => state.brands);
  const brandProducts = useSelector((state) => state.brandProducts);
  const [news, setNews] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchNews() {
      if (isProduct) {
        const targetBrandProduct = brandProducts?.data?.data?.filter(
          (obj) => obj?.product?.name == data?.productName
        );
        if (targetBrandProduct && targetBrandProduct.length) {
          const payload = {
            frequency: dateMapping[frequency],
            category:
              targetBrandProduct?.[0]?.customer_brand_product_categories?.[0]
                ?.category?.name,
            entity: targetBrandProduct?.[0]?.product?.name,
            competitors:
              targetBrandProduct?.[0]?.customer_competitor_brand_products?.map(
                (obj) => obj?.brand_product?.name
              ),
            isBrand: !isProduct,
          };
          setIsLoading(true);
          const newsResp = await getHomepageNews(payload);
          setIsLoading(false);
          setNews(newsResp);
        }
      }
    }

    // fetchNews();
  }, [data]);

  async function fetchNews() {
    if (!isProduct) {
      const targetBrand = brands?.data?.data?.filter(
        (obj) => obj?.brand?.name == data?.brandName
      );

      if (targetBrand && targetBrand.length) {
        const payload = {
          frequency: dateMapping[frequency],
          category: targetBrand?.[0]?.category?.name,
          entity: targetBrand?.[0]?.brand?.name,
          competitors: targetBrand?.[0]?.competitors?.map(
            (obj) => obj?.brand?.name
          ),
          isBrand: !isProduct,
        };

        setIsLoading(true);
        const newsResp = await getHomepageNews(payload);
        setIsLoading(false);
        setNews(newsResp);
      }
    }
  }

  // useEffect(() => {
  //   fetchNews();
  // }, [data]);

  // const mergedAlerts = processAlertsData(
  //   ALERTS_RULE,
  //   data,
  //   alertMetricsMapping
  // );

  const mergedAlerts = []

  const handleNavigation = () => {
    if (isProduct) {
      navigate(
        `/luminaire/product-monitoring?brandProductId=${data?.customerProductId}&dateRange=${frequency}`
      );
    } else {
      navigate(
        `/luminaire/brand-monitoring?brandId=${data?.customerBrandId}&dateRange=${frequency}`
      );
    }

    // // Force a page reload after navigation
    // window.location.reload();
  };

  return (
    <div>
      <div class="mb-3 d-flex justify-content-between align-items-center">
        <div class="mb-3 d-flex align-items-center">
          <img
            style={{
              width: "32px",
              height: "28px",
              borderRadius: "6px",
              marginBottom: "8px", // Adds spacing between logo and text
            }}
            src={`https://img.logo.dev/${extractDomain(
              domainUrl
            )}?token=pk_SaDf4t2eRsW5Fc84zgJRZA`}
            alt={""}
          />
          &nbsp; &nbsp;
          <h5 class="me-3">
            {isProduct ? data?.productName : data?.brandName}
          </h5>
        </div>

        <Button
          style={{
            backgroundColor: "#007AFF",
            color: "#EFF4F8",
            fontSize: "14px",
            width: "120px",
            height: "35px",
            textAlign: "center",
          }}
          onClick={handleNavigation}
        >
          Monitoring
        </Button>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="graphGreyBg">
            <div class="row align-items-center justify-content-center">
              <div class="col reachCount">
                <p class="m-0" style={{ color: "#292B2C" }}>
                  Reach
                </p>
                <p class="m-0 prdCount">
                  {Number(data?.avgScores?.avg_brand_reach_score).toFixed(0)}
                </p>
              </div>
              <div class="col reachCount">
                <p class="m-0" style={{ color: "#292B2C" }}>
                  Sentiment
                </p>
                <p class="m-0 prdCount">
                  {Number(
                    data?.avgScores?.avg_brand_favorability_score
                  ).toFixed(0)}
                </p>
              </div>
            </div>
            <div class="d-flex align-items-center mt-3 flex-column mb-3">
              <p style={{ color: "#626262" }} class="mb-2">
                Brand Index Score
              </p>
              {/* <img src="images/graph1.png" class="w-100" /> */}
              <RadialGraph
                label={`Brand Index Score`}
                value={{
                  RBI: Number(data?.avgScores?.avg_brand_index_score).toFixed(
                    0
                  ),
                }}
                pastValue={{
                  RBI: Number(
                    data?.pastAvgScores?.avg_brand_index_score
                  ).toFixed(0),
                }}
                Range={100}
                frequencyLabel={dateMapping[frequency]}
              />
            </div>
            <br />
            <br />
            <div style={{ marginTop: "-80px" }} class="graphsummary prdSummary">
              <CardTable llmDomains={llmDomains} llms={data?.llms} />
            </div>
          </div>
        </div>

        <div className="col-md-8">
          <div className={classes.tableContainer}>
            <table className="table table-striped">
              <thead>
                <tr className="prdtblHeader">
                  {mergedAlerts && mergedAlerts?.length ? (
                    <>
                      <th style={{ width: "100px" }}></th>
                      <th>Alerts</th>
                    </>
                  ) : (
                    <th style={{ textAlign: "center" }}>Alerts</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {mergedAlerts && mergedAlerts?.length ? (
                  mergedAlerts?.map((dataObj) => {
                    const statusIconMapping = {
                      increased: <CheckCircleIcon style={{ color: "green" }} />,
                      decreased: <CancelIcon style={{ color: "red" }} />,
                      default: <Warning style={{ color: "#eca336" }} />, // Default icon
                    };
                    return (
                      <tr>
                        <td class="text-center" valign="middle">
                          {statusIconMapping[dataObj?.status] ||
                            statusIconMapping.default}
                        </td>
                        <td  style={{ display: "flex", alignItems: "center" }}>
                          <img
                            className={
                              dataObj?.llmName !== "llm-web"
                                ? classes.logo4
                                : classes.logo3
                            }
                            src={
                              dataObj?.llmName == "llm-web"
                                ? "/menu.png"
                                : `https://img.logo.dev/${extractDomain(
                                    llmDomains?.[dataObj?.llmName]
                                  )}?token=pk_SaDf4t2eRsW5Fc84zgJRZA`
                            }
                            alt={""}
                          />{" "}
                          &nbsp; &nbsp;
                          <span style={{ flex: "1" }}> {dataObj?.message} </span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <EmptyView message="Alerts Not Available" />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <p></p>
      <div class="row">
        <div className="col-md-12">
          <div className={`${classes.tableContainer} graphGreyBg`}>
            <p className="mb-3 font14-600">
              News &nbsp; &nbsp;
              <MUIButton
                onClick={fetchNews}
                variant="outlined"
                className={classes.regenerate}
                startIcon={<AutoAwesomeOutlinedIcon />}
              >
                Generate
              </MUIButton>
            </p>
            <div className="graphsummary font-11">
              {isLoading ? (
                <span style={{ display: "flex", justifyContent: "center" }}>
                  <Spinner />
                </span>
              ) : news && typeof news === "string" ? (
                <Markdown className="markTable">{news}</Markdown>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default SummaryCard;
