import React, { useState } from "react";
import { TableHead, TableCell, TableRow, Tooltip } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import MUICard from "../../../../Component/UI/MUICard/MUICard";

import classes from "./Taxonomy.module.css";

import MUIToolTip from "../../../../Component/UI/MUIToolTip/MUIToolTip";
import MUIIconButton from "../../../../Component/UI/MUIIconButton/MUIIconButton";
import { extractDomain } from "../../../../utils/helpers";
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../Component/UI/Table/Table";
import EmptyView from "../../../../Component/EmptyView/EmptyView";
import AIGenerateButton from "../../../../Component/AIGenerateButton/AIGenerateButton";
import Spinner from "../../../../Component/Spinner/Spinner";

const TABLE_HEADERS = [
  {
    id: "taxonomy",
    name: "Product Dimensions",
  },
];

function Row({ category }) {
  const [open, setOpen] = useState(false);

  function roundNumber(num) {
    return num % 1 < 0.5 ? Math.floor(num) : Math.ceil(num);
  }

  return (
    <React.Fragment key={category.name}>
      <StyledTableRow>
        <StyledTableCell className={classes.attribute_headings}>
          <div className={classes.heading_wrapper}>
            <MUIIconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </MUIIconButton>
            <span className={classes.heading_text}>{category.name}</span>
          </div>
        </StyledTableCell>
        {category?.products?.map((product) => {
          let color = "#dc3545";
          if (product.score >= 7) color = "#2196F3";
          if (product.score >= 9) color = "#198754";

          return (
            <StyledTableCell
              style={{ color }}
              className={classes.attribute_score}
              key={product.name}
            >
              {roundNumber(product.score)}
            </StyledTableCell>
          );
        })}
      </StyledTableRow>
      {open &&
        category?.products?.[0].subcategories?.map((sub, idx) => (
          <StyledTableRow key={idx} className={classes.sub_row}>
            <StyledTableCell className={classes.attribute_sub_headings}>
              {sub.name}
            </StyledTableCell>
            {category?.products?.map((product, jdx) => {
              let color = "#dc3545";
              let rating = "low";
              const item = product.subcategories[idx];

              if (item.score >= 7) {
                rating = "fair";
                color = "#2196F3";
              }
              if (item.score >= 9) {
                rating = "high";
                color = "#198754";
              }

              let sources = [];
              if (item?.sources) {
                sources = item?.sources?.map((source) => ({
                  source: source,
                  snippet: "",
                }));
              }

              return (
                <MUIToolTip
                  key={jdx}
                  // llm={discoveryCtx.llm}
                  llm={"gpt-4o"}
                  additionalText={`${product.name} received a rating of ${item.score} out of 10, a ${rating} score in ${sub.name}`}
                  title={item.explanation}
                  arrow
                  disableAction
                  showSources
                  actionText="Add To Actions"
                  llmSources={sources}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -18],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <StyledTableCell
                    style={{ color }}
                    className={classes.attribute_score}
                  >
                    {roundNumber(product.subcategories[idx].score)}
                  </StyledTableCell>
                </MUIToolTip>
              );
            })}
          </StyledTableRow>
        ))}
    </React.Fragment>
  );
}

export default function Taxonomy({
  categories,
  brandDomains,
  loading,
  errors,
  summaries,
  getAttributesSummary,
}) {
  let competitors = categories?.map((obj) => {
    return obj?.products?.map((item) => item?.name);
  });
  competitors = Array.from(new Set(competitors?.flat()));
  function renderHeaders() {
    const headers = [
      ...TABLE_HEADERS,
      ...competitors?.map((item) => ({
        id: item,
        name: item,
      })),
    ];

    return (
      <TableHead className={classes.table_head}>
        <TableRow>
          {headers?.map((header, index) => {
            let domain = extractDomain(brandDomains?.[header?.name]);

            return (
              <TableCell key={index} className={classes.table_head_cell}>
                <div className={classes.table_head_content}>
                  {index != 0 && (
                    <img
                      data-html2canvas-ignore
                      className={classes.table_head_logo}
                      src={`https://img.logo.dev/${domain}?token=pk_SaDf4t2eRsW5Fc84zgJRZA`}
                    />
                  )}
                  <div
                    className={
                      index === 0
                        ? classes.table_head_text_comp
                        : classes.table_head_text
                    }
                  >
                    {header.name}
                  </div>
                </div>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  function renderRows(category) {
    return <Row key={category.name} category={category} />;
  }

  return (
    <>
      <MUICard variant="elevated" elevation={0} className={classes.card}>
        {categories?.length > 0 ? (
          <MUITable
            elevation={0}
            data={categories}
            renderHeaders={() => renderHeaders()}
            render={renderRows}
          />
        ) : (
          <>
            <h5>
              <Tooltip
              // title={metricExplainations[`Purchase Criteria`]}
              >
                Product Dimensions
              </Tooltip>
            </h5>
            <EmptyView message="Data Not Available" />
          </>
        )}
      </MUICard>

      <div className="col-md-12">
        <div className="graphGreyBg">
          <p className="mb-3 font14-600">
            Summary &nbsp; &nbsp;
            <AIGenerateButton
              fetchSummary={getAttributesSummary}
              type="product-dimensions"
            />
          </p>
          <div className="graphsummary font-11">
            {loading?.productDimensions ? (
              <Spinner />
            ) : summaries?.productDimensions ? (
              <p className="mb-1"> {summaries?.productDimensions} </p>
            ) : errors?.productDimensions ? (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors?.productDimensions}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
