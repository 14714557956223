import { useContext, useEffect, useState } from "react";

import { DiscoveryContext } from "./discovery-context";
import { fetchGpt4API } from "../../services/apiService";
import { extractJSONFromString } from "../../utils/helpers";

export default function useTopUserProducts() {
  const [keyPlayers, setKeyPlayers] = useState([]);
  const discoveryCtx = useContext(DiscoveryContext);

  function generateProductPrompt(
    category,
    userRole = "General Consumer",
    attributes = [],
    country = "United States"
  ) {
    const attributesSection = attributes.length
      ? `and prioritize products that align with attributes users care about, such as: ${attributes.join(
          ", "
        )}.`
      : "";

    return `
          Based on the specified category "${category}" and market in "${country}", provide a list of the top 8 products that belong to this category, ranked in order of popularity or market share.  
          Consider the user's role as "${userRole}" ${attributesSection}
      
          ### Instructions:  
          - Output the response in the following JSON format:  
          {
            "data": [
              { "name": "Product1", "domain": "url.com" },
              { "name": "Product2", "domain": "url.com" },
              { "name": "Product3", "domain": "url.com" },
              ...
            ]
          }
          - Each product should have its official website domain listed in the "domain" field.
          - If the category is "EV Pickup Trucks," an example product could be:
            { "name": "Tesla Cybertruck", "domain": "tesla.com" }
          - Rankings should reflect the user's role and the listed attributes while still accounting for overall popularity or market share in ${country}.
          - If no internet access is available, use existing knowledge to determine the rankings and attributes.
      
          ### Considerations:  
          - For users with roles like "Home Maker," focus on products with ease of use or practicality.  
          - For roles like "Salesperson," emphasize performance, style, or features enhancing productivity.
          - Consider products that are officially available in ${country}.
          - Account for local market preferences and regulations in ${country}.
          - Ensure accuracy and relevance to the specified category.
          - Do not include placeholders or incomplete fields in the output.  
      
          ### Additional Example:
          If the category is "Sedans," and the user cares about "Performance" and "Fuel Efficiency," the rankings should prioritize products that excel in these areas while maintaining market relevance in ${country}.  
        `;
  }

  useEffect(() => {
    if (discoveryCtx.abort_launch) {
      setKeyPlayers([]);
    }
  }, [discoveryCtx.abort_launch]);
  
  async function getBrandOrProductSuggestions() {
    const prompt = generateProductPrompt(
      discoveryCtx.category,
      discoveryCtx.occupation,
      discoveryCtx.attributes,
      discoveryCtx.location
    );
    let response = await fetchGpt4API(
      prompt,
      new AbortController(),
      1000,
      1,
      "gpt-4o"
    );
    response = await extractJSONFromString(response["GPT-4"][0]);
    if (response && response?.data) {
      const fetchedKeyPlayers = response?.data;
      setKeyPlayers(fetchedKeyPlayers);
    }
  }

  return { keyPlayers, getBrandOrProductSuggestions };
}
