import React, { useState, useContext } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";

import classes from "./AuthComponent.module.css"; // Use your updated CSS module
import { AuthContext } from "../../context/auth-context";
import { doLogin, resetPassword } from "../../DatabaseFirebase/firebaseService";
import ForgotPasswordModal from "./ForgotPasswordModal";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { fetchCustomer } from "../../redux/actions/userActions";
import TextInput from "../UI/TextInput/TextInput";
import MUIButton from "../UI/MUIButton/MUIButton";
import loginBg from "./../../assets/images/login-bg.svg";
import translateLogo from "./../../assets/images/isolation.svg";
import loginLogo from "./../../assets/images/login-logo.svg";

const AuthComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [emailToResetPass, setEmailToResetPass] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext);

  const handleClose = () => {
    setIsShowModal(false);
    setSuccessMessage("");
    setEmailToResetPass("");
  };

  const handleResetPassword = async () => {
    try {
      await resetPassword(emailToResetPass);
      toast.success("Password reset email sent. Please check your inbox.");
    } catch (error) {
      console.log(error.message);
      toast.error("Something went wrong");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsClicked(true);
    setError(null);

    setLoading(true);
    doLogin(email, password)
      .then((response) => {
        const authUser = response.data.data;
        if (!authUser.is_verified) {
          setError("You need to verify your email before you login to the app");
          return;
        }
        if (authUser.is_active === 0) {
          setError("Your account is inactive. Please contact admin.");
          return;
        }
        toast.success("Login successful");
        dispatch(fetchCustomer(authUser?.customer_id));
        setUser(authUser.token, authUser?.email, authUser);
        navigate("/luminaire/discovery");
      })
      .catch(() => {
        toast.error("Invalid Username/Password", { autoClose: 900 });
        setIsClicked(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <ForgotPasswordModal
        emailToResetPass={emailToResetPass}
        setEmailToResetPass={setEmailToResetPass}
        show={isShowModal}
        handleClose={handleClose}
        handleResetPassword={handleResetPassword}
        successMessage={successMessage}
      />

      <div className={classes.container}>
        <div className={classes.form_container}>
          <Form onSubmit={handleSubmit} className={classes.form}>
            <div className={classes.logo}>
              <img src={loginLogo} alt="Brand Logo" />
            </div>
            <span className={classes.login_txt}>Login</span>
            <TextInput
              variant="outlined"
              type="email"
              placeholder="Email"
              value={email}
              required
              className={classes.input}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextInput
              variant="outlined"
              type="password"
              placeholder="Password"
              className={classes.input}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <div
              className={classes.forgot_pass}
              onClick={() => setIsShowModal(true)}
            >
              <span>Forgot password?</span>
            </div>

            {error && <ErrorMessage message={error} />}
            <MUIButton
              loading={loading}
              className={classes.login_btn}
              type="submit"
            >
              Login Now
            </MUIButton>
          </Form>
        </div>
        <div
          className={classes.background_section}
          style={{
            backgroundImage: `url(${loginBg})`,
          }}
        >
          <div className={classes.auto_fix_wrapper}>
            <img src={translateLogo} />
            <div className={classes.auto_fix}>
              <AutoFixHighOutlinedIcon className={classes.auto_fix_icon} />
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};
export default AuthComponent;
