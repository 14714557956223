import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Tooltip } from "@mui/material";

import {
  fetchBrandProducts,
  deleteBrandProduct,
} from "../../../redux/actions/brandProductsActions";
import { fetchPersonas } from "../../../redux/actions/personasActions";
import { fetchcoreAttributes } from "../../../redux/actions/coreAttributeActions";
import { AuthContext } from "../../../context/auth-context";
import Table, {
  StyledTableCell,
  StyledTableRow,
} from "./../../../Component/UI/Table/Table";
import Spinner from "../../../Component/Spinner/Spinner";
import MUIIconButton from "./../../../Component/UI/MUIIconButton/MUIIconButton";
import MUIChip from "./../../../Component/UI/MUIChip/MUIChip";
import classes from "./Product.module.css";

const TABLE_HEADERS = [
  { id: "product", name: "Product", sorting: true },
  { id: "details", name: "Details", sorting: false },
  { id: "product_category", name: "Product Category", sorting: true },
  { id: "product_competitor", name: "Product Competitor", sorting: true },
  { id: "persona", name: "Persona", sorting: true },
  { id: "status", name: "Status", sorting: true },
  { id: "actions", name: "Actions", sorting: false },
];

const CHIP_COLORS = [
  "#CEB4FF80",
  "#F7C4FF80",
  "#FFBFAB80",
  "#C4FFFA80",
  "#CFCFCF80",
  "#FFDE9E80",
  "#C4FF8980",
  "#FFF27980",
];

function extractDomain(url) {
  if (url?.toString().trim()) {
    const parsedUrl = new URL(url);
    let hostname = parsedUrl.hostname;
    if (hostname.startsWith("www.")) {
      hostname = hostname.slice(4);
    }
    return hostname;
  } else {
    return "brandluminaire.eastus.cloudapp.azure.com";
  }
}

export default function Product({
  setUpdateModalOpen,
  setModalOpen,
  setProductName,
  setProductDetails,
  setProductCategory,
  setProductCategoryDescription,
  setBrandPersona,
  setCompetitors,
  setSelectProductID,
}) {
  const dispatch = useDispatch();

  const brandProducts = useSelector((state) => state.brandProducts);
  const personas = useSelector((state) => state.personas);
  const coreAttributes = useSelector((state) => state.coreAttributes);

  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const customerId = user?.customer_id || 0;

  function renderToolTipContent(arr) {
    return (
      <React.Fragment>
        {arr.map((p, index) => (
          <React.Fragment key={index}>
            {p.persona.name}
            {index < arr.length - 1 ? ", " : ""}
            <br />
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }

  const handleDeleteBrandProduct = (brandProductId) => {
    dispatch(deleteBrandProduct(customerId, brandProductId)).then(() => {
      dispatch(fetchBrandProducts(customerId));
    });
  };

  useEffect(() => {
    if (brandProducts?.data && brandProducts?.data.length === 0) {
      dispatch(fetchBrandProducts(customerId));
    }
  }, [dispatch]);

  const brandProductsData = brandProducts?.data?.data?.filter(
    (obj) => obj.is_active
  );

  const renderRow = (item) => (
    <StyledTableRow key={item.id}>
      <StyledTableCell component="th" scope="row" style={{ width: 150 }}>
        <div className={classes.brand_product}>
          <img
            src={`https://img.logo.dev/${extractDomain(
              item.brand?.domain_url
            )}?token=pk_SaDf4t2eRsW5Fc84zgJRZA`}
          />
          <span>{item.product.name}</span>
        </div>
      </StyledTableCell>
      <StyledTableCell align="left" style={{ width: 180 }}>
        {item.product.description}
      </StyledTableCell>
      <StyledTableCell align="left" style={{ width: 180 }}>
        {item.customer_brand_product_categories[0].category.name}
      </StyledTableCell>
      <StyledTableCell align="left" style={{ width: 250 }}>
        {item.customer_competitor_brand_products.map((competitor) => (
          <div className={classes.competitor}>
            {competitor.brand_product.name}
          </div>
        ))}
      </StyledTableCell>
      <StyledTableCell align="left" style={{ width: 300 }}>
        {item.custProduct.slice(0, 2).map((p) => (
          <MUIChip
            label={p.persona.name}
            color={CHIP_COLORS[Math.floor(Math.random() * CHIP_COLORS.length)]}
          />
        ))}
        {item.custProduct.length - 2 > 0 ? (
          <Tooltip
            title={renderToolTipContent(
              item.custProduct.slice(2, item.custProduct.length)
            )}
          >
            <span></span>
            <MUIChip
              label={`${item.custProduct.length - 2}+`}
              color="#858585"
            />
          </Tooltip>
        ) : null}
      </StyledTableCell>
      <StyledTableCell align="left" style={{ width: 100 }}>
        <div className={classes.status}>
          <CheckCircleIcon style={{ color: "#028101" }} />
          <span>{item.is_active == 1 ? "Active" : "Inactive"}</span>
        </div>
      </StyledTableCell>
      <StyledTableCell align="left">
        <div>
          <MUIIconButton
            style={{ color: "#028101" }}
            className={classes.action_btn}
          >
            <PlayArrowIcon />
          </MUIIconButton>
          <MUIIconButton
            tooltip="Update"
            style={{ color: "#007aff" }}
            className={classes.action_btn}
            onClick={() => {
              setUpdateModalOpen(true);
              setModalOpen(true);
              setProductName(item.product.name);
              setProductDetails(item.product.description);
              setProductCategory(
                item.customer_brand_product_categories[0].category.name
              );
              setProductCategoryDescription(
                item.customer_brand_product_categories[0].category.description
              );
              setSelectProductID(item);
              console.log(item, "item");
              createPersonaArray(item.custProduct);
            }}
          >
            <EditIcon />
          </MUIIconButton>
          <MUIIconButton
            tooltip="Delete"
            style={{ color: "#da1a04" }}
            onClick={() => handleDeleteBrandProduct(item?.id)}
            className={classes.action_btn}
          >
            <DeleteOutlineIcon />
          </MUIIconButton>
          <MUIIconButton
            tooltip="Launch Dashboard"
            onClick={() =>
              navigate(
                `/brandonboarding/dashboard?brandId=${item?.id}&brandCustomerId=${item?.customer?.id}&isDetailPage=1`
              )
            }
            style={{ color: "#6118f0" }}
            className={classes.action_btn}
          >
            <SpeedOutlinedIcon />
          </MUIIconButton>
        </div>
      </StyledTableCell>
    </StyledTableRow>
  );

  const createPersonaArray = (persona) => {
    const result = persona?.map((item) =>
      typeof item === "object" ? item.persona_id : item
    );
    setBrandPersona(result);
  };
  return (
    <>
      {brandProductsData && brandProductsData.length !== 0 ? (
        <Table
          headers={TABLE_HEADERS}
          data={brandProductsData}
          render={renderRow}
        />
      ) : (
        <Spinner />
      )}
    </>
  );
}
