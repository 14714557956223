import React, { useRef } from "react";

const targetMapping = {
  reach: "Reach",
  sentiment: "Risk",
  risk: "Performance",
  opp: "Opportunity",
};

const targetReverseMapping = {
  Reach: "reach",
  Risk: "sentiment",
  Performance: "risk",
  Opportunity: "opp",
};

const ActionTypeTab = ({ activeTarget, handleChange }) => {
  const navLinksRef = useRef({});

  const handleClick = (event) => {
    event.preventDefault();
    const target = event.currentTarget.getAttribute("data-target");
    handleChange(targetReverseMapping[target]);

    // Update the class names for active and inactive links
    Object.values(navLinksRef.current).forEach((link) => {
      link.classList.remove("active");
    });

    if (navLinksRef.current[target]) {
      navLinksRef.current[target].classList.add("active");
    }
  };

  return (
    <div className="col-md-12">
      <div className="mb-3 w-100 d-flex justify-content-center align-items-center">
        <div style={{ border: "none" }} className="dmqGroup">
          <button
            className={`${
              targetMapping[activeTarget] === "Reach" ? "active" : ""
            }`}
            data-target="Reach"
            ref={(el) => (navLinksRef.current["Reach"] = el)}
            onClick={handleClick}
          >
            Reach
          </button>

          <button
            className={`${
              targetMapping[activeTarget] === "Risk" ? "active" : ""
            }`}
            data-target="Risk"
            ref={(el) => (navLinksRef.current["Risk"] = el)}
            onClick={handleClick}
          >
            Risk
          </button>

          <button
            className={`${
              targetMapping[activeTarget] === "Performance" ? "active" : ""
            }`}
            data-target="Performance"
            ref={(el) => (navLinksRef.current["Performance"] = el)}
            onClick={handleClick}
          >
            Performance
          </button>

          {/* <button
            className={`${
              targetMapping[activeTarget] === "Opportunity" ? "active" : ""
            }`}
            data-target="Opportunity"
            ref={(el) => (navLinksRef.current["Opportunity"] = el)}
            onClick={handleClick}
          >
            Opportunity
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default ActionTypeTab;
