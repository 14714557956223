import Markdown from "markdown-to-jsx";
import classes from "./Review.module.css";
import { Link } from "react-router-dom";

export default function Review({ review, index }) {
  function convertMarkdownToPlainText(markdown) {
    return (
      markdown
        // Remove headers (e.g., #, ##, ###)
        .replace(/^#+\s*/gm, "")
        // Remove bold markers (** or __)
        .replace(/\*\*(.*?)\*\*/g, "$1")
        .replace(/__(.*?)__/g, "$1")
        // Remove italics markers (* or _)
        .replace(/\*(.*?)\*/g, "$1")
        .replace(/_(.*?)_/g, "$1")
        // Remove links but keep the text
        .replace(/\[([^\]]+)\]\([^)]+\)/g, "$1")
        // Remove unordered list markers (-, *, +)
        .replace(/^[*-+]\s+/gm, "")
        // Remove ordered list markers (e.g., 1., 2.)
        .replace(/^\d+\.\s+/gm, "")
        // Remove any extra spaces at the beginning of lines
        .trim()
    );
  }

  return (
    <div className={classes.container}>
      <p className={classes.review_text}>
        <span className={classes.review_nubmer}>{index + 1}</span>
        {/* <div className={classes.content}>{review}</div> */}

        <div
          style={{
            padding: "1rem",
          }}
        >
          {/* Render the markdown snippet */}
          <div
            style={{ marginBottom: "0.5rem", fontSize: "1rem", color: "#333" }}
          >
            <Markdown style={{ fontSize: "12px", color: "grey" }}>
              {convertMarkdownToPlainText(review?.snippet)}
            </Markdown>
          </div>

          {/* Render the source links */}
          <div>
            {review?.urls.map((url, index) => (
              <div key={index}>
                <Link
                  to={url.finalUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#007bff",
                    textDecoration: "none",
                    fontSize: "10px",
                  }}
                >
                  {url.title}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </p>
    </div>
  );
}
