import React, { useState, useEffect, useContext } from "react";

import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  Checkbox,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ActionTypeTab from "./ActionTypeTab";
import {
  createActionMetric,
  fetchActionMetrics,
} from "../../../../redux/actions/metricActions";
import { useDispatch } from "react-redux";
import { AuthContext } from "../../../../context/auth-context";
import EmptyView from "../../../../Component/EmptyView/EmptyView";
import { getSourcesWithAlias } from "../../../../services/apiService";

export const CommonInfoCard = ({ data, useCardWrapper = true }) => {
  const content = (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Typography variant="body1" fontWeight="bold">
          {data?.isProduct ? "Product" : "Brand"}
        </Typography>
        <Typography variant="body2">{data?.brand || "N/A"}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body1" fontWeight="bold">
          Persona
        </Typography>
        <Typography variant="body2">{data?.persona || "N/A"}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body1" fontWeight="bold">
          LLM
        </Typography>
        <Typography variant="body2">{data?.llm || "N/A"}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body1" fontWeight="bold">
          Geography
        </Typography>
        <Typography variant="body2">{data?.geography || "N/A"}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body1" fontWeight="bold">
          Market
        </Typography>
        <Typography variant="body2">{data?.market || "N/A"}</Typography>
      </Grid>
    </Grid>
  );

  return useCardWrapper ? (
    <Card sx={{ mb: 2, boxShadow: 3 }}>
      <CardContent>{content}</CardContent>
    </Card>
  ) : (
    <div>{content}</div>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflowY: "auto",
  borderRadius: "8px",
};

const ActionModal = ({ open, handleClose, data, type, handleChange }) => {
  const { user } = useContext(AuthContext);
  const customerId = user?.customer_id;

  const [actionType, setActionType] = useState(type);
  const [actionListItems, setActionListItems] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setActionType(type);
  }, [type]);

  function transformActionTypeData(inputObjects, mainData, filterType) {
    const sentimentMetaMapping = {
      Sentiment: inputObjects?.Sentiment?.sentimentMeta,
      opp: inputObjects?.Sentiment?.sentimentMeta,
    };

    const isObject = (value) => {
      return (
        value !== null && typeof value === "object" && !Array.isArray(value)
      );
    };

    const result = [];
    if (inputObjects) {
      const keyMapping = {
        llmComp: "llm",
        marketcomp: "market",
        geocomp: "geography",
        personacomp: "persona",
      };

      // Helper function to generate the title
      const generateTitle = (parentKey, key, data, mainData, input) => {
        const parentKeyLabel =
          parentKey.charAt(0).toUpperCase() + parentKey.slice(1); // Capitalize parent key
        if (key === "onlySelf") {
          const status = actionType == "opp" ? "high" : "low";
          return `The ${parentKeyLabel} score for ${mainData?.brand} ${
            mainData?.isProduct ? "product" : "brand"
          } is significantly ${status} at ${data.score}.`.trim();
        } else {
          return `The ${parentKeyLabel} score for ${keyMapping[key]}  - ${
            mainData[keyMapping[key]]
          } is ${input?.self?.score}, while '${
            data.targetKey || ""
          }' achieves the highest score of ${
            data.score
          } in this category.`.trim();
        }
      };

      // Iterate over each parent object
      Object.entries(inputObjects).forEach(([parentKey, input]) => {
        // Apply the filter condition based on filterType
        const isReachFilter = filterType === "Reach";
        const isSentimentFilter = filterType === "Sentiment";
        const shouldInclude = isReachFilter
          ? parentKey.toLowerCase().includes("reach")
          : isSentimentFilter
          ? parentKey.toLowerCase().includes("sentiment")
          : !parentKey.toLowerCase().includes("reach") &&
            !parentKey.toLowerCase().includes("sentiment");

        if (shouldInclude || filterType === "opp") {
          // Iterate over the keys in each input object
          Object.keys(input).forEach((key) => {
            const value = input[key];
            if (isObject(value) && key !== "self") {
              // Exclude null values and 'self' key
              result.push({
                parentKey, // Link to the parent object key
                title: generateTitle(parentKey, key, value, mainData, input),
                sources: value.sources ? value.sources.slice(0, 4) : [], // Limit sources to top 4
                ratioanle:
                  value?.rationale ||
                  (!parentKey.toLowerCase().includes("reach") &&
                    sentimentMetaMapping[filterType]),
              });
            }
          });
        }
      });
    }

    // Check mainData for the 'comp' key and additional processing
    if (mainData?.comp && filterType !== "opp") {
      Object.entries(mainData.comp).forEach(([compKey, compData]) => {
        const isReachKey = compKey.toLowerCase().includes("reach");
        const isSentimentKey = compKey.toLowerCase().includes("sentiment");
        const shouldInclude =
          filterType === "Reach"
            ? isReachKey
            : filterType === "Sentiment"
            ? isSentimentKey
            : !isReachKey && !isSentimentKey;

        if (shouldInclude && compData) {
          result.push({
            parentKey: isReachKey ? "Reach" : compKey, // Set parentKey dynamically
            title: ` The ${compKey} score for your ${
              mainData?.isProduct ? "product" : "brand"
            }, ${data?.brand},is ${compData.score}, while the competitor  ${
              mainData?.isProduct ? "product" : "brand"
            },${compData?.compData?.targetKey}, achieves a highest score of  ${
              compData.compData?.score || "N/A"
            } in this category.`.trim(),
            sources: compData.sources ? compData.sources.slice(0, 4) : [], // Limit sources to top 4
            ratioanle:
              compData?.rationale ||
              (!isReachKey && sentimentMetaMapping[filterType]),
          });
        }
      });
    }

    return result;
  }

  const dataMapping = {
    reach: transformActionTypeData(data?.Risk, data, "Reach"),
    sentiment: transformActionTypeData(data?.Risk, data, "Sentiment"),
    risk: transformActionTypeData(data?.Risk, data, "Risk"),
    opp: transformActionTypeData(data?.opp, data, "opp"),
  };

  const handleActionTypeChange = (key) => {
    setActionType(key);
  };

  const handleCheckboxChange = (obj, checked) => {
    setActionListItems(
      (prevItems) =>
        checked
          ? [...prevItems, obj] // Add item if checked
          : prevItems.filter((item) => item.title !== obj.title) // Remove item if unchecked
    );
  };

  const createDomainUrlMapping = (dataArray) => {
    const mapping = {};

    dataArray?.forEach((item) => {
      const domainUrl = item.domain_url;
      const aliasUrls = item.source_aliases?.map((alias) => alias.url) || [];
      mapping[domainUrl] = aliasUrls?.[0];
    });

    return mapping;
  };

  const isChecked = (obj) =>
    actionListItems.some((item) => item.title === obj.title);

  const saveActions = async () => {
    const currentActions = [...actionListItems];
    let sourceFullUrls = [];
    const sources = currentActions
      ?.map((obj) => obj.sources && obj.sources)
      .flat();
    if (sources && sources.length > 0) {
      sourceFullUrls = await getSourcesWithAlias({ sources });
    }
    const domainUrlMapping = createDomainUrlMapping(sourceFullUrls?.data);
    const modifiedActionList = currentActions?.map((obj) => {
      const modifiedObj = {
        ...obj,
        brand: data?.brand,
        persona: data?.persona,
        geography: data?.geography,
        market: data?.market,
        llm: data?.llm,
        isProduct: data?.isProduct,
        sources: obj?.sources?.map((url) => {
          return { domain_url: url, fullUrl: domainUrlMapping?.[url] };
        }),
      };

      return modifiedObj;
    });

    const payload = {
      action_json: modifiedActionList,
      is_archived: 0,
      is_active: 1,
    };
    dispatch(createActionMetric(customerId, payload)).then(() => {
      dispatch(fetchActionMetrics(customerId));
      handleClose();
      handleChange("Actions List");
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 8, right: 8, color: "gray" }}
        >
          <CloseIcon />
        </IconButton>

        <ActionTypeTab
          activeTarget={actionType}
          handleChange={handleActionTypeChange}
        />

        {dataMapping?.[actionType]?.length ? (
          <>
            <CommonInfoCard data={data} />
            <ul style={{ listStyleType: "square", padding: 0 }}>
              {dataMapping?.[actionType]?.map((obj, index) => (
                <li key={index} style={{ marginBottom: "16px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="body1" component="span">
                      {obj?.title}
                    </Typography>
                    <Checkbox
                      checked={isChecked(obj)}
                      onChange={(e) =>
                        handleCheckboxChange(obj, e.target.checked)
                      }
                    />
                  </div>

                  {/* Sources */}
                  <Typography variant="h6" component="div" sx={{ mt: 1 }}>
                    Sources
                  </Typography>
                  <ul style={{ paddingLeft: "20px" }}>
                    {obj?.sources.map((data, subIndex) => (
                      <li key={subIndex}>
                        <Typography variant="body2">{data}</Typography>
                      </li>
                    ))}
                  </ul>
                  <hr />
                </li>
              ))}
            </ul>
            <Button
              variant="contained"
              color="primary"
              onClick={saveActions}
              sx={{ mt: 2 }}
            >
              Add To Actions List
            </Button>
          </>
        ) : (
          <EmptyView message="Nothing here" />
        )}
      </Box>
    </Modal>
  );
};

export default ActionModal;
