import { useEffect, useState } from "react";
import axios from "axios";

export default function useCountry() {
  const [countries, setCountries] = useState([]);
  const [error, setError] = useState("");

  async function fetchCountries() {
    try {
      const response = await axios.get("https://restcountries.com/v3.1/all");
      const countries = response.data.map((country) => {
        return {
          id: country.cca2,
          name: country.name.common,
          value: country.name.common,
        }
      }).sort((a, b) => a.name.localeCompare(b.name));
      setCountries(countries);
    } catch (error) {
      setError(`Error fetching countries ${error.message}`);
    }
  }

  useEffect(() => {
    fetchCountries();
  }, []);

  return { countries, error };
}
