import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextInput from "./../TextInput/TextInput";

export default function MUIAutocomplete({
  onChange,
  onInputChange,
  label,
  optionValue,
  options,
  ...props
}) {
  const value = optionValue;

  return (
    <Autocomplete
      id="autocomplete"
      freeSolo
      size="small"
      style={{ width: "20%", ...props.style }}
      value={value}
      onChange={(_, value) => {
        const option = options.find((opt) => opt.name === value);
        onChange(option);
      }}
      onInputChange={(e) => onInputChange(e?.target?.value)}
      options={options.map((option) => option.name)}
      renderInput={(params) => (
        <TextInput
          fullWidth
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: null,
          }}
        />
      )}
      {...props}
    />
  );
}
