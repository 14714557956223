import LoadingSkeleton from "./LoadingSkeleton";
import Review from "./Review";
import classes from "./CustomerReviews.module.css";

export default function CustomerReviews({ loading, reviews = [] }) {
  return (
    <div className={classes.container}>
      <div className={classes.title}>Reviews</div>
      {loading && <LoadingSkeleton />}
      {!loading &&
        reviews.length > 0 &&
        reviews
          .slice(0, 3)
          .map((review, index) => (
            <Review key={index} index={index} review={review} />
          ))}
    </div>
  );
}
