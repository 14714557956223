import { Modal, Button, FormControl } from "react-bootstrap";

import classes from "./ForgotPassword.module.css";
import { Typography } from "@mui/material";
import React from "react";

function ForgotPasswordModal({
  show,
  handleClose,
  emailToResetPass,
  setEmailToResetPass,
  handleResetPassword,
  successMessage,
}) {
  const [emailValid, setEmailValid] = React.useState(false);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Forgot Your Password?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Enter your registered email address below and we’ll send you a link to
        reset your password
        <p></p>
        <FormControl
          type="text"
          placeholder="Enter Email Address"
          value={emailToResetPass}
          onChange={(e) => {
            setEmailValid(emailToResetPass.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/));
            setEmailToResetPass(e.target.value);
          }}
          required
        />
        <p>
          {!emailValid && emailToResetPass && (
            <Typography color="error" variant="caption" mt={1}>
              Please enter a valid email
            </Typography>
          )}
        </p>
      </Modal.Body>
      <Modal.Footer className={classes.actions}>
        <Button
          style={{ backgroundColor: emailValid ? "#30c6b7" : "gainsboro" }}
          variant="success"
          onClick={() => handleResetPassword()}
          disabled={!emailValid || !emailToResetPass}
        >
          Send
        </Button>
        <Button
          style={{ backgroundColor: "#ff7a00" }}
          variant="danger"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ForgotPasswordModal;
