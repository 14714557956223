import { useState } from "react";
import moment from "moment";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";

import MUIIconButton from "../../../../Component/UI/MUIIconButton/MUIIconButton";
import classes from "./FilterItem.module.css";
import api from "../../../../BrandOnBoarding/api";

export default function FilterItem({ item, onSelect, onDelete, onError }) {
  const [loading, setLoading] = useState(false);

  async function deleteSearchFilter() {
    try {
      setLoading(true);
      onError(null);
      const response = await api.delete(`/users/search-filter/${item.id}`);

      if (response.status === 200) {
        onDelete(item.id);
      } else {
        onError("Failed to delete history item.");
      }
    } catch (error) {
      onError("Failed to delete history item.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={classes.container} onClick={() => onSelect(item)}>
      <div className={classes.content_wrapper}>
        <SortOutlinedIcon className={classes.icon} />
        <div className={classes.content}>
          <span className={classes.heading}>{item.name}</span>
          <span className={classes.description}>{item.description}</span>
          <span className={classes.date}>
            {moment(item.updated_at).format("DD-MM-YYYY hh:mm A")}
          </span>
        </div>
      </div>
      <MUIIconButton
        loading={loading}
        disabled={loading}
        onClick={(e) => {
          e.stopPropagation();
          deleteSearchFilter();
        }}
      >
        <DeleteOutlineIcon />
      </MUIIconButton>
    </div>
  );
}
