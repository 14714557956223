import { useContext, useEffect, useRef, useState } from "react";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import { Skeleton } from "@mui/material";

import classes from "./ProductItem.module.css";
import { DiscoveryContext } from "../../Discovery/discovery-context";
import api from "../../../BrandOnBoarding/api";
import AttributeSkeleton from "./AttributeSkeleton";
import BingImage from "../../../Component/BingImage/BingImage";
import CustomerReviews from "../CustomerReviews/CustomerReviews";

export default function ProductItem({
  index,
  classifiedProducts,
  name,
  brandIndex,
  reviews,
  reviewLoading,
}) {
  const [attributes, setAttributes] = useState({
    positives: [],
    negatives: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const discoveryCtx = useContext(DiscoveryContext);

  const abortControllerRef = useRef(null);

  async function getPositiveNegativeAttributes() {
    setError(null);
    setLoading(true);
    abortControllerRef.current = new AbortController();
    try {
      const payload = {
        location: "",
        market: "",
        llm: discoveryCtx.llm,
        persona: "General Profile",
        type: discoveryCtx.type,
        exec_count: discoveryCtx.type === "category" ? 1 : 3,
      };

      if (discoveryCtx.type === "brand") {
        payload.brand = name;
      } else {
        payload.brand = name;
      }
      if (discoveryCtx.llm === "Perplexity") {
        await new Promise((res) => setTimeout(() => res(""), 120000));
      }
      let response = await api.post(`/metrics/v3/signal-strength`, payload, {
        signal: abortControllerRef.current.signal,
      });

      const negativeAttributes = response.data.aggregatedNegatives;
      const positiveAttributes = response.data.aggregatedPositives;

      setAttributes({
        positives: positiveAttributes.slice(0, 5),
        negatives: negativeAttributes.slice(0, 5),
      });
    } catch (error) {
      console.log("Something went wrong", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (discoveryCtx.abort_launch) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    }
  }, [discoveryCtx.abort_launch]);

  useEffect(() => {
    getPositiveNegativeAttributes();
  }, []);

  const productGroup = classifiedProducts?.find(
    (prod) => prod.product_name === name
  );

  return (
    <div className={classes.item}>
      <BingImage
        className={classes.brand_image}
        entity={name}
        category={discoveryCtx.category}
      />
      <div className={classes.content}>
        <span className={classes.name}>{name}</span>
        {brandIndex && (
          <div className={classes.scores}>
            <div className={classes.metric}>
              <span className={classes.score}>{productGroup?.group_name}</span>
              <span className={classes.label}>Rank</span>
            </div>
            <div className={classes.metric}>
              <span className={classes.score}>{brandIndex?.favorability}</span>
              <span className={classes.label}>Sentiment</span>
            </div>
          </div>
        )}

        {!brandIndex && (
          <Skeleton variant="rectangular" width={"100%"} height={70} />
        )}

        {loading && <AttributeSkeleton />}
        {loading && <AttributeSkeleton />}

        {!loading && attributes.positives.length > 0 && (
          <div className={classes.procons}>
            <div className={classes.list_heading}>
              <ThumbUpOffAltIcon style={{ color: "green" }} />
              <span className={classes.list_heading_text}>Positives</span>
            </div>
            <ul className={classes.list}>
              {attributes.positives.map((positiveAttr) => {
                return <li key={positiveAttr.name}>{positiveAttr.name}</li>;
              })}
            </ul>
          </div>
        )}

        {!loading && attributes.negatives.length > 0 && (
          <div className={classes.procons}>
            <div className={classes.list_heading}>
              <ThumbDownOffAltIcon style={{ color: "red" }} />
              <span className={classes.list_heading_text}>Negatives</span>
            </div>
            <ul className={classes.list}>
              {attributes.negatives.map((negativeAttr) => {
                return <li key={negativeAttr.name}>{negativeAttr.name}</li>;
              })}
            </ul>
          </div>
        )}
        <CustomerReviews
          reviews={reviews?.reviews}
          loading={reviewLoading}
        />
      </div>
    </div>
  );
}
