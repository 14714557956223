import React, { useState } from "react";
import { extractDomain } from "../../../../utils/helpers";
import MUICard from "./../../../../Component/UI/MUICard/MUICard";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./CompetitorItem.module.css";
import BrandIndexBreakups from "../../../../Component/BrandIndexHistory/BrandIndexBreakups";
import Modal from "../../../../Component/Modal/Modal";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MUIIconButton from "../../../../Component/UI/MUIIconButton/MUIIconButton";

export default function CompetitorItem({ indexScoreData, brandDomains }) {
  let domain = extractDomain(brandDomains?.[indexScoreData?.name]);

  const [showBrandIndexBreakups, setShowBrandIndexBreakups] = useState(false);

  return (
    <div className={classes.container}>
      <Modal
        width={1200}
        open={showBrandIndexBreakups}
        handleClose={() => setShowBrandIndexBreakups(false)}
      >
        <BrandIndexBreakups
          isMonitoring={true}
          data={indexScoreData?.metaData?.extras}
        />
      </Modal>
      <MUICard
        variant="elevated"
        elevation={0}
        borderRadius={3}
        className={classes.card}
      >
        {indexScoreData?.metaData?.extras && (
          <MUIIconButton
            data-html2canvas-ignore
            onClick={() => setShowBrandIndexBreakups(true)}
            className={classes.settings}
          >
            <InfoOutlinedIcon />
          </MUIIconButton>
        )}
        <div className={classes.header}>
          <img
            data-html2canvas-ignore
            className={classes.logo}
            src={`https://img.logo.dev/${domain}?token=pk_SaDf4t2eRsW5Fc84zgJRZA`}
          />
          <span className={classes.title}>{indexScoreData?.name}</span>
        </div>
        <div className={classes.content}>
          <div className={classes.score}>
            {Number(indexScoreData?.BrandIndexScore).toFixed(0)}
            {/* &nbsp; {indexScoreData?.delta !== null && (
              <>
                <span style={{ color: "grey", marginLeft: "-10px" }}>
                  <FontAwesomeIcon
                    className={
                      indexScoreData.delta < 0
                        ? `${classes["core-down-icon"]} text-danger me-1`
                        : `${classes["core-up-icon"]} text-success me-1`
                    }
                    icon={indexScoreData.delta < 0 ? faSortDown : faSortUp}
                  />
                </span>
                <span
                  style={{ color: "grey", marginLeft: "1px" }}
                  className={classes.coreDelta}
                >
                  {Number(Math.abs(indexScoreData.delta)).toFixed(0)}
                </span>
              </>
            )} */}
          </div>
          <div className={classes.sub_content}>
            <span className={classes.sub_score}>
              {Number(indexScoreData?.Rank).toFixed(0)}
              {/* &nbsp; &nbsp; {indexScoreData?.rankDelta !== null && (
                <>
                  <span style={{ color: "grey", marginLeft: "-10px" }}>
                    <FontAwesomeIcon
                      className={
                        indexScoreData?.rankDelta < 0
                          ? `${classes["core-down-icon-2"]} text-danger me-1`
                          : `${classes["core-up-icon-2"]} text-success me-1`
                      }
                      icon={
                        indexScoreData?.rankDelta < 0 ? faSortDown : faSortUp
                      }
                    />
                  </span>
                  <span
                    style={{ color: "grey", marginLeft: "1px" }}
                    className={classes.coreDelta2}
                  >
                    {Number(Math.abs(indexScoreData?.rankDelta)).toFixed(0)}
                  </span>
                </>
              )} */}
            </span>
            <hr className={classes.divider} />
            <span className={classes.sub_score}>
              {Number(indexScoreData?.Favorability).toFixed(0)}
              {/* &nbsp; &nbsp; {indexScoreData?.favDelta !== null && (
                <>
                  <span style={{ color: "grey", marginLeft: "-10px" }}>
                    <FontAwesomeIcon
                      className={
                        indexScoreData?.favDelta < 0
                          ? `${classes["core-down-icon-2"]} text-danger me-1`
                          : `${classes["core-up-icon-2"]} text-success me-1`
                      }
                      icon={
                        indexScoreData?.favDelta < 0 ? faSortDown : faSortUp
                      }
                    />
                  </span>
                  <span
                    style={{ color: "grey", marginLeft: "1px" }}
                    className={classes.coreDelta2}
                  >
                    {Number(Math.abs(indexScoreData?.favDelta)).toFixed(0)}
                  </span>
                </>
              )} */}
            </span>
          </div>
        </div>
      </MUICard>
    </div>
  );
}
