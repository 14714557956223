import { useEffect, useState, useContext } from "react";

import Modal from "./../../Component/Modal/Modal";
import TextInput from "../../Component/UI/TextInput/TextInput";
import { DiscoveryContext } from "./discovery-context";
import { validateProductCategory } from "../../utils/helpers";
import Alert from "../../Component/UI/Alert/Alert";
import classes from "./UserKeyPlayer.module.css";
import { LinearProgress } from "@mui/material";

export default function UserKeyPlayer({ open, handleClose, handleConfirm }) {
  const [userKeyPlayer, setUserKeyPlayer] = useState("");
  const [isValidating, setIsValidating] = useState(false);
  const [isProductHasDifferentCategory, setIsProductHasDifferentCategory] =
    useState(false);
  const [productDomain, setProductDomain] = useState("");

  const discoveryCtx = useContext(DiscoveryContext);

  async function validateUserInput() {
    setIsValidating(true);
    setIsProductHasDifferentCategory(false);
    try {
      let response = await validateProductCategory(
        userKeyPlayer,
        discoveryCtx.category
      );
      setIsValidating(false);
      setProductDomain(response.data.domain);
      if (response.data.ans === "Yes") {
        setIsProductHasDifferentCategory(false);
      } else {
        setIsProductHasDifferentCategory(true);
      }
    } catch (error) {
      setIsValidating(false);
    }
  }

  useEffect(() => {
    if (!userKeyPlayer) return;
    let timer = setTimeout(() => {
      validateUserInput();
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [userKeyPlayer]);

  return (
    <Modal
      title="Add Key Player"
      open={open}
      showActions
      handleClose={() => handleClose()}
      handleConfirm={() => {
        handleConfirm(userKeyPlayer, productDomain);
      }}
      confirmBtnDisabled={!userKeyPlayer || isValidating}
    >
      <TextInput
        className={classes.input}
        fullWidth
        type="text"
        onChange={(e) => setUserKeyPlayer(e.target.value)}
        placeholder="Enter Product name"
      />
      {isValidating && <LinearProgress />}
      {isProductHasDifferentCategory && (
        <div className="mt-3">
          <Alert
            title="Mismatched category"
            message={
              <span>
                Please note, Entered product doesn't belong to{" "}
                {discoveryCtx.category} category.
              </span>
            }
          />
        </div>
      )}
    </Modal>
  );
}
