import React, { useState } from "react";
import { ListItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import MUIIconButton from "./../../../Component/UI/MUIIconButton/MUIIconButton";
import classes from "./SourceItem.module.css";

function extractDomain(url) {
  const match = url.match(
    /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n]+)/im
  );
  if (match && match[1]) {
    const domain = match[1].split(".").slice(-2)[0];
    return domain.charAt(0).toUpperCase() + domain.slice(1);
  }
  return null;
}

export default function SourceItem({ item, index }) {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment key={index}>
      <ListItem
        className={classes.list_item}
        style={{
          backgroundColor: index % 2 == 0 ? "#FFF" : "#F8FAFB",
        }}
      >
        <MUIIconButton
          onClick={() => setOpen(!open)}
          style={{
            visibility: item?.items?.length > 0 ? "visible" : "hidden",
          }}
        >
          {open ? <RemoveIcon /> : <AddIcon />}
        </MUIIconButton>

        {item?.items?.length > 0 ? (
          <a className={classes.link}>
            {extractDomain(item.src)}
          </a>
        ) : (
          <a
            href={
              item.src.startsWith("http://") || item.src.startsWith("https://")
                ? item.src
                : `http://${item.src}`
            }
            target="_blank"
            className={classes.link}
          >
            {extractDomain(item.src)}
          </a>
        )}
      </ListItem>
      {open && (
        <>
          {item?.items?.map((link, index) => {
            return (
              <ListItem
                key={link.src}
                className={classes.list_item}
                style={{
                  backgroundColor: "#f5f8fa",
                }}
              >
                <a
                  href={
                    link.src.startsWith("http://") ||
                    link.src.startsWith("https://")
                      ? link.src
                      : `http://${link.src}`
                  }
                  style={{ paddingLeft: "3rem", fontSize: 15 }}
                  target="_blank"
                  className={classes.link}
                >
                  {index + 1}. {link.src}
                </a>
              </ListItem>
            );
          })}
        </>
      )}
    </React.Fragment>
  );
}
