import { Skeleton } from "@mui/material";

export default function LoadingSkeleton() {
  return (
    <div className="d-flex flex-row justify-content-center">
      {Array(2)
        .fill(0)
        .map((_, index) => {
          return (
            <div className="d-flex flex-column align-items-center" key={index}>
              <Skeleton
                variant="rectangular"
                width={"100%"}
                className="mt-4 mb-2"
                height={20}
              />
              {Array(10)
                .fill(0)
                .map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    className="my-2 mx-1"
                    width={300}
                    height={20}
                  />
                ))}
            </div>
          );
        })}
    </div>
  );
}
