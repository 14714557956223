import React, { useContext, useEffect, useState } from "react";

import Header from "./../../Component/Header/Header";
import Tabs from "./../../Component/UI/Tabs/Tabs";
import Brand from "./Brand/Brand";
import Product from "./Product/Product";
import Personas from "./Personas/Personas";
import Geography from "./Geography/Geography";
import Metrics from "./Metrics/Metrics";
import Users from "./Users/Users";
import BrandForm from "./Brand/Addbrand";
import Addproduct from "./Product/Addproduct";
import PersonasForm from "./Personas/AddPersonas";
import GeographyForm from "./Geography/AddGeography";
import UserForm from "./Users/Addusers";
import MetricsForm from "./Metrics/Addmetrics";
import classes from "./settings.module.css";
import Modal from "../../Component/Modal/Modal";
import { addUser } from "../../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "../../context/auth-context";
import {
  createPersona,
  fetchPersonas,
  updatePersona,
} from "../../redux/actions/personasActions";
import {
  createBrand,
  fetchBrands,
  updateBrand,
} from "../../redux/actions/brandsActions";
import {
  createBrandProduct,
  fetchBrandProducts,
  updateBrandProduct,
} from "../../redux/actions/brandProductsActions";
import {
  fetchCustomerLlms,
  generateBrandMetrics,
  generateProductMetrics,
  getGeographies,
  getLlms,
  getMarkets,
} from "../../services/apiService";
import {
  createGeoMarket,
  fetchGeoMarkets,
} from "../../redux/actions/geoMarketsActions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import MonitoringSetup from "./MonitoringSetup/MonitoringSetup";
import BrandPillars from "./BrandPillars/BrandPillars";
import BrandPillarsForm from "./BrandPillars/AddBrandPillars";
import {
  creteCoreAttributes,
  deleteCoreAttributes,
  updateCoreAttributes,
} from "../../redux/actions/coreAttributeActions";
const TAB_OPTIONS = [
  { name: "Brand", value: "brand" },
  { name: "Product", value: "product" },
  { name: "Personas", value: "personas" },
  { name: "Brand Pillars", value: "brand_pillars" },
  { name: "Metrics", value: "metrics" },
  { name: "Geography & Markets", value: "geo_market" },
  { name: "Monitoring Setups", value: "monitoring" },
  { name: "Admin", value: "admin" },
  { name: "Users", value: "users" },
];
const availableCompetitors = [
  "Nike",
  "Puma",
  "Reebok",
  "Skechers",
  "Vans",
  "New Balance",
  "Asics",
];
export default function Settings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState("brand");
  const [isModalOpen, setModalOpen] = useState(false);
  const [brandName, setBrandName] = useState("");
  const [details, setDetails] = useState("");
  const [brandCategoryName, setBrandCategoryName] = useState("");
  const [brandCategoryDescription, setBrandCategoryDescription] = useState("");
  const [competitorOption, setCompetitorOption] = useState("manual");
  const [competitors, setCompetitors] = useState([]);
  const [brandPersona, setBrandPersona] = useState([]);
  const [personasName, setPersonasName] = useState("");
  const [personasDetails, setpersonasDetails] = useState("");
  const [productName, setProductName] = useState("");
  const [productDetails, setProductDetails] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productCategoryDescription, setProductCategoryDescription] =
    useState("");
  const [brandPillar, setBrandPillar] = useState({ name: "", description: "" });
  const [metricsName, setMetricsName] = useState("");
  const [metricsDetails, setMetricsDetails] = useState("");

  const [geographyName, setGeographyName] = useState("");
  const [market, setMarket] = useState();

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const { addUserError, adduserSuccess } = useSelector((state) => state.user);
  const [llms, setLlms] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [geographies, setGeographies] = useState([]);
  const [customerLlm, setCustomerLlm] = useState([]);
  const [selectGeograph, setSelectGeograph] = useState(null);
  const [selectMarket, setSelectMarket] = useState([]);
  const brands = useSelector((state) => state.brands);
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const brandProducts = useSelector((state) => state.brandProducts);
  const [selectPersonasID, setSelectPersonasID] = useState(null);
  const brandsData = brands?.data?.data;
  const brandProductsData = brandProducts?.data?.data;
  const customerId = authCtx?.user?.customer_id || 0;
  const coreAttributes = useSelector((state) => state.coreAttributes);
  const [selectBrandID, setSelectBrandID] = useState(null);
  const [selectProductID, setSelectProductID] = useState(null);

  useEffect(() => {
    if (adduserSuccess || addUserError) {
      setModalOpen(false);
    }
  }, [adduserSuccess, addUserError]);

  useEffect(() => {
    if (brands?.data && brands?.data.length === 0) {
      dispatch(fetchBrands(customerId));
    }
    if (brandProducts?.data && brandProducts?.data.length === 0) {
      dispatch(fetchBrandProducts(customerId));
    }
    async function fetchLlmsData() {
      const llm = await getLlms();
      const market = await getMarkets();
      const geo = await getGeographies();
      const customerLlms = await fetchCustomerLlms([customerId]);
      setGeographies(geo?.data);
      setMarkets(market?.data);
      setLlms(llm?.data);
      setCustomerLlm(customerLlms);
    }
    fetchLlmsData();
  }, [dispatch]);
  const handleCompetitorToggle = (competitor) => {
    console.log(competitor, "competitor", competitors);
    if (competitors?.includes(competitor)) {
      setCompetitors(competitors.filter((c) => c !== competitor));
    } else {
      setCompetitors([...competitors, competitor]);
    }
  };

  const handleAddAll = () => {
    setCompetitors(availableCompetitors);
  };

  const handleSubmit = () => {
    console.log({
      brandName,
      details,
      brandCategoryName,
      brandCategoryDescription,
      competitors,
      brandPersona,
    });
    setModalOpen(false);
  };
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setBrandName("");
    setDetails("");
    setBrandCategoryName("");
    setBrandCategoryDescription("");
    setCompetitorOption("manual");
    setCompetitors([]);
    setBrandPersona([]);
    setPersonasName("");
    setpersonasDetails("");
    setProductName("");
    setProductDetails("");
    setProductCategory("");
    setProductCategoryDescription("");
    setMetricsName("");
    setMetricsDetails("");
    setGeographyName("");
    setMarket([]);
    setUserEmail("");
    setModalOpen(false);
    setUpdateModalOpen(false);
    setSelectPersonasID(null);
    setSelectBrandID(null);
    setSelectProductID(null);
    setBrandPillar({ name: "", description: "" });
  };

  const handleBrandPillar = async (actionType, payload) => {
    console.log(isUpdateModalOpen, payload);

    switch (actionType) {
      case "delete":
        await dispatch(deleteCoreAttributes(payload.id, customerId));
        console.log("DELETED");
        break;

      case "open_update_modal":
        setBrandPillar({ ...payload });
        setUpdateModalOpen(true);
        console.log("UPDATED");
        break;

      case "update":
        await dispatch(updateCoreAttributes(payload, customerId));
        handleCloseModal();
        console.log("UPDATED " + payload);
        break;

      case "create":
        await dispatch(
          creteCoreAttributes({ ...payload, customer_id: customerId })
        );
        handleCloseModal();
        console.log("CREATED");

        break;

      default:
        break;
    }
  };

  const renderForm = () => {
    switch (selectedTab) {
      case "brand":
        return (
          <BrandForm
            brandName={brandName}
            setBrandName={setBrandName}
            details={details}
            setDetails={setDetails}
            brandCategoryName={brandCategoryName}
            setBrandCategoryName={setBrandCategoryName}
            brandCategoryDescription={brandCategoryDescription}
            setBrandCategoryDescription={setBrandCategoryDescription}
            competitorOption={competitorOption}
            setCompetitorOption={setCompetitorOption}
            competitors={competitors}
            setCompetitors={setCompetitors}
            availableCompetitors={availableCompetitors}
            handleCompetitorToggle={handleCompetitorToggle}
            handleAddAll={handleAddAll}
            brandPersona={brandPersona}
            setBrandPersona={setBrandPersona}
            classes={classes}
            handleCloseModal={handleCloseModal}
            coreAttributes={coreAttributes}
            createNewBrand={createNewBrand}
            isUpdateModalOpen={isUpdateModalOpen}
            updateCreatedBrand={updateCreatedBrand}
          />
        );
      case "product":
        return (
          <Addproduct
            productName={productName}
            setProductName={setProductName}
            productDetails={productDetails}
            setProductDetails={setProductDetails}
            productCategory={productCategory}
            setProductCategory={setProductCategory}
            setProductCategoryDescription={setProductCategoryDescription}
            productCategoryDescription={productCategoryDescription}
            brandPersona={brandPersona}
            setBrandPersona={setBrandPersona}
            classes={classes}
            competitors={competitors}
            setCompetitors={setCompetitors}
            availableCompetitors={availableCompetitors}
            handleCompetitorToggle={handleCompetitorToggle}
            handleCloseModal={handleCloseModal}
            createNewproduct={createNewproduct}
            isUpdateModalOpen={isUpdateModalOpen}
            updateCreatedProduct={updateCreatedProduct}
          />
        );
      case "personas":
        return (
          <PersonasForm
            handleAddNewPersona={handleAddNewPersona}
            classes={classes}
            personasDetails={personasDetails}
            setpersonasDetails={setpersonasDetails}
            personasName={personasName}
            setPersonasName={setPersonasName}
            handleCloseModal={handleCloseModal}
            isUpdateModalOpen={isUpdateModalOpen}
            updatePersonas={updatePersonas}
          />
        );
      case "brand_pillars":
        return (
          <BrandPillarsForm
            classes={classes}
            handleCloseModal={handleCloseModal}
            isUpdateModalOpen={isUpdateModalOpen}
            brandPillar={brandPillar}
            setBrandPillar={setBrandPillar}
            handleBrandPillar={handleBrandPillar}
          />
        );
      case "geo_market":
        return (
          <GeographyForm
            geographyName={geographyName}
            setGeographyName={setGeographyName}
            market={market}
            setMarket={setMarket}
            classes={classes}
            handleCloseModal={handleCloseModal}
            markets={markets}
            geographies={geographies}
            selectGeograph={selectGeograph}
            setSelectGeograph={setSelectGeograph}
            selectMarket={selectMarket}
            setSelectMarket={setSelectMarket}
            createNewGeoMarket={createNewGeoMarket}
          />
        );
      case "users":
        return (
          <UserForm
            classes={classes}
            userName={userName}
            setUserName={setUserName}
            userEmail={userEmail}
            setUserEmail={setUserEmail}
            emailError={emailError}
            setEmailError={setEmailError}
            handleCloseModal={handleCloseModal}
            onSubmit={addUserHandler}
          />
        );
      default:
        return (
          <MetricsForm
            classes={classes}
            metricsName={metricsName}
            setMetricsName={setMetricsName}
            metricsDetails={metricsDetails}
            setMetricsDetails={setMetricsDetails}
          />
        );
    }
  };
  const addUserHandler = async (email) => {
    const payload = {
      email: email,
      password: 123456, //temp password
      customer_id: authCtx.user.customer_id,
      role_id: 3,
    };
    dispatch(addUser(authCtx.user, payload));
  };
  const handleAddNewPersona = (personaInfo) => {
    console.log(personaInfo, "personal");
    dispatch(createPersona(authCtx.user.customer_id, personaInfo)).then(() => {
      handleCloseModal();
      dispatch(fetchPersonas(authCtx.user.customer_id));
    });
  };
  const createNewGeoMarket = (selectMarket, geographyValue) => {
    let payload = {
      geographyItem: geographyValue,
      marketList: selectMarket,
    };
    dispatch(createGeoMarket(customerId, payload))
      .then(() => {
        dispatch(fetchGeoMarkets(customerId));
        setModalOpen(false);
      })
      .catch((error) => {
        setModalOpen(false);
        dispatch(fetchGeoMarkets(customerId));
        toast.error(
          error?.response?.data?.message || "something went wrong !",
          {
            autoClose: 700,
          }
        );
      });
    console.log(selectMarket, geographyValue, " payload");
  };

  const createNewBrand = (
    competitors,
    brandName,
    brandPersona,
    details,
    brandCategoryName,
    brandCategoryDescription,
    coreAttributesList,
    purchaseCriteria
  ) => {
    const payload = {
      brand_name: brandName,
      brand_description: details,
      category_name: brandCategoryName,
      category_description: brandCategoryDescription,
      brandCategoryDimensions: purchaseCriteria,
      comp_brands: [],
      personaIds: brandPersona.map((item) => item.persona_id),
      coreAttributesList,
    };
    console.log(`Body: ${JSON.stringify(payload)}`);
    const llm = customerLlm?.data.filter(
      (obj) => obj.llm_id === 1 && obj.market_id === 1
    )[0]?.id;
    dispatch(createBrand(customerId, payload))
      .then((response) => {
        const customerBrandPersonaId = response?.data?.newBrandPersona[0]?.id;

        dispatch(fetchBrands(customerId));
        setModalOpen(false);
        if (customerBrandPersonaId) {
          // setIsInProgress(true);
          generateBrandMetrics(llm, customerBrandPersonaId)
            .then(() => {
              navigate(
                `/brandonboarding/dashboard?brandId=${response?.data?.newBrand?.id}&isDetailPage=1`
              );
              setModalOpen(false);
              // setIsInProgress(false);
            })
            .catch((error) => {
              console.error("Error generating brand metrics:", error);
            });
        } else {
          console.error("Failed to get newBrandPersona ID");
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.message || "something went wrong !",
          {
            autoClose: 700,
          }
        );
        setModalOpen(false);
        console.error("Error in handleAddNewBrand:", error);
      });
  };
  const createNewproduct = (
    productName,
    selectedBrand,
    productDetails,
    productCategory,
    productCategoryDescription,
    competitors,
    brandPersona,
    productDimensions,
    purchaseCriteria
  ) => {
    const payload = {
      product_name: productName,
      product_description: productDetails,
      category_name: productCategory,
      category_description: productCategoryDescription,
      comp_products: [],
      personaIds: brandPersona.map((item) => item.persona_id),
      brand_id: selectedBrand,
      coreAttributesList: coreAttributes.data.data.filter((o) => o.is_default),
      productDimensions,
      purchaseCriteria,
    };
    console.log(payload);
    const llm = customerLlm?.data.filter(
      (obj) => obj.llm_id == 1 && obj.market_id == 1
    )[0]?.id;
    dispatch(createBrandProduct(customerId, payload))
      .then((response) => {
        dispatch(fetchBrandProducts(customerId));
        const customerBrandProductPersonaId =
          response?.data?.newBrandProductPersona[0]?.id;
        setModalOpen(false);
        if (customerBrandProductPersonaId) {
          // setIsInProgress(true);
          generateProductMetrics(llm, customerBrandProductPersonaId)
            .then(() => {
              navigate(
                `/brandonboarding/dashboard?brandProductId=${response?.data?.newBrandProduct?.id}&isDetailPage=1`
              );
              setModalOpen(false);
              // setIsInProgress(false);
            })
            .catch((error) => {
              console.error(
                "Error generating brand product metrics:",
                error?.message
              );
            });
        } else {
          console.error("Failed to get newBrandProductPersona ID");
        }
      })
      .catch((error) => {
        dispatch(fetchBrandProducts(customerId));
        setModalOpen(false);
        // setIsInProgress(false);
        console.error("Error in handleAddNewBrandProduct:", error);
        toast.error(
          error?.response?.data?.message || "something went wrong !",
          {
            autoClose: 700,
          }
        );
      });
  };
  const updatePersonas = (personaInfo) => {
    console.log(personaInfo, "update", selectPersonasID);
    dispatch(updatePersona(customerId, selectPersonasID.id, personaInfo)).then(
      () => {
        handleCloseModal();
        dispatch(fetchPersonas(customerId));
      }
    );
  };
  const updateCreatedBrand = (
    competitors,
    brandName,
    brandPersona,
    details,
    brandCategoryName,
    brandCategoryDescription,
    coreAttributesList,
    purchaseCriteria
  ) => {
    const payload = {
      brand_name: brandName,
      brand_description: details,
      category_name: brandCategoryName,
      category_description: brandCategoryDescription,
      brandCategoryDimensions: purchaseCriteria,
      comp_brands: [],
      personaIds: brandPersona.map((item) => item.persona_id),
      coreAttributesList,
    };
    console.log(payload, coreAttributes, "selectBrandID");
    const llm = customerLlm?.data.filter(
      (obj) => obj.llm_id === 1 && obj.market_id === 1
    )[0]?.id;
    dispatch(updateBrand(customerId, selectBrandID.id, payload))
      .then(() => {
        handleCloseModal();
        dispatch(fetchBrands(customerId));
      })
      .catch((error) => {
        // console.log('err==>', error)
        handleCloseModal();
        toast.error(
          error?.response?.data?.message || "something went wrong !",
          {
            autoClose: 700,
          }
        );
      });
  };
  const updateCreatedProduct = (
    productName,
    selectedBrand,
    productDetails,
    productCategory,
    productCategoryDescription,
    competitors,
    brandPersona,
    productDimensions,
    purchaseCriteria
  ) => {
    let brandProductInfo = {
      product_name: productName,
      product_description: productDetails,
      category_name: productCategory,
      category_description: productCategoryDescription,
      comp_products: [],
      personaIds: brandPersona.map((item) => item.persona_id),
      brand_id: selectedBrand,
      coreAttributesList: coreAttributes.data.data,
      productDimensions,
      purchaseCriteria,
    };

    dispatch(
      updateBrandProduct(customerId, selectProductID.id, brandProductInfo)
    )
      .then(() => {
        handleCloseModal();
        dispatch(fetchBrandProducts(authCtx?.user?.customer_id || 0));
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.message || "something went wrong !",
          {
            autoClose: 700,
          }
        );
      });
  };

  const selectedTabName = TAB_OPTIONS.find(
    (tab) => tab.value === selectedTab
  )?.name;

  return (
    <>
      <Modal
        title={
          isUpdateModalOpen
            ? `Update ${selectedTabName}`
            : `Add ${selectedTabName}`
        }
        // title={`Add ${selectedTab}`}
        // showActions
        open={isModalOpen || isUpdateModalOpen}
        handleClose={handleCloseModal}
        confirmBtnText="Add"
      >
        {renderForm()}
      </Modal>
      <section className="mx-4">
        <Header
          actionBtnVariant="contained"
          actionText={`Add ${selectedTabName}`}
          title="Settings"
          onActionClick={handleOpenModal}
        />
        <Tabs
          options={TAB_OPTIONS}
          selected={selectedTab}
          onChange={(tab) => setSelectedTab(tab)}
        />
        <div className="p-4" style={{ background: "white" }}>
          {selectedTab === "brand" && (
            <Brand
              setUpdateModalOpen={setUpdateModalOpen}
              setModalOpen={setModalOpen}
              setBrandName={setBrandName}
              setDetails={setDetails}
              setBrandCategoryName={setBrandCategoryName}
              setBrandCategoryDescription={setBrandCategoryDescription}
              setCompetitorOption={setCompetitorOption}
              setCompetitors={setCompetitors}
              setBrandPersona={setBrandPersona}
              setSelectBrandID={setSelectBrandID}
            />
          )}
          {selectedTab === "product" && (
            <Product
              setUpdateModalOpen={setUpdateModalOpen}
              setModalOpen={setModalOpen}
              setProductName={setProductName}
              setProductDetails={setProductDetails}
              setProductCategory={setProductCategory}
              setProductCategoryDescription={setProductCategoryDescription}
              setBrandPersona={setBrandPersona}
              setCompetitors={setCompetitors}
              setSelectProductID={setSelectProductID}
            />
          )}
          {selectedTab === "personas" && (
            <Personas
              setSelectPersonasID={setSelectPersonasID}
              setUpdateModalOpen={setUpdateModalOpen}
              setModalOpen={setModalOpen}
              setPersonasName={setPersonasName}
              setpersonasDetails={setpersonasDetails}
            />
          )}
          {selectedTab === "brand_pillars" && (
            <BrandPillars handleBrandPillar={handleBrandPillar} />
          )}
          {selectedTab === "metrics" && <Metrics />}
          {selectedTab === "geo_market" && <Geography />}
          {selectedTab === "users" && <Users />}
          {selectedTab === "monitoring" && <MonitoringSetup />}
        </div>
      </section>
    </>
  );
}
