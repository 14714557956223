import { Skeleton } from "@mui/material";

export default function AttributeSkeleton() {
  return (
    <div style={{ margin: "20px 0"}}>
      <Skeleton
        variant="text"
        style={{ width: 100, height: 20, margin: "3px 0" }}
      ></Skeleton>
      {Array(8)
        .fill(0)
        .map((_, index) => {
          return (
            <Skeleton
              key={index}
              variant="text"
              style={{ width: "100%", height: 20, margin: "3px 0" }}
            ></Skeleton>
          );
        })}
    </div>
  );
}
