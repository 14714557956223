import React from "react";
import { Button } from "@mui/material";
import TextInput from "../../../Component/UI/TextInput/TextInput";
import classes from "../settings.module.css";
const BrandPillarsForm = ({
  brandPillar,
  handleCloseModal,
  setBrandPillar,
  handleBrandPillar,
}) => {
  const handleFormSubmit = (e) => {
    e.preventDefault();

    brandPillar?.id
      ? handleBrandPillar("update", brandPillar)
      : handleBrandPillar("create", brandPillar);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className={classes.divContainer}>
        <TextInput
          label="Brand Pillar Name"
          variant="outlined"
          fullWidth
          value={brandPillar?.name}
          required
          disabled={brandPillar?.is_default}
          onChange={(e) =>
            setBrandPillar((prev) => {
              return { ...prev, name: e.target.value };
            })
          }
        />

        <TextInput
          label="Details for Brand Pillar"
          variant="outlined"
          fullWidth
          value={brandPillar?.description}
          required
          disabled={brandPillar?.is_default}
          onChange={(e) =>
            setBrandPillar((prev) => {
              return { ...prev, description: e.target.value };
            })
          }
        />
        <div className={classes.buttonDiv}>
          <Button
            variant="contained"
            disabled={brandPillar?.is_default}
            type="submit"
          >
            Add
          </Button>
          <Button variant="outlined" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};

export default BrandPillarsForm;
