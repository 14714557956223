import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import EmptyView from "../../../Component/EmptyView/EmptyView";

const ReVerticalMultibarChart = ({
  data,
  index,
  isBrandSources,
  sourceAlias,
}) => {
  const filteredData = data
    ?.filter((obj) => {
      const urlsArray = sourceAlias[obj?.name];
      return Array.isArray(urlsArray) && urlsArray.length > 0;
    })
    ?.map((item) => {
      const newItem = { ...item };
      Object.keys(newItem).forEach((key) => {
        if (newItem[key] === null || newItem[key] === undefined) {
          delete newItem[key];
        }
      });
      return newItem;
    });
  // ?.slice(0, 10);

  // Get all unique keys from the data array
  const allKeys = Array.from(
    new Set(
      filteredData?.flatMap((item) =>
        Object.keys(item).filter((key) => key !== "name")
      )
    )
  )?.sort((a, b) => a.localeCompare(b));

  const brandSourceLabelsMapping = {
    0: "All",
    1: "Sentiment",
    2: "Positive Attributes",
    3: "Negative Attributes",
  };

  const categorySourceLabelsMapping = {
    0: "All",
    1: "Reach",
    2: "Purchase Dimensions",
    3: "Purchase Dimensions Performence/Rating",
    4: "Brand Pillars Performence/Rating",
  };

  const llmColorsMapping = {
    "gpt-4o": "#C7F0EC",
    Claude3: "#FF6F6F",
    Gemini: "#CEB4FF",
    llama3: "#FFCE6E",
    Perplexity: "#92B7FE",
    "llm-web": "#FEE7E6",
  };

  const colors = [
    "#C7F0EC",
    "#FF6F6F",
    "#CEB4FF",
    "#FFCE6E",
    "#92B7FE",
    "#FEE7E6",
    "#CEB4FF",
    "#FFCE6E",
  ];

  const barSize = 30; // Fixed width for each bar
  const margin = { top: 20, right: 20, left: 40, bottom: 5 };

  // Calculate the chart width based on the number of keys (brands) and attributes
  const chartWidth = allKeys.length * barSize + margin.left + margin.right;

  const BAR_HEIGHT = 75; // Height per bar
  const MIN_HEIGHT = 300; // Minimum height for the chart

  const chartHeight = Math.max(MIN_HEIGHT, filteredData?.length * BAR_HEIGHT);

  const CustomYAxisTick = ({ x, y, payload }) => {
    const links = sourceAlias?.[payload.value] || []; // Ensure `links` is always an array

    const MAX_LENGTH = 18; // Maximum characters to show
    const displayText =
      payload.value.length > MAX_LENGTH
        ? `${payload.value.substring(0, MAX_LENGTH)}...`
        : payload.value;

    // Filter links based on http_status
    const validLinks = links.filter((link) => link.http_status === "200");

    // Check if there are valid links
    const hasValidLinks = validLinks.length > 0;

    // Determine if tooltip should be shown
    const shouldShowTooltip = hasValidLinks;

    // Determine styles based on http_status
    const isForbidden = links?.every((link) => link.http_status === "403");
    const textStyle = isForbidden
      ? { fill: "gray", cursor: "not-allowed", textDecoration: "none" }
      : { fill: "blue", cursor: "pointer", textDecoration: "underline" };

    const tooltipContent = (
      <div>
        {validLinks.map((link, index) => (
          <div key={index}>
            <Typography
              component="a"
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "block",
                color: "white",
                textDecoration: "underline",
                cursor: "pointer",
                marginBottom: 4,
              }}
            >
              {link.url}
            </Typography>
            {index < validLinks.length - 1 && (
              <hr style={{ borderColor: "white", margin: "6px 0" }} />
            )}
          </div>
        ))}
      </div>
    );

    return (
      <Tooltip
        title={shouldShowTooltip ? tooltipContent : ""}
        placement="top-start"
        arrow
        disableInteractive={false}
        disableHoverListener={!shouldShowTooltip} // Disable tooltip if it shouldn't show
      >
        <g>
          <text
            x={x}
            y={y}
            dy={5}
            dx={-10}
            textAnchor="end"
            fontSize={14}
            fontFamily="Roboto Flex, sans-serif"
          >
            <tspan style={textStyle}>{displayText}</tspan>
          </text>
        </g>
      </Tooltip>
    );
  };

  return (
    <>
      <h5 className="me-3">
        {isBrandSources
          ? brandSourceLabelsMapping[index]
          : categorySourceLabelsMapping[index]}
      </h5>

      {!filteredData || filteredData?.length == 0 ? (
        <EmptyView message="Data Not Available" />
      ) : (
        <ResponsiveContainer
          width="100%"
          height={chartHeight}
          style={{ backgroundColor: "white" }}
        >
          <BarChart
            layout="vertical"
            data={filteredData}
            width={chartWidth} // Dynamically calculated width
            height={300}
            // data={data}
            margin={margin}
            barCategoryGap="20%" // Adjust the gap between bars in a category
          >
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <Legend
              verticalAlign="top"
              align="center"
              wrapperStyle={{ paddingBottom: 20 }} // Adjust padding to create space between legend and bars
            />
            <XAxis
              type="number" // X-axis now represents the values
              domain={[0, 100]} // Set limit from 0 to 100
              tickCount={11} // Number of ticks including 0
              tickLine={false}
              tick={{
                fill: "#b6b7b8",
                fontFamily: "Roboto Flex, sans-serif",
                fontSize: 14,
              }}
              axisLine={{ stroke: "#b6b7b8", strokeWidth: 1 }}
            />
            <YAxis
              dataKey="name" // Y-axis now represents the names
              type="category"
              tickLine={false}
              width={150} // Increase the width to allow for wrapping
              axisLine={{ stroke: "white" }}
              tick={<CustomYAxisTick />}
              padding={{ top: 10, bottom: 10 }}
            />

            <RechartsTooltip />

            {allKeys.map((brand, index) => (
              <Bar
                key={brand}
                dataKey={brand}
                fill={llmColorsMapping?.[brand] || colors[index]} // Random color
                barSize={barSize} // Fixed bar width for better visibility
                isAnimationActive={true}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      )}

      {filteredData && filteredData.length ? (
        <p style={{ textAlign: "center" }}>Signal Strength(%)</p>
      ) : null}
    </>
  );
};

export default ReVerticalMultibarChart;
