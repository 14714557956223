import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";

import {
  deletePersona,
  fetchPersonas,
} from "../../../redux/actions/personasActions";
import classes from "../Brand/Brand.module.css";
import { AuthContext } from "../../../context/auth-context";
import Table, {
  StyledTableCell,
  StyledTableRow,
} from "../../../Component/UI/Table/Table";
import MUIIconButton from "./../../../Component/UI/MUIIconButton/MUIIconButton";
import Spinner from "./../../../Component/Spinner/Spinner";

const TABLE_HEADERS = [
  { id: "Personas", name: "Personas", sorting: true },
  { id: "details", name: "Details", sorting: false },
  { id: "actions", name: "Actions", sorting: false },
];

const Personas = ({
  setPersonasName,
  setpersonasDetails,
  setModalOpen,
  setUpdateModalOpen,
  setSelectPersonasID,
}) => {
  const dispatch = useDispatch();
  const personas = useSelector((state) => state.personas);
  const { user } = useContext(AuthContext);
  const customerId = user?.customer_id || 0;

  useEffect(() => {
    if (personas?.data && personas?.data.length === 0) {
      dispatch(fetchPersonas(customerId));
    }
  }, [dispatch]);

  const personasData = personas.data.data;

  const renderRow = (item) => (
    <StyledTableRow key={item.id}>
      <StyledTableCell component="th" scope="row" style={{ width: 450 }}>
        <div className={classes.brand_product}>
          <span>{item.persona.name}</span>
        </div>
      </StyledTableCell>
      <StyledTableCell align="left" style={{ width: 450 }}>
        {item.persona.description}
      </StyledTableCell>

      <StyledTableCell align="left">
        <div>
          <MUIIconButton
            style={{ color: "#007aff" }}
            className={classes.action_btn}
            tooltip="Update"
            onClick={() => {
              setPersonasName(item.persona.name);
              setpersonasDetails(item.persona.description);
              setModalOpen(true);
              setUpdateModalOpen(true);
              setSelectPersonasID(item);
            }}
          >
            <EditIcon />
          </MUIIconButton>
          <MUIIconButton
            style={{ color: "#da1a04" }}
            tooltip="Delete"
            className={classes.action_btn}
            onClick={() => handleDeletePersona(item.id)}
          >
            <DeleteOutlineIcon />
          </MUIIconButton>
        </div>
      </StyledTableCell>
    </StyledTableRow>
  );
  const handleDeletePersona = (personaId) => {
    dispatch(deletePersona(customerId, personaId)).then(() => {
      dispatch(fetchPersonas(customerId));
    });
  };
  return (
    <>
      {personas.loading && <Spinner />}
      {!personas.loading && personasData && personasData?.length > 0 && (
        <Table headers={TABLE_HEADERS} data={personasData} render={renderRow} />
      )}
    </>
  );
};

export default Personas;
