import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import EmptyView from "../../../../Component/EmptyView/EmptyView";

const ReVerticalMultibarChart = ({ data, label, sourceFullUrls }) => {
  const top10Objects = data
    // ?.filter((obj) => sourceFullUrls[obj?.name])
    ?.filter((obj) => {
      const urlsArray = sourceFullUrls[obj?.name]; // Get the array of strings from sourceFullUrls
      return Array.isArray(urlsArray) && urlsArray.length > 0; // Check if it's an array with at least one string
    })
    .sort((a, b) => {
      const aValue = Object.values(a).find((v) => typeof v === "number"); // Get dynamic value
      const bValue = Object.values(b).find((v) => typeof v === "number");
      return bValue - aValue; // Sort by descending order
    })
    .slice(0, 10);

  // Preprocess data to remove null values and avoid empty spaces in bars
  const filteredData = top10Objects?.map((item) => {
    const newItem = { ...item };
    Object.keys(newItem).forEach((key) => {
      if (newItem[key] === null || newItem[key] === undefined) {
        delete newItem[key];
      }
    });
    return newItem;
  });

  // Get all unique keys from the data array
  const allKeys = Array.from(
    new Set(
      filteredData?.flatMap((item) =>
        Object.keys(item).filter((key) => key !== "name")
      )
    )
  );

  const llmColorsMapping = {
    "gpt-4o": "#C7F0EC",
    Claude3: "#FF6F6F",
    Gemini: "#CEB4FF",
    llama3: "#FFCE6E",
    Perplexity: "#92B7FE",
    "llm-web": "#FEE7E6",
  };

  const barSize = 30; // Fixed width for each bar
  const margin = { top: 20, right: 20, left: 40, bottom: 5 };

  // Calculate the chart width based on the number of keys (brands) and attributes
  const chartWidth = allKeys.length * barSize + margin.left + margin.right;

  const BAR_HEIGHT = 75; // Height per bar
  const MIN_HEIGHT = 300; // Minimum height for the chart

  const chartHeight = Math.max(MIN_HEIGHT, filteredData?.length * BAR_HEIGHT);

  const CustomYAxisTick = ({ x, y, payload }) => {
    const links = sourceFullUrls?.[payload.value] || []; // Ensure `links` is always an array

    const MAX_LENGTH = 18; // Maximum characters to show

    // Truncate the text and add "..." if it exceeds the maximum length
    const displayText =
      payload.value.length > MAX_LENGTH
        ? `${payload.value.substring(0, MAX_LENGTH)}...`
        : payload.value;

    // Tooltip content with clickable links
    const tooltipContent = (
      <div>
        {links.length > 0 ? (
          links.map((link, index) => (
            <>
              <Typography
                key={index}
                component="a"
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "block",
                  color: "white",
                  textDecoration: "underline",
                  cursor: "pointer",
                  marginBottom: 4,
                }}
              >
                {link}
              </Typography>

              {index < links.length - 1 && (
               <hr style={{ borderColor: "white", margin: "6px 0" }} />
              )}
            </>
          ))
        ) : (
          <Typography>No links available</Typography>
        )}
      </div>
    );

    return (
      <Tooltip
        title={tooltipContent} // Tooltip content
        placement="top-start"
        arrow
        disableInteractive={false} // Allow interaction with tooltip content
      >
        <g>
          <text
            x={x}
            y={y}
            dy={5}
            dx={-10}
            textAnchor="end"
            fontSize={14}
            fontFamily="Roboto Flex, sans-serif"
          >
            <tspan
              fill={"blue"}
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              {displayText}
            </tspan>
          </text>
        </g>
      </Tooltip>
    );
  };

  return (
    <>
      <h5 className="me-3">{label}</h5>

      {!filteredData || filteredData?.length == 0 ? (
        <EmptyView message="Data Not Available" />
      ) : (
        <ResponsiveContainer
          width="100%"
          height={chartHeight}
          style={{ backgroundColor: "white" }}
        >
          <BarChart
            layout="vertical"
            data={filteredData}
            width={chartWidth} // Dynamically calculated width
            height={300}
            // data={data}
            margin={margin}
            barCategoryGap="20%" // Adjust the gap between bars in a category
          >
            <XAxis
              type="number" // X-axis now represents the values
              domain={[0, 100]} // Set limit from 0 to 100
              tickCount={11} // Number of ticks including 0
              tickLine={false}
              tick={{
                fill: "#b6b7b8",
                fontFamily: "Roboto Flex, sans-serif",
                fontSize: 14,
              }}
              axisLine={{ stroke: "#b6b7b8", strokeWidth: 1 }}
            />
            <YAxis
              dataKey="name" // Y-axis now represents the names
              type="category"
              tickLine={false}
              width={150} // Increase the width to allow for wrapping
              tick={<CustomYAxisTick />}
              axisLine={{ stroke: "white" }}
              padding={{ top: 10, bottom: 10 }}
            />

            <RechartsTooltip />

            {allKeys.map((brand, index) => (
              <Bar
                key={brand}
                dataKey={brand}
                fill={
                  llmColorsMapping?.[brand] ||
                  `#${Math.floor(Math.random() * 16777215).toString(16)}`
                } // Random color
                barSize={barSize} // Fixed bar width for better visibility
                isAnimationActive={true}
                radius={[10, 10, 10, 10]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      )}

      {filteredData && filteredData?.length ? (
        <p style={{ textAlign: "center" }}>Signal Strength (%)</p>
      ) : null}
    </>
  );
};

export default ReVerticalMultibarChart;
