import { useState, useContext, useRef, useEffect } from "react";

import api from "../../../BrandOnBoarding/api";
import { DiscoveryContext } from "../../Discovery/discovery-context";

export default function useProductSpecs() {
  const [productSpecs, setProductSpecs] = useState([]);
  const [loading, setLoading] = useState(false);

  const abortControllerRef = useRef();
  const discoveryCtx = useContext(DiscoveryContext);

  let competitors = discoveryCtx.competitors
    .slice(discoveryCtx.type == "category" ? 0 : 1, 4)
    .map((c) => c.name);

  useEffect(() => {
    if (discoveryCtx.abort_launch) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    }
  }, [discoveryCtx.abort_launch]);

  async function getProductSpec() {
    abortControllerRef.current = new AbortController();
    try {
      setLoading(true);
      const response = await api.post(
        "/metrics/product-specs",
        {
          llm: "gpt-4o",
          products: competitors,
          category: discoveryCtx.category,
        },
        {
          signal: abortControllerRef.current.signal,
        }
      );
      const data = response.data;
      setProductSpecs(data.specs);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  return {
    productSpecs,
    loading,
    getProductSpec,
  };
}
