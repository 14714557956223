import { Skeleton } from "@mui/material";

import useBingImage from "./useBingImage";

export default function BingImage({ entity, category, className }) {
  const { loading, image } = useBingImage(entity, category);
  return (
    <>
      {loading && (
        <Skeleton
          variant="rectangular"
          animation="wave"
          style={{ background: "lightgray", height: 400 }}
        ></Skeleton>
      )}
      {image && !loading && (
        <div
          style={{ backgroundImage: `url(${image})` }}
          className={className}
        />
      )}
    </>
  );
}
