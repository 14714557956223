import React, { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputSelectionComponent from "../Dashboard/Component/inputSelection";
import {
  fetchCustomerLlms,
  getLlms,
  getCustomerMetrcisAlertsData,
  getAllBrands,
} from "../../services/apiService";
import DateRangePicker from "../Dashboard/Component/DateRangePicker";
import { AuthContext } from "../../context/auth-context";
import SummaryCard from "./SummaryCard/SummaryCard";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { fetchBrands } from "../../redux/actions/brandsActions";
import { fetchBrandProducts } from "../../redux/actions/brandProductsActions";
import EmptyView from "../../Component/EmptyView/EmptyView";

const HomePage = () => {
  const [selectedLlmItems, setSelectedLlmItems] = useState([]);
  const [selectedSubFrequencyItems, setSelectedSubFrequencyItems] =
    useState("Current");
  const [dbLlms, setDbLlms] = useState([]);
  const [customerLlm, setCustomerLlm] = useState([]);
  const [loading, setLoading] = useState(true);
  const [metricsDataWithAlerts, setMetricsDataWithAlerts] = useState([]);
  const [llmDomains, setLlmDomains] = useState();

  const dispatch = useDispatch();

  const handleLlmCheckChange = (selected) => {
    let selectedValues = selected?.map((option) => option?.name);

    setSelectedLlmItems(selectedValues || []);
  };

  const { user } = useContext(AuthContext);

  const customerId = user?.customer_id || 0;

  useEffect(() => {
    dispatch(fetchBrands(customerId));
    dispatch(fetchBrandProducts(customerId));
  }, []);

  useEffect(() => {
    async function fetchData() {
      const allBrands = await getAllBrands();
      const { llms: allLlms } = allBrands?.data || {};

      const createDomainMap = (data, key = "domain_url") =>
        data?.reduce((acc, item) => {
          acc[item.name] = item[key];
          return acc;
        }, {});

      setLlmDomains(createDomainMap(allLlms));

      const llms = await getLlms();
      const customerSpecificLlms = await fetchCustomerLlms([
        Number(customerId),
      ]);
      setDbLlms(llms?.data);
      setCustomerLlm(customerSpecificLlms?.data);
      setSelectedLlmItems(llms?.data?.map((obj) => obj?.name));
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedLlmItems?.length && selectedSubFrequencyItems) {
      setLoading(true);
      handleBrandIndexData();
    }
  }, [selectedLlmItems, selectedSubFrequencyItems]);

  const handleBrandIndexData = async () => {
    const llmIds = dbLlms
      .filter((item) => selectedLlmItems.includes(item?.name))
      .map((obj) => obj?.id);

    const customerLlmIds = customerLlm
      .filter(
        (item) =>
          llmIds.includes(item?.llm_id) &&
          item?.market_id == 1 &&
          item?.geography_id == 1
      )
      .map((obj) => obj?.id);

    const payload = {
      customerId,
      llmIds: customerLlmIds,
      timePeriod: selectedSubFrequencyItems,
    };

    try {
      const apiResp = await getCustomerMetrcisAlertsData(payload);
      setMetricsDataWithAlerts(apiResp?.customerMetrcisDataWithAlerts);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err, {
        autoClose: 700,
      });
    }
  };

  const handlesubFrequencyCheckChange = (key) => {
    setSelectedSubFrequencyItems(key);
  };

  return (
    <>
      <div class="bodycontent">
        <div style={{ marginBottom: "-15px", display: "flex" }}>
          <div class="col-md-3">
            <InputSelectionComponent
              selectedOptions={selectedLlmItems}
              handleChange={handleLlmCheckChange}
              options={dbLlms}
              type="llm"
            />
          </div>{" "}
          &nbsp; &nbsp; &nbsp;
          <DateRangePicker
            activeTarget={selectedSubFrequencyItems}
            handleChange={handlesubFrequencyCheckChange}
            isHomePage={true}
          />
        </div>

        <div class="col-md-12 mt-4">
          <div class="card p-3 custCard">
            {loading ? (
              <div
                style={{
                  fontSize: "19px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                  color: "green",
                }}
              >
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Please Wait...
              </div>
            ) : !metricsDataWithAlerts || metricsDataWithAlerts.length === 0 ? (
              <EmptyView message="Nothing here!" />
            ) : (
              <>
                {metricsDataWithAlerts?.map((data) => {
                  return (
                    <>
                      <SummaryCard
                        frequency={selectedSubFrequencyItems}
                        domainUrl={data?.brandDomain}
                        data={data}
                        llmDomains={llmDomains}
                      />
                      <hr />
                      {data?.products?.map((productData) => {
                        return (
                          <>
                            <SummaryCard
                              frequency={selectedSubFrequencyItems}
                              domainUrl={data?.brandDomain}
                              isProduct={true}
                              data={productData}
                              llmDomains={llmDomains}
                            />
                            <hr />
                          </>
                        );
                      })}
                    </>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default HomePage;
