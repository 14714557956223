import { useEffect, useContext } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { DiscoveryContext } from "../../Discovery/discovery-context";
import Table from "./../../../Component/UI/Table/Table";
import classes from "./ProductSpecs.module.css";
import LoadingSkeleton from "./LoadingSkeleton";
import useProductSpecs from "./useProductSpecs";

export default function ProductSpec() {
  const discoveryCtx = useContext(DiscoveryContext);

  const { getProductSpec, productSpecs, loading } = useProductSpecs();

  let competitors = discoveryCtx.competitors
    .slice(discoveryCtx.type == "category" ? 0 : 1, 4)
    .map((c) => c.name);

  useEffect(() => {
    if (competitors.length == 0) return;
    getProductSpec();
  }, [competitors.length]);

  function renderTable(spec) {
    return (
      <TableRow>
        {Object.values(spec).map((value, index) => {
          return (
            <TableCell style={{ padding: 20, fontSize: 14 }} key={index}>
              {index === 0 ? <b>{value}</b> : value}
            </TableCell>
          );
        })}
      </TableRow>
    );
  }

  return (
    <div>
      <div className={classes.title}>Product Specifications</div>
      {!loading && productSpecs.length > 0 && (
        <div className={classes.table_container}>
          <Table
            elevation={0}
            headers={["Specification", ...competitors].map((product) => {
              return { id: product, name: product, sorting: false };
            })}
            render={renderTable}
            data={productSpecs}
          />
        </div>
      )}
      {loading && <LoadingSkeleton />}
    </div>
  );
}
