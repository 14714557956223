import React, { useState } from "react";
import classes from "./Actions.module.css";

import ActionModal from "./ActionModal";
import { sortRecommendedActionsData } from "../../../../utils/helpers";

export const ActionTable = ({ actionMetricsData, handleChange }) => {
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [actionType, setActionType] = useState();

  const handleClose = () => setOpen(false);

  const handleModalData = (data, type) => {
    setOpen(true);
    setModalData(data);
    setActionType(type);
  };

  const headers = [
    "Brand",
    "Product",
    "LLM",
    "Persona",
    "Geography",
    "Market",
    "Reach",
    "Risk",
    "Performance",
    // "Opportunity",
  ];

  const getActionCount = (countsToSum) => {
    return countsToSum
      .filter((count) => count !== undefined && count !== null)
      .reduce((sum, count) => sum + count, 0);
  };

  return (
    <div className="col-md-12">
      <ActionModal
        type={actionType}
        data={modalData}
        open={open}
        handleClose={handleClose}
        handleChange={handleChange}
      />

      <div className={classes.tableContainer}>
        <table className="table table-striped">
          <thead
            style={{
              position: "sticky",
              // zIndex: 10,
              top: 0,
            }}
          >
            <tr style={{ textAlign: "center" }} className="prdtblHeader">
              {headers.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!!actionMetricsData.length ? (
              sortRecommendedActionsData(actionMetricsData)?.map(
                (data, index) => {
                  const reachCount = getActionCount([
                    data?.reachCount,
                    data?.reachCompCount,
                    // data?.changeActionData?.reachCount,
                  ]);

                  const sentimentCount = getActionCount([
                    data?.sentimentCount,
                    data?.sentimentCompCount,
                    // data?.changeActionData?.sentimentCount,
                  ]);

                  const riskCount = getActionCount([
                    data?.riskCompCount,
                    data?.nonReachRiskCount,
                    // data?.changeActionData?.riskCount,
                  ]);

                  const oppCount = getActionCount([
                    data?.oppCount,
                    // data?.changeActionData?.oppCount,
                  ]);

                  return (
                    <tr style={{ textAlign: "center" }} key={index}>
                      <td>{(!data?.isProduct && data?.brand) || "--"}</td>
                      <td>{(data?.isProduct && data?.brand) || "--"}</td>
                      <td>{data?.llm}</td>
                      <td>{data?.persona}</td>
                      <td>{data?.geography}</td>
                      <td>{data?.market}</td>
                      <td
                        onClick={() => {
                          handleModalData(data, "reach");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {reachCount || "--"}
                      </td>
                      <td
                        onClick={() => {
                          handleModalData(data, "sentiment");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {sentimentCount || "--"}
                      </td>
                      <td
                        onClick={() => {
                          handleModalData(data, "risk");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {riskCount || "--"}
                      </td>
                      {/* <td
                        onClick={() => {
                          handleModalData(data, "opp");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {oppCount || "--"}
                      </td> */}
                    </tr>
                  );
                }
              )
            ) : (
              <div style={{ minHeight: "300px" }}></div>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
