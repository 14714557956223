import { useState, useContext } from "react";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";

import classes from "./Share.module.css";
import MUIIconButton from "../../../Component/UI/MUIIconButton/MUIIconButton";
import { DiscoveryContext } from "../discovery-context";
import { toast } from "react-toastify";

export default function Share() {
  const discoveryCtx = useContext(DiscoveryContext);
  const [isCopied, setIsCopied] = useState(false);

  const docId = discoveryCtx.state.id;
  const documentPath = `${window.location.origin}${window.location.pathname}?id=${docId}`;

  async function handleCopy() {
    await navigator.clipboard.writeText(documentPath);
    setIsCopied(true);
    toast.success("Share link is copied.");
    setTimeout(() => {
      setIsCopied(false);
    }, 4000);
  }

  return (
    <div>
      <div className={classes.label}>{discoveryCtx.entity} Discovery</div>
      <div className={classes.copy_container}>
        <span className={classes.path}>{documentPath}</span>
        <MUIIconButton onClick={handleCopy} tooltip="Click to copy">
          {isCopied ? (
            <DoneOutlinedIcon color="success" />
          ) : (
            <ContentCopyOutlinedIcon />
          )}
        </MUIIconButton>
      </div>
    </div>
  );
}
