import React, { useState } from "react";
import Zoom from "@mui/material/Zoom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import classes from "./MUIToolTip.module.css";
import Sources from "./Sources";

import MUIButton from "../MUIButton/MUIButton";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#353945",
    color: "#FCFCFD",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 500,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#353945",
  },
}));

export default function MUIToolTip({
  sources = [],
  actionText = "",
  children,
  title,
  metric,
  metricScore,
  addToActionHandler,
  attribute,
  selectedInput,
  ...props
}) {
  const llmSources =
    sources && Object.values(sources)?.[0]
      ? Object.keys(Object.values(sources)[0]).slice(0, 5)
      : [];
  const [open, setOpen] = useState(false);

  function handleTooltipOpen() {
    setOpen(true);
  }

  function handleTooltipClose() {
    setOpen(false);
  }

  function ToolTipContent({ actionText }) {
    return (
      <div className={classes.content}>
        <h6>{title}</h6>
        <h6>Training Data Sources</h6>
        {llmSources.length > 0 && (
          <>
            <Sources sources={llmSources} />
          </>
        )}

        <div className={classes.action_btn_wrapper}>
          <MUIButton
            size="small"
            className={classes.action_btn}
            variant="outlined"
            onClick={() => {
              const entity = selectedInput?.brand
                ? `brand ${selectedInput?.brand}`
                : `product ${selectedInput?.product}`;
              const actionStatement = `The ${metric}-${attribute} score for the ${entity} is low at ${metricScore}`;
              addToActionHandler(actionStatement,title);
            }}
          >
            {actionText}
          </MUIButton>
        </div>
      </div>
    );
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div className={classes.tooltip_wrapper}>
        <LightTooltip
          open={open}
          onOpen={handleTooltipOpen}
          onClose={handleTooltipClose}
          slots={{
            transition: Zoom,
          }}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          onClick={() => setOpen(true)}
          title={<ToolTipContent actionText={actionText} />}
          {...props}
        >
          {children}
        </LightTooltip>
      </div>
    </ClickAwayListener>
  );
}
