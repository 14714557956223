import React, { useContext } from "react";
import {
  Home,
  HomeOutlined,
  Explore,
  ExploreOutlined,
  LocalOffer,
  LocalOfferOutlined,
  Work,
  WorkOutline,
  TouchApp,
  TouchAppOutlined,
  Settings,
  SettingsOutlined,
  BuildOutlined,
  Build,
  CategoryOutlined,
  Category,
} from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import styles from "./Sidebar.module.css";
import { AuthContext } from "../../context/auth-context";
import { DiscoveryContext } from "../../luminaire/Discovery/discovery-context";

const navItems = [
  { target: "home", label: "Home", icon: Home, iconOutlined: HomeOutlined },
  {
    target: "discovery",
    label: "Discovery",
    icon: Explore,
    iconOutlined: ExploreOutlined,
  },
  {
    target: "prompt-engine",
    label: "LLM Insights",
    icon: Build,
    iconOutlined: BuildOutlined,
  },
  {
    target: "brand-monitoring",
    label: "Brand Analysis and Monitoring",
    icon: LocalOffer,
    iconOutlined: LocalOfferOutlined,
  },
  {
    target: "product-monitoring",
    label: "Product Analysis and Monitoring",
    icon: Work,
    iconOutlined: WorkOutline,
  },
  // {
  //   target: "category-discovery",
  //   label: "Category Discovery",
  //   icon: Category,
  //   iconOutlined: CategoryOutlined,
  // },
  {
    target: "actions",
    label: "Actions",
    icon: TouchApp,
    iconOutlined: TouchAppOutlined,
  },
  // The "Settings" NavLink will be added conditionally
];

const Sidebar = () => {
  const { user } = useContext(AuthContext);
  const discoveryCtx = useContext(DiscoveryContext);

  const isAdmin =
    user?.user_role?.role?.name == "Company admin" ||
    user?.user_role?.role?.name == "Super User";

  return (
    <ul className="nav nav-pills p-3 flex-column mb-auto">
      {navItems.map(
        ({ target, label, icon: Icon, iconOutlined: IconOutlined }) => (
          <li className="nav-item" key={target}>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `${styles.navLink} ${styles.navLinkActive}`
                  : styles.navLink
              }
              onClick={() => {
                if (target === "discovery") {
                  discoveryCtx.updateType("brand");
                }
                discoveryCtx.resetState();
              }}
              to={target}
            >
              {({ isActive }) => (
                <React.Fragment>
                  <span
                    className={`${styles.iconWrap} ${
                      isActive ? styles.iconWrapActive : ""
                    }`}
                  >
                    {isActive ? (
                      <Icon className={`${styles.activeIcon}`} />
                    ) : (
                      <IconOutlined className={`${styles.icon}`} />
                    )}
                  </span>
                  <span className={`${styles.labelText}`}>{label}</span>
                </React.Fragment>
              )}
            </NavLink>
          </li>
        )
      )}

      {/* Conditionally render the "Settings" NavLink for admin users */}
      {isAdmin && (
        <li className="nav-item" key="settings">
          <NavLink
            className={({ isActive }) =>
              isActive
                ? `${styles.navLink} ${styles.navLinkActive}`
                : styles.navLink
            }
            to="settings"
          >
            {({ isActive }) => (
              <React.Fragment>
                <span
                  className={`${styles.iconWrap} ${
                    isActive ? styles.iconWrapActive : ""
                  }`}
                >
                  {isActive ? (
                    <Settings className={`${styles.activeIcon}`} />
                  ) : (
                    <SettingsOutlined className={`${styles.icon}`} />
                  )}
                </span>
                <span className={`${styles.labelText}`}>Settings</span>
              </React.Fragment>
            )}
          </NavLink>
        </li>
      )}
    </ul>
  );
};

export default Sidebar;
