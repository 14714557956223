import { useContext, useEffect } from "react";

import { DiscoveryContext } from "./discovery-context";
import { fetchGpt4API } from "../../services/apiService";
import { extractJSONFromString } from "../../utils/helpers";

const PRODUCT_PREF_PROMPT = `
You are an expert in understanding user preferences and buying behavior. Based on the given product category, generate a list of up to 8 attributes that users care about the most before purchasing products in that category. All attributes should be in title case. Return the data in JSON format without any additional information or explanations.
Ensure the output adheres to the following JSON structure:

{
   "data": ["attribute1", "attribute2", "attribute3", ..., "attribute8"]
}
Input Product Category: [Category]

Output: JSON only.
`;

export default function useProductPreference() {
  const discoveryCtx = useContext(DiscoveryContext);

  useEffect(() => {
    if (discoveryCtx.abort_launch) {
      discoveryCtx.updateProductPrefs([]);
    }
  }, [discoveryCtx.abort_launch]);

  async function getProductPrefs() {
    const prompt = PRODUCT_PREF_PROMPT.replace(
      "[Category]",
      discoveryCtx.category
    );
    let response = await fetchGpt4API(
      prompt,
      new AbortController(),
      1000,
      1,
      "gpt-4o"
    );
    response = await extractJSONFromString(response["GPT-4"][0]);
    if (response && response?.data) {
      const fetchedPrefs = response?.data;
      discoveryCtx.updateProductPrefs(fetchedPrefs);
    }
  }

  return { getProductPrefs };
}
