import { useContext, useEffect, useRef } from "react";

import { DiscoveryContext } from "./discovery-context";
import api from "../../BrandOnBoarding/api";

export default function useGeneratePreCategoryDimensions() {
  const discoveryCtx = useContext(DiscoveryContext);
  const abortControllerRef = useRef();

  useEffect(() => {
    if (discoveryCtx.abort_launch) {
      discoveryCtx.updatePreCategoryDimensions([]);
    }
  }, [discoveryCtx.abort_launch]);

  async function generatePreCategoryDimensions() {
    abortControllerRef.current = new AbortController();
    try {
      const response = await api.post(
        "/metrics/generate-category-dimensions?return_sources=false",
        {
          llm: "gpt-4o-mini",
          category: discoveryCtx.category,
        },
        {
          signal: abortControllerRef.current.signal,
        }
      );
      discoveryCtx.updatePreCategoryDimensions(response.data);
    } catch (error) {
      console.error("Error getting taxonomies:", error);
    }
  }

  return {
    preDimensions: discoveryCtx.pre_category_dimensions,
    generatePreCategoryDimensions,
  };
}
