import React, { useState, useEffect, useContext } from "react";
import ActionTypeTab from "./ActionTypeTab";
import RecommendedActions from "../Dashboard/Component/ActionComponent/Actions";
import ActionList from "../Dashboard/Component/ActionComponent/ActionList";
import { AuthContext } from "../../context/auth-context";
import {
  getActionMetricsData,
  getRecommendedActions,
} from "../../services/apiService";
import { mergeChangeActions } from "../../utils/helpers";
import useQuery from "../../Component/devDashboard2/common/useQuery";

export default function Actions() {
  const { user } = useContext(AuthContext);
  const customerId = user?.customer_id || 0;
  const query = useQuery();
  const actionTab = query.get("actions_list")
    ? "Actions List"
    : "Recommended Actions";
  const [actionType, setActionType] = useState(actionTab);
  const [isLoading, setIsLoading] = useState(false);
  const [actionMetricsData, setActionMetricsData] = useState([]);

  const handleActionTypeChange = (key) => {
    setActionType(key);
  };

  // useEffect(() => {
  //   fetchActionMetricsData();
  // }, []);

  const fetchActionMetricsData = async () => {
    try {
      setIsLoading(true);
      let data = await getRecommendedActions(customerId);
      if (data && data.length > 0) {
        const { current_data_actions_json, change_data_actions_json } =
          data?.[0];

        data = mergeChangeActions(
          current_data_actions_json,
          change_data_actions_json
        );
        setActionMetricsData(data);
        return;
      } else {
        const payload = { customerId };

        const { customerChangeDataActions, finishedActionsData } =
          await getActionMetricsData(payload);
        data = mergeChangeActions(
          finishedActionsData,
          customerChangeDataActions
        );
        setActionMetricsData(data);
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="m-3">

      <h5> Coming soon...  </h5>
      {/* <ActionTypeTab
        activeTarget={actionType}
        handleChange={handleActionTypeChange}
      />
      {actionType === "Recommended Actions" && (
        <RecommendedActions
          isLoading={isLoading}
          actionMetricsData={actionMetricsData}
          handleChange={handleActionTypeChange}
        />
      )}
      {actionType === "Actions List" && (
        <>
          <br /> <ActionList />
        </>
      )} */}
    </div>
  );
}
