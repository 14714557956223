import { useContext } from "react";

import MUIChip from "../../../Component/UI/MUIChip/MUIChip";
import classes from "./Suggestions.module.css";
import { DiscoveryContext } from "../../Discovery/discovery-context";

export default function Suggestions({ options, onDelete }) {
  const discoveryCtx = useContext(DiscoveryContext);
  
  return (
    <div className={classes.container}>
      <span>Products Added: </span>
      {options.map((item) => (
        <MUIChip
          disabled={discoveryCtx.loading || discoveryCtx.is_launched}
          key={item.name}
          label={item.name}
          className={classes.chip}
          onDelete={() => onDelete(item.name)}
        />
      ))}
    </div>
  );
}
