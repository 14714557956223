import React, { useEffect, useState } from "react";

const AnimatedNumber = ({ start, end, duration, ...props }) => {
  const [count, setCount] = useState(start);

  if(end === null || isNaN(end)) {
    start = -1;
    end = 0;
  }

  useEffect(() => {
    let current = start;
    const range = end - start;
    const increment = end > start ? 1 : -1;
    const step = Math.abs(Math.floor(duration / range));

    const timer = setInterval(() => {
      current += increment;
      setCount(current);
      if (current === end) {
        clearInterval(timer);
      }
    }, step);

    return () => clearInterval(timer);
  }, [start, end, duration]);

  return <span {...props}>{count}</span>;
};

export default AnimatedNumber;
