import { useState, useContext, useRef, useEffect } from "react";

import api from "../../../BrandOnBoarding/api";
import { DiscoveryContext } from "../../Discovery/discovery-context";

export default function useProductRecommendations() {
  const [productRecommendations, setProductRecommendations] = useState("");
  const [loading, setLoading] = useState(false);

  const abortControllerRef = useRef();
  const discoveryCtx = useContext(DiscoveryContext);

  let competitors = discoveryCtx.competitors
    .slice(discoveryCtx.type == "category" ? 0 : 1, 4)
    .map((c) => c.name);

  useEffect(() => {
    if (discoveryCtx.abort_launch) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    }
  }, [discoveryCtx.abort_launch]);

  async function getRecommendedProducts() {
    abortControllerRef.current = new AbortController();
    try {
      setLoading(true);
      const response = await api.post(
        "/metrics/product-recommendations",
        {
          llm: discoveryCtx.llm,
          products: competitors,
          category: discoveryCtx.category,
          country: discoveryCtx.location,
          occupation: discoveryCtx.occupation,
          attributes: discoveryCtx.attributes.toString(),
        },
        {
          signal: abortControllerRef.current.signal,
        }
      );
      const data = response.data;
      setProductRecommendations(data.response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  return { productRecommendations, loading, getRecommendedProducts };
}
