import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";

import classes from "../Brand/Brand.module.css";
import Table, {
  StyledTableCell,
  StyledTableRow,
} from "../../../Component/UI/Table/Table";
import MUIIconButton from "../../../Component/UI/MUIIconButton/MUIIconButton";
import Spinner from "../../../Component/Spinner/Spinner";
import { fetchcoreAttributes } from "../../../redux/actions/coreAttributeActions";
import { AuthContext } from "../../../context/auth-context";

const TABLE_HEADERS = [
  { id: "brand_pillars", name: "Brand Pillars", sorting: true },
  { id: "details", name: "", sorting: false },
  { id: "actions", name: "Actions", sorting: false },
];

const BrandPillars = ({ handleBrandPillar }) => {
  const dispatch = useDispatch();
  const brandPillars = useSelector((state) => state.coreAttributes);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (brandPillars?.data && brandPillars?.data.length === 0) {
      dispatch(fetchcoreAttributes(user.customer_id));
    }
  }, [brandPillars?.data, dispatch]);
  const brandPillarsData = brandPillars.data.data;

  const handleDeleteBrandPillar = async (payload) => {
    const proceed = window.confirm("Do you really want to proceed?");
    proceed && handleBrandPillar("delete", payload);
  };

  const renderRow = (item) => (
    <StyledTableRow key={item.id}>
      <StyledTableCell component="th" scope="row" style={{ width: 450 }}>
        <div className={classes.brand_product}>
          <span>{item.name}</span>
        </div>
      </StyledTableCell>
      <StyledTableCell align="left" style={{ width: 450 }}>
        {/* {item.description} */}
      </StyledTableCell>

      <StyledTableCell align="left">
        <div>
          <MUIIconButton
            style={{ color: item?.is_default ? "#c4e0ff" : "#007aff" }}
            className={classes.action_btn}
            tooltip="Update"
            onClick={() => handleBrandPillar("open_update_modal", item)}
          >
            <EditIcon />
          </MUIIconButton>
          <MUIIconButton
            style={{ color: item?.is_default ? "#fed1cb" : "#da1a04" }}
            tooltip="Delete"
            disabled={item?.is_default}
            className={classes.action_btn}
            onClick={() => handleDeleteBrandPillar(item)}
          >
            <DeleteOutlineIcon />
          </MUIIconButton>
        </div>
      </StyledTableCell>
    </StyledTableRow>
  );

  return (
    <>
      {brandPillars.loading && <Spinner />}
      {!brandPillars.loading &&
        brandPillarsData &&
        brandPillarsData?.length > 0 && (
          <Table
            headers={TABLE_HEADERS}
            data={brandPillarsData}
            render={renderRow}
          />
        )}
    </>
  );
};

export default BrandPillars;
