import { useState, useContext } from "react";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { DiscoveryContext } from "../discovery-context";
import MUICard from "./../../../Component/UI/MUICard/MUICard";
import MUIIconButton from "./../../../Component/UI/MUIIconButton/MUIIconButton";
import classes from "./CompetitorItem.module.css";
import Modal from "../../../Component/Modal/Modal";
import BrandIndexBreakups from "../../../Component/BrandIndexHistory/BrandIndexBreakups";
import ErrorView from "../../../Component/ErrorView/ErrorView";
import AnimatedNumber from "./../../../Component/AnimatedNumber/AnimatedNumber";
import ProductItem from "./../../CategoryDiscovery/Products/ProductItem";

function extractDomain(input) {
  // Check if the input is already in the desired format
  if (/^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(input)) {
    return input;
  }

  try {
    // If it's not a full URL, prepend 'http://' to make it valid for URL parsing
    if (!/^https?:\/\//i.test(input)) {
      input = "http://" + input;
    }

    // Create a URL object
    const urlObject = new URL(input);

    // Get the hostname (e.g., "in.puma.com")
    let hostname = urlObject.hostname;

    // Split the hostname by dots
    const parts = hostname.split(".");

    // If we have more than 2 parts, take the last two
    if (parts.length > 2) {
      hostname = parts.slice(-2).join(".");
    }

    return hostname;
  } catch (error) {
    console.error("Invalid input:", error.message);
    return null;
  }
}

export default function CompetitorItem({
  competitor,
  brandIndex,
  brand,
  index,
  onRetry = () => {},
}) {
  const [showBrandIndexBreakups, setShowBrandIndexBreakups] = useState(false);

  const discoveryCtx = useContext(DiscoveryContext);
  const discoveryType = discoveryCtx.type;

  const ctxCompetitor = discoveryCtx.competitors
    .filter((comp) => comp.name != brand)
    .find((comp) => comp.name === competitor);

  let domain = "";
  if (ctxCompetitor && ctxCompetitor?.domain) {
    domain = extractDomain(ctxCompetitor?.domain);
  }

  return (
    <div className={classes.container}>
      <Modal
        width={1200}
        open={showBrandIndexBreakups}
        handleClose={() => setShowBrandIndexBreakups(false)}
      >
        <BrandIndexBreakups data={brandIndex?.extras} />
      </Modal>
      <MUICard
        variant="elevated"
        elevation={0}
        borderRadius={3}
        className={classes.card}
        width={"90%"}
      >
        {!competitor && (
          <div className={classes.add_container}>
            <MUIIconButton size="large">
              <AddCircleOutlineOutlinedIcon
                style={{ fontSize: 40 }}
                fontSize="large"
              />
            </MUIIconButton>
          </div>
        )}
        {competitor && !brandIndex?.error && (
          <>
            {brandIndex?.extras && (
              <MUIIconButton
                data-html2canvas-ignore
                onClick={() => setShowBrandIndexBreakups(true)}
                className={classes.settings}
              >
                <InfoOutlinedIcon />
              </MUIIconButton>
            )}
            <div className={classes.header}>
              <img
                data-html2canvas-ignore
                className={classes.logo}
                src={`https://img.logo.dev/${domain}?token=pk_SaDf4t2eRsW5Fc84zgJRZA`}
              />
              <span className={classes.title}>{competitor}</span>
            </div>
            <div className={classes.content}>
              <AnimatedNumber
                className={classes.score}
                start={10}
                end={brandIndex?.total}
                duration={1000}
              />
              <div className={classes.sub_content}>
                <span className={classes.sub_score}>{brandIndex?.rank}</span>
                <hr className={classes.divider} />
                <span className={classes.sub_score}>
                  {brandIndex?.favorability}
                </span>
              </div>
            </div>
          </>
        )}
        {competitor && brandIndex?.error && (
          <ErrorView
            title="An error occured"
            message="Something went wrong"
            retry
            onRetry={() => onRetry(index)}
          />
        )}
      </MUICard>
    </div>
  );
}
