import React, { useEffect, useState } from "react";
import {
  TextField,
  Typography,
  Button,
  Chip,
  InputLabel,
  Box,
  LinearProgress,
} from "@mui/material";
import RadioButtonsGroup from "../../../Component/UI/RadioGroup/RadioGroup";
import TextInput from "../../../Component/UI/TextInput/TextInput";
import { useSelector } from "react-redux";
import { getPromptForBrandCompetitorsData } from "../../../utils/helpers";
import { fetchGpt4API } from "../../../services/apiService";
import json5 from "json5";
import InputSelectionComponent from "../../Dashboard/Component/inputSelection";
import debounce from "lodash.debounce";

const radioOption = [
  {
    value: "manual",
    label: "Enter Competitors Manually",
  },
  {
    value: "ai",
    label: "Add Competitors from AI Suggestion",
  },
];
const BrandForm = ({
  brandName,
  setBrandName,
  details,
  setDetails,
  brandCategoryName,
  setBrandCategoryName,
  brandCategoryDescription,
  setBrandCategoryDescription,
  competitorOption,
  setCompetitorOption,
  competitors,
  setCompetitors,
  availableCompetitors,
  handleCompetitorToggle,
  handleAddAll,
  brandPersona,
  setBrandPersona,
  classes,
  handleCloseModal,
  coreAttributes,
  createNewBrand,
  isUpdateModalOpen,
  updateCreatedBrand,
}) => {
  const personas = useSelector((state) => state.personas);
  const brands = useSelector((state) => state.brands);
  const [competersAI, setCompetersAI] = useState("manual");
  const personasData = personas.data.data;
  const [competitorsFromAi, setCompetitorsFromAi] = useState([]);
  const [isLoading, setIsLoading] = useState({
    compititor: false,
    purchaseCriteria: false,
  });
  const [brandPillarError, setBrandPillarError] = useState(false);
  const [prevBrandName, setPrevBrandName] = useState("");
  const [brandPersonaLabel, setBrandPersonaLabel] = useState([]);
  const [selectedCoreAttributes, setSelectedCoreAttributes] = useState([]);
  const [selectedCoreAttributesLabels, setSelectedCoreAttributesLabels] =
    useState([]);
  const [purchaseCriteria, setPurchaseCriteria] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedText, setEditedText] = useState("");

  useEffect(() => {
    console.log(brandName, "brandName", competersAI);
    selectedCoreAttributes.length === 0 &&
      handleSelectedCoreAttributes(
        coreAttributes.data.data.filter((item) => item.is_default === 1)
      );
  }, []);

  // Define the fetch function
  const fetchLLMPurchaseCriteria = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, purchaseCriteria: true }));
      let res = await fetch(
        "https://llmapi.buzzybrains.net/api/metrics/generate-category-dimensions",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            llm: "gpt-4o",
            brand: brandName,
            category: brandCategoryName,
          }),
        }
      );
      res = await res.json();
      res = res.map((item) => item.name);
      setPurchaseCriteria([...res]);
    } catch (error) {
      console.error("Error fetching purchase criteria:", error);
    } finally {
      setIsLoading((prev) => ({
        ...prev,
        purchaseCriteria: false,
      }));
    }
  };

  // Create a debounced version of the fetch function
  const debouncedFetchLLMPurchaseCriteria = React.useCallback(
    debounce(() => {
      fetchLLMPurchaseCriteria();
    }, 4000),
    [brandName, brandCategoryName]
  );

  // Trigger the debounce function when brandName or brandCategoryName changes
  useEffect(() => {
    if (brandName && brandCategoryName) {
      debouncedFetchLLMPurchaseCriteria();
    }
    // Cleanup debounce on unmount
    return () => debouncedFetchLLMPurchaseCriteria.cancel();
  }, [brandName, brandCategoryName, debouncedFetchLLMPurchaseCriteria]);

  async function getCompetitorsData(type) {
    if (type === "ai" && brandName && prevBrandName !== brandName) {
      console.log("called in if");
      let prompt = getPromptForBrandCompetitorsData(brandName);
      setIsLoading((prev) => ({ ...prev, compititor: true }));
      let result = await fetchGpt4API(
        prompt,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        "Brand-onboarding"
      );
      result = result["GPT-4"][0];

      // Match the array of objects using the regular expression
      let matchedArray = result.match(
        /\[\s*\{[^\}]*\}\s*(,\s*\{[^\}]*\}\s*)*\]/g
      );

      if (matchedArray) {
        // Parse the matched array into a JavaScript object
        let resultArray = json5.parse(matchedArray[0]);
        // console.log(resultArray);
        setIsLoading((prev) => ({ ...prev, compititor: false }));
        setCompetitorsFromAi(resultArray);
        setPrevBrandName(brandName);
      }
    }
  }

  const handleDoubleClick = (index, value) => {
    setEditingIndex(index);
    setEditedText(value);
  };

  const handleInputChange = (event) => {
    setEditedText(event.target.value);
  };

  const handleBlur = () => {
    if (editingIndex !== null) {
      const updatedCriteria = [...purchaseCriteria];
      updatedCriteria[editingIndex] = editedText.trim();
      setPurchaseCriteria(updatedCriteria);
      setEditingIndex(null);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleBlur();
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log({
      form: "form ",
      competitors,
      brandName,
      brandPersona,
      details,
      brandCategoryName,
      brandCategoryDescription,
      coreAttributes: selectedCoreAttributes,
      isUpdateModalOpen,
    });
    if (isUpdateModalOpen) {
      updateCreatedBrand(
        competitors,
        brandName,
        brandPersona,
        details,
        brandCategoryName,
        brandCategoryDescription,
        selectedCoreAttributes,
        purchaseCriteria
      );
    } else {
      createNewBrand(
        competitors,
        brandName,
        brandPersona,
        details,
        brandCategoryName,
        brandCategoryDescription,
        selectedCoreAttributes,
        purchaseCriteria
      );
    }
  };

  const handlePersonaCheckChange = (selected) => {
    const selectedValues = selected?.map((option) => option?.persona?.name);
    setBrandPersonaLabel(selectedValues || []);
    setBrandPersona(selected || []);
  };

  const handleSelectedCoreAttributes = (selected) => {
    if (selected.length > 8) {
      console.table(selected);
      alert("You can select maximum 8 brand pillars");
      return;
    }
    if (selected.length === 8) {
      setBrandPillarError(false);
    } else {
      setBrandPillarError(true);
    }
    setSelectedCoreAttributes(selected || []);
    let selectedValues = selected?.map((option) => option?.name);
    setSelectedCoreAttributesLabels(selectedValues || []);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      {console.log(coreAttributes, "coreAttributes", personas)}
      <div className={classes.modalBody}>
        <div className={classes.formGrid}>
          <TextInput
            label="Brand Name"
            variant="outlined"
            fullWidth
            required
            value={brandName}
            onChange={(e) => {
              setCompetersAI("manual");
              setCompetitors([]);
              setBrandName(e.target.value);
            }}
            className={classes.textField}
          />

          <TextInput
            label="Details for Disambiguation"
            variant="outlined"
            fullWidth
            value={details}
            required
            onChange={(e) => setDetails(e.target.value)}
            className={classes.textField}
          />

          <TextInput
            label="Brand Category Name"
            variant="outlined"
            fullWidth
            required
            value={brandCategoryName}
            onChange={(e) => setBrandCategoryName(e.target.value)}
            className={classes.textField}
          />
          <TextInput
            label="Brand Category Description"
            variant="outlined"
            fullWidth
            required
            value={brandCategoryDescription}
            onChange={(e) => setBrandCategoryDescription(e.target.value)}
            className={classes.textField}
          />
        </div>

        <Typography variant="subtitle1">Brand Competitor</Typography>
        <RadioButtonsGroup
          options={radioOption}
          selectedvalue={competersAI}
          onChange={(v) => {
            setCompetersAI(v);
            getCompetitorsData(v);
            setCompetitors([]);
          }}
        ></RadioButtonsGroup>
        <div>
          <TextInput
            label="Competitors Brand Description"
            variant="outlined"
            fullWidth
            margin="normal"
            required
            value={competitors?.join(", ")}
            onChange={(e) => setCompetitors(e.target.value.split(", "))}
            // className={classes.select}
          />
        </div>
        {isLoading.compititor && <LinearProgress />}
        <div
          style={{
            marginTop: "10px",
          }}
        >
          {console.log(competitorsFromAi, "competitorsFromAi", competitors)}
          {/* <MUIButton variant="contained">Add all</MUIButton> */}
          {competersAI === "ai" &&
            competitorsFromAi?.map((competitor, index) => (
              <Chip
                style={{ margin: "5px", borderRadius: "3px" }}
                key={competitor}
                label={competitor.comp_brand_name}
                onClick={() =>
                  handleCompetitorToggle(competitor.comp_brand_name)
                }
                color={
                  competitors?.includes(competitor.comp_brand_name)
                    ? "primary"
                    : "default"
                }
              />
            ))}
        </div>

        <Box mt={2}>
          <InputLabel shrink htmlFor="input">
            Purchase Criteria
          </InputLabel>
          {isLoading.purchaseCriteria && <LinearProgress className="mb-2" />}
          {purchaseCriteria.length === 0 && (
            <Typography color="error" variant="caption" mt={1}>
              Please enter brand name and brand category.
            </Typography>
          )}
          <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
            {purchaseCriteria.map((tag, index) => (
              <Box key={index}>
                {editingIndex === index ? (
                  <TextField
                    value={editedText}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onKeyPress={handleKeyPress}
                    size="small"
                    variant="outlined"
                    autoFocus
                  />
                ) : (
                  <Chip
                    label={tag}
                    color="primary"
                    variant="outlined"
                    title="Double click to edit"
                    onDoubleClick={() => handleDoubleClick(index, tag)}
                  />
                )}
              </Box>
            ))}
          </Box>
        </Box>

        {coreAttributes?.data && (
          <Box mt={2}>
            <InputLabel shrink htmlFor="input">
              Brand Pillars
            </InputLabel>
            <InputSelectionComponent
              selectedOptions={selectedCoreAttributesLabels}
              handleChange={handleSelectedCoreAttributes}
              options={coreAttributes.data.data.map((val) => {
                return { ...val, type: "coreAttributes" };
              })}
              type="coreAttributes"
              required
            />
            {brandPillarError && (
              <Typography color="error" variant="caption" mt={1}>
                Please select exactly 8 Brand Pillars.
              </Typography>
            )}
          </Box>
        )}

        <Box mt={2}>
          <InputLabel shrink htmlFor="input">
            Persona
          </InputLabel>
          <InputSelectionComponent
            selectedOptions={brandPersonaLabel}
            handleChange={handlePersonaCheckChange}
            options={personasData}
            type="persona"
          />
          {brandPersonaLabel.length === 0 && (
            <Typography color="error" variant="caption" mt={1}>
              Please select a persona.
            </Typography>
          )}
        </Box>

        <div className={classes.buttonDiv}>
          <Button
            // onClick={handleConfirm}
            // color={confirmBtnColor}
            type="submit"
            variant="contained"
            disabled={
              brands?.loading ||
              !purchaseCriteria.length ||
              brandPillarError ||
              !brandPersonaLabel.length
            }
          >
            <div>{isUpdateModalOpen ? "Update" : "Add"}</div>
          </Button>
          <Button variant="outlined" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};

export default BrandForm;
