import { Skeleton } from "@mui/material";

import classes from "./Review.module.css";

export default function LoadingSkeleton() {
  return (
    <div>
      {Array(4)
        .fill(0)
        .map((_, index) => {
          return (
            <div className={classes.container} key={index}>
              {Array(3)
                .fill(0)
                .map((_, index) => {
                  return <Skeleton key={index} variant="text" height={25} />;
                })}
            </div>
          );
        })}
    </div>
  );
}
