import React, { useState, useEffect, useContext } from "react";
import BrandScore from "./BrandScore/BrandScore";
import {
  coreAttributesDataTransform,
  getSummaryPrompt,
} from "../../../utils/helpers";
import CoreAttributes from "./CoreAttributes/CoreAttributes";
import classes from "./DashboardComponent.module.css";
import AIGenerated from "../../../Component/AIGenerated/AIGenerated";
import CategoryDimensions from "./CategoryDimensions/CategoryDims";
import PositiveAttributes from "./PositiveAttribute/PositiveAttribute";
import NegativeAttributes from "./NegativeAttribute/NegativeAttribute";
import Competitors from "./Competitors/Competitors";
import Source from "./Source/Source";
import { fetchGeminiAPI } from "../../../services/apiService";
import Spinner from "../../../Component/Spinner/Spinner";
import { AuthContext } from "../../../context/auth-context";
import Taxonomy from "./Taxonomy/Taxonomy";

const BrandProductOverview = ({
  indexScoreMetaData,
  compIndexScoreMetaData,
  indexScoreDataForLineChart,
  domain,
  brandProductCategory,
  brandProduct,
  type,
  compCoreAttributes,
  brandDomains,
  compCategoryDimensions,
  positiveAttGraphData,
  pastPositiveAttGraphData,
  negativeAttGraphData,
  pastNegativeAttGraphData,
  compBrandIndexScore,
  pastCompBrandIndexScore,
  pastCompCoreAttributes,
  pastCompCategoryDimensions,
  categoryDimensionRatingSource,
  coreAttSourceData,
  reachSourceData,
  sentimentSourceData,
  brandProductInfo,
  frequency,
  sourceFullUrls,
  productTaxonomyData,
  selectedInput,
}) => {
  const { user } = useContext(AuthContext);
  const { license_status } = user?.customer;
  const customerId = user?.customer_id || 0;

  const [summaries, setSummaries] = useState({
    positiveAttributes: null,
    negativeAttributes: null,
    indexScore: null,
    favorability: null,
    rank: null,
    brandPillars: null,
    purchaseCriteria: null,
    productDimensions: null,
  });

  const [loading, setLoading] = useState({
    positiveAttributes: null,
    negativeAttributes: null,
    indexScore: null,
    favorability: null,
    rank: null,
    brandPillars: null,
    purchaseCriteria: null,
    productDimensions: null,
  });

  const [errors, setErrors] = useState({
    positiveAttributes: null,
    negativeAttributes: null,
    indexScore: null,
    favorability: null,
    rank: null,
    brandPillars: null,
    purchaseCriteria: null,
    productDimensions: null,
  });

  const [abortControllers, setAbortControllers] = useState([]);

  // Helper to create a new AbortController
  const createAbortController = () => {
    const abortController = new AbortController();
    setAbortControllers((prevControllers) => [
      ...prevControllers,
      abortController,
    ]);
    return abortController;
  };

  const resetAbortControllers = () => {
    abortControllers.forEach((controller) => controller.abort());
    setAbortControllers([]);
  };

  let coreAttributesData =
    (compCoreAttributes &&
      coreAttributesDataTransform(compCoreAttributes, brandProduct)) ||
    [];

  let purchaseDimData =
    compCategoryDimensions &&
    coreAttributesDataTransform(compCategoryDimensions, brandProduct);
  let pastcoreAttributesData = coreAttributesDataTransform(
    pastCompCoreAttributes
  );
  let pastPurchaseDimData = coreAttributesDataTransform(
    pastCompCategoryDimensions
  );

  const filterDataByBrand = (data, brand) => {
    const filteredData = {};

    for (let property in data) {
      if (data[property].hasOwnProperty(brand)) {
        // Create a new object with brand and its score for each property
        filteredData[property] = { [brand]: data[property][brand] };
      }
    }

    return filteredData;
  };

  const getAttributesSummary = (type) => {
    // console.log('type==>', type)
    // Fetch data for each prop simultaneously
    if (
      type == "positive-attributes" &&
      positiveAttGraphData &&
      positiveAttGraphData.length
    ) {
      fetchAttributeData(
        positiveAttGraphData,
        pastPositiveAttGraphData,
        currentFrequencyMapping,
        priorFrequencyMapping,
        brandProduct,
        "positive",
        frequency,
        fetchSummaryData,
        "positiveAttributes"
      );
    }

    if (
      type == "negative-attributes" &&
      negativeAttGraphData &&
      negativeAttGraphData.length
    ) {
      fetchAttributeData(
        negativeAttGraphData,
        pastNegativeAttGraphData,
        currentFrequencyMapping,
        priorFrequencyMapping,
        brandProduct,
        "negative",
        frequency,
        fetchSummaryData,
        "negativeAttributes"
      );
    }

    if (
      type == "brand-pillars" &&
      coreAttributesData &&
      coreAttributesData.length
    ) {
      fetchMetricSummary(
        coreAttributesData,
        pastcoreAttributesData,
        currentFrequencyMapping,
        priorFrequencyMapping,
        brandProduct,
        "brand pillars",
        frequency,
        fetchSummaryData,
        "brandPillars"
      );
    }

    if (
      type == "product-dimensions" &&
      productTaxonomyData &&
      productTaxonomyData.length
    ) {
      fetchMetricSummary(
        productTaxonomyData,
        undefined,
        currentFrequencyMapping,
        priorFrequencyMapping,
        brandProduct,
        "product taxonomy",
        frequency,
        fetchSummaryData,
        "productDimensions"
      );
    }

    if (
      type == "purchase-criteria" &&
      purchaseDimData &&
      purchaseDimData.length
    ) {
      fetchMetricSummary(
        purchaseDimData,
        pastPurchaseDimData,
        currentFrequencyMapping,
        priorFrequencyMapping,
        brandProduct,
        "purchase criterias",
        frequency,
        fetchSummaryData,
        "purchaseCriteria"
      );
    }
  };

  const fetchMetricSummary = (
    currentMetricData,
    pastMetricData,
    currentFrequencyMapping,
    priorFrequencyMapping,
    brandProduct,
    type,
    frequency,
    fetchSummaryData,
    attributeType
  ) => {
    const currentData = currentMetricData;

    const priorData = pastMetricData;

    const finalData = {
      [`${
        currentFrequencyMapping[frequency || ""]
      } ${type} of ${brandProduct}`]: currentData,
      // [`${priorFrequencyMapping[frequency || ""]} ${type} of ${brandProduct}`]:
      //   priorData,
    };

    if (priorData) {
      finalData[
        `${priorFrequencyMapping[frequency || ""]} ${type} of ${brandProduct}`
      ] = priorData;
    }
    fetchSummaryData(finalData, attributeType);
  };

  // Function to handle fetching summary data for both positive and negative attributes
  const fetchAttributeData = (
    currentGraphData,
    pastGraphData,
    currentFrequencyMapping,
    priorFrequencyMapping,
    brandProduct,
    type,
    frequency,
    fetchSummaryData,
    attributeType
  ) => {
    const currentData = transformGraphData(currentGraphData);

    const priorData = transformGraphData(pastGraphData);

    const finalData = {
      [`${
        currentFrequencyMapping[frequency || ""]
      } ${type} attributes of ${brandProduct}`]: currentData,
      [`${
        priorFrequencyMapping[frequency || ""]
      } ${type} attributes of ${brandProduct}`]: priorData,
    };
    fetchSummaryData(finalData, attributeType);
  };

  const transformGraphData = (graphData) => {
    return (
      graphData?.map((obj) => ({
        "attribute-category": obj?.name,
        "attribute-category-signal-strength": obj?.value,
        attributes: obj?.altNames,
        totalSignlStrengths: obj?.signalStrengths,
      })) || []
    );
  };

  const brandIndexScore = filterDataByBrand(compBrandIndexScore, brandProduct);
  const pastBrandIndexScore = filterDataByBrand(
    pastCompBrandIndexScore,
    brandProduct
  );

  const currentFrequencyMapping = {
    Current: "Current",
    "3Days": "current 3 days average",
    "7Days": "current 7 days average",
    Month: "current 30 days average",
    "3Month": "current 90 days average",
  };

  const priorFrequencyMapping = {
    Current: "Prior",
    "3Days": "prior 3 days average",
    "7Days": "prior 7 days average",
    Month: "prior 30 days average",
    "3Month": "prior 90 days average",
  };

  const getBrandIndexPromptData = (property, label) => {
    return {
      [`${currentFrequencyMapping?.[frequency] || ""} ${property}`]: {
        ...brandIndexScore?.[label],
        category: brandProductCategory,
      },
      [`${priorFrequencyMapping?.[frequency] || ""} ${property}`]: {
        ...pastBrandIndexScore?.[label],
        category: brandProductCategory,
      },
    };
  };

  const getBrandIndexSummary = (data, mainDataKey, promptKey, summaryKey) => {
    if (data?.[mainDataKey]) {
      const data = getBrandIndexPromptData(promptKey, mainDataKey);
      fetchSummaryData(data, summaryKey);
    }
  };

  const fetchSummaryData = async (dataProp, propKey) => {
    setLoading((prev) => ({ ...prev, [propKey]: true }));
    setErrors((prev) => ({ ...prev, [propKey]: null }));

    const compReachScoreData = compBrandIndexScore?.Rank;

    try {
      const controller = createAbortController();
      const prompt = getSummaryPrompt(
        dataProp,
        propKey,
        compReachScoreData,
        brandProduct
      );
      let result = await fetchGeminiAPI(
        prompt,
        controller,
        undefined,
        undefined
        // "gpt-4o"
      );
      result = result["Gemini"][0];
      setSummaries((prev) => ({ ...prev, [propKey]: result }));
    } catch (err) {
      setErrors((prev) => ({
        ...prev,
        [propKey]: "Failed to generate summary",
      }));
    } finally {
      setLoading((prev) => ({ ...prev, [propKey]: false }));
    }
  };

  useEffect(() => {
    resetAbortControllers();
    getBrandIndexSummary(
      brandIndexScore,
      "Brand Index Score",
      "Brand Index Score",
      "indexScore"
    );

    if (license_status == "Paid") {
      const metric = type == "Product" ? "product-dimensions" : "brand-pillars";
      const metrics = [
        "positive-attributes",
        "negative-attributes",
        metric,
        "purchase-criteria",
      ];

      metrics.map((type) => {
        getAttributesSummary(type);
      });
    }
  }, [compBrandIndexScore]);

  return (
    <div>
      <BrandScore
        domain={domain}
        indexScoreDataForLineChart={indexScoreDataForLineChart}
        brandProductCategory={brandProductCategory}
        brandProduct={brandProduct}
        type={type}
        brandIndexScore={brandIndexScore}
        pastBrandIndexScore={pastBrandIndexScore}
        brandProductInfo={brandProductInfo}
        errors={errors}
        loading={loading}
        summaries={summaries}
        indexScoreMetaData={indexScoreMetaData}
        frequency={frequency}
      />

      <p> </p>
      <Competitors
        brandDomains={brandDomains}
        compBrandIndexScore={compBrandIndexScore}
        pastCompBrandIndexScore={pastCompBrandIndexScore}
        compIndexScoreMetaData={compIndexScoreMetaData}
      />
      <p> </p>
      <PositiveAttributes
        positiveAttGraphData={positiveAttGraphData}
        pastPositiveAttGraphData={pastPositiveAttGraphData}
        getAttributesSummary={getAttributesSummary}
        errors={errors}
        summaries={summaries}
        loading={loading}
      />
      <NegativeAttributes
        negativeAttGraphData={negativeAttGraphData}
        pastNegativeAttGraphData={pastNegativeAttGraphData}
        getAttributesSummary={getAttributesSummary}
        errors={errors}
        summaries={summaries}
        loading={loading}
      />
      <p> </p>
      <div className={classes.section_summary}>
        {type == "Product" ? (
          <Taxonomy
            categories={productTaxonomyData}
            brandDomains={brandDomains}
            errors={errors}
            summaries={summaries}
            loading={loading}
            getAttributesSummary={getAttributesSummary}
          />
        ) : (
          <CoreAttributes
            customerId={customerId}
            brandDomains={brandDomains}
            coreAttributesData={coreAttributesData}
            pastcoreAttributesData={pastcoreAttributesData}
            getAttributesSummary={getAttributesSummary}
            errors={errors}
            summaries={summaries}
            loading={loading}
            selectedInput={selectedInput}
            sources={coreAttSourceData}
          />
        )}
      </div>
      <p> </p>
      <div className={classes.section_summary}>
        <CategoryDimensions
          brandDomains={brandDomains}
          purchaseDimData={purchaseDimData}
          pastPurchaseDimData={pastPurchaseDimData}
          getAttributesSummary={getAttributesSummary}
          errors={errors}
          summaries={summaries}
          loading={loading}
          selectedInput={selectedInput}
          sources={categoryDimensionRatingSource}
          customerId={customerId}
        />
      </div>
      {/* <p> </p>
      <Source
        categoryDimensionRatingSource={categoryDimensionRatingSource}
        coreAttSourceData={coreAttSourceData}
        reachSourceData={reachSourceData}
        sentimentSourceData={sentimentSourceData}
        sourceFullUrls={sourceFullUrls}
      /> */}
    </div>
  );
};

export default BrandProductOverview;
