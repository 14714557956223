import { useEffect, useContext } from "react";
import Markdown from "markdown-to-jsx";

import { DiscoveryContext } from "../../Discovery/discovery-context";
import LoadingSkeleton from "./LoadingSkeleton";
import MUICard from "./../../../Component/UI/MUICard/MUICard";
import classes from "./ProductRecommendations.module.css";
import useProductRecommendations from "./useProductRecommendations";

export default function ProductRecommendations() {
  const discoveryCtx = useContext(DiscoveryContext);
  const { loading, productRecommendations, getRecommendedProducts } =
    useProductRecommendations();

  let competitors = discoveryCtx.competitors
    .slice(discoveryCtx.type == "category" ? 0 : 1, 4)
    .map((c) => c.name);

  useEffect(() => {
    if (competitors.length === 0) return;
    getRecommendedProducts();
  }, [competitors.length]);

  return (
    <div>
      <div className={classes.title}>Product Recommendations</div>
      <MUICard className={classes.card}>
        {!loading && productRecommendations && (
          <Markdown>{productRecommendations}</Markdown>
        )}
        {loading && <LoadingSkeleton />}
      </MUICard>
    </div>
  );
}
