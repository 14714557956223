import React, { forwardRef } from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { IconButton, InputAdornment } from "@mui/material";

const BootstrapInput = styled(InputBase)(({ theme, error }) => ({
  "label + &": {
    marginTop: theme.spacing(2.5),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "1px solid",
    borderColor: error ? theme.palette.error.main : "#E0E3E7", // Dynamically set border color
    fontSize: 14,
    width: "100%",
    padding: "8px 10px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(
        error ? theme.palette.error.main : theme.palette.primary.main,
        0.25
      )} 0 0 0 0.2rem`,
      borderColor: error
        ? theme.palette.error.main
        : theme.palette.primary.main,
    },
    ...theme.applyStyles("dark", {
      backgroundColor: "#1A2027",
      borderColor: error ? theme.palette.error.main : "#2D3843",
    }),
  },
}));

const TextInput = forwardRef(
  (
    {
      type = "text",
      placeholder,
      startIcon,
      fullWidth,
      value,
      label,
      InputProps,
      inputRef,
      ...props
    },
    ref
  ) => {
    return (
      <FormControl variant="standard" size="small" fullWidth={fullWidth}>
        {label && (
          <InputLabel shrink htmlFor="input">
            {label}
          </InputLabel>
        )}
        <BootstrapInput
          size="small"
          placeholder={placeholder}
          type={type}
          value={value}
          startAdornment={
            startIcon ? (
              <InputAdornment position="start">
                <IconButton edge="start">{startIcon}</IconButton>
              </InputAdornment>
            ) : null
          }
          inputRef={inputRef || ref}
          {...InputProps}
          {...props}
        />
        <small
          style={{
            color: " #f44336",
            position: "absolute",
            top: "60px",
            fontSize: "8px",
          }}
        >
          {props?.error}
        </small>
      </FormControl>
    );
  }
);

export default TextInput;