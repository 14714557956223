import React, { useEffect, useState } from "react";
import {
  TextField,
  Typography,
  FormControlLabel,
  Radio,
  Button,
  Chip,
  Select,
  MenuItem,
  ListItemText,
  IconButton,
  Paper,
  Box,
  List,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputLabel,
  LinearProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RadioButtonsGroup from "../../../Component/UI/RadioGroup/RadioGroup";
import TextInput from "../../../Component/UI/TextInput/TextInput";
import { useSelector } from "react-redux";
import { getPromptForBrandCompetitorsData } from "../../../utils/helpers";
import { fetchGpt4API } from "../../../services/apiService";
import json5 from "json5";
import InputSelectionComponent from "../../Dashboard/Component/inputSelection";
import { debounce } from "lodash";
import Spinner from "../../../Component/Spinner/Spinner";

const radioOption = [
  {
    value: "manual",
    label: "Enter Competitors Manually",
  },
  {
    value: "ai",
    label: "Add Competitors from AI Suggestion",
  },
];
const Addproduct = ({
  productName,
  setProductName,
  productDetails,
  setProductDetails,
  productCategory,
  setProductCategory,
  productCategoryDescription,
  setProductCategoryDescription,
  brandPersona,
  setBrandPersona,
  classes,
  competitors,
  setCompetitors,
  availableCompetitors,
  handleCompetitorToggle,
  handleCloseModal,
  createNewproduct,
  updateCreatedProduct,
  isUpdateModalOpen,
}) => {
  const brands = useSelector((state) => state.brands);
  const brandProducts = useSelector((state) => state.brandProducts);
  const coreAttributes = useSelector((state) => state.coreAttributes);
  const personas = useSelector((state) => state.personas);
  const personasData = personas.data.data;
  const [selectedBrand, setSelectedBrand] = useState("");
  const [competersAI, setCompetersAI] = useState("manual");
  const [competitorsFromAi, setCompetitorsFromAi] = useState([]);
  const [isLoading, setIsLoading] = useState({
    competitors: false,
    productDimensions: false,
    purchaseCriteria: false,
  });
  const [prevProductName, setPrevProductName] = useState("");
  const [editing, setEditing] = useState(null);
  const [editedValue, setEditedValue] = useState("");
  const [brandPersonaLabel, setBrandPersonaLabel] = useState([]);
  const [productDimensions, setProductDimensions] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedText, setEditedText] = useState("");
  const [purchaseCriteria, setPurchaseCriteria] = useState([]);

  // Create a debounced version of the fetch function
  const debouncedFetchLLMProductDimensions = React.useCallback(
    debounce(() => {
      fetchLLMProductDimensions();
    }, 4000),
    [productCategory]
  );

  // Trigger the debounce function when brandName or brandCategoryName changes
  useEffect(() => {
    if (productCategory) {
      debouncedFetchLLMProductDimensions();
    }
    // Cleanup debounce on unmount
    return () => debouncedFetchLLMProductDimensions.cancel();
  }, [productCategory, debouncedFetchLLMProductDimensions]);

  // Create a debounced version of the fetch function
  const debouncedFetchLLMPurchaseCriteria = React.useCallback(
    debounce(() => {
      fetchLLMPurchaseCriteria();
    }, 4000),
    [selectedBrand, productCategory]
  );

  // Trigger the debounce function when brandName or brandCategoryName changes
  useEffect(() => {
    if (selectedBrand && productCategory) {
      debouncedFetchLLMPurchaseCriteria();
    }
    // Cleanup debounce on unmount
    return () => debouncedFetchLLMPurchaseCriteria.cancel();
  }, [selectedBrand, productCategory, debouncedFetchLLMPurchaseCriteria]);

  // Define the fetch function
  const fetchLLMPurchaseCriteria = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, purchaseCriteria: true }));
      let res = await fetch(
        "https://llmapi.buzzybrains.net/api/metrics/generate-category-dimensions",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            llm: "gpt-4o",
            brand: selectedBrand,
            category: productCategory,
          }),
        }
      );
      res = await res.json();
      res = res.map((item) => item.name);
      setPurchaseCriteria([...res]);
    } catch (error) {
      console.error("Error fetching purchase criteria:", error);
    } finally {
      setIsLoading((prev) => ({
        ...prev,
        purchaseCriteria: false,
      }));
    }
  };
  const handlePersonaCheckChange = (selected) => {
    const selectedValues = selected?.map((option) => option?.persona?.name);
    setBrandPersonaLabel(selectedValues || []);
    setBrandPersona(selected || []);
  };

  const handleEdit = (categoryIndex, subcategoryIndex) => {
    setEditing({ categoryIndex, subcategoryIndex });
    setEditedValue(
      productDimensions[categoryIndex].subcategories[subcategoryIndex]
    );
  };

  const handleSave = (categoryIndex, subcategoryIndex) => {
    const updatedCategories = [...productDimensions];
    updatedCategories[categoryIndex].subcategories[subcategoryIndex] =
      editedValue;
    setProductDimensions(updatedCategories);
    setEditing(null);
  };

  const handleDoubleClick = (index, value) => {
    setEditingIndex(index);
    setEditedText(value);
  };

  const handleInputChange = (event) => {
    setEditedText(event.target.value);
  };

  const handleBlur = () => {
    if (editingIndex !== null) {
      const updatedCriteria = [...purchaseCriteria];
      updatedCriteria[editingIndex] = editedText.trim();
      setPurchaseCriteria(updatedCriteria);
      setEditingIndex(null);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleBlur();
    }
  };

  async function getCompetitorsData(type) {
    if (type === "ai" && productName && selectedBrand) {
      try {
        if (prevProductName !== productName) {
          let prompt = getPromptForBrandCompetitorsData(productName, "product");
          setIsLoading((prev) => ({ ...prev, competitors: true }));
          let result = await fetchGpt4API(
            prompt,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            "Brand-onboarding"
          );
          result = result["GPT-4"][0];

          // Match the array of objects using the regular expression
          let matchedArray = result.match(
            /\[\s*\{[^\}]*\}\s*(,\s*\{[^\}]*\}\s*)*\]/g
          );

          if (matchedArray) {
            // Parse the matched array into a JavaScript object
            let resultArray = json5.parse(matchedArray[0]);
            console.log(resultArray);
            setCompetitorsFromAi(resultArray);
            setPrevProductName(productName);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading((prev) => ({ ...prev, competitors: false }));
      }
    }
  }

  // Define the fetch function
  const fetchLLMProductDimensions = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, productDimensions: true }));
      let res = await fetch(
        "https://llmapi.buzzybrains.net/api/metrics/product-dimensions",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            llm: "gpt-4o",
            category: "EV Passenger Cars",
          }),
        }
      );
      res = await res.json();
      setProductDimensions(res.data);
    } catch (error) {
      console.error("Error fetching purchase criteria:", error);
    } finally {
      setIsLoading((prev) => ({
        ...prev,
        productDimensions: false,
      }));
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (productDimensions.length !== 5) {
      alert("Please add Product dimensions");
      return;
    }
    if (brandPersona.length === 0) {
      alert("Please select personas");
      return;
    }
    if (purchaseCriteria.length !== 5) {
      alert("Please add purchase criteria");
      return;
    }

    if (isUpdateModalOpen) {
      updateCreatedProduct(
        productName,
        selectedBrand,
        productDetails,
        productCategory,
        productCategoryDescription,
        competitors,
        brandPersona,
        productDimensions,
        purchaseCriteria
      );
    } else {
      createNewproduct(
        productName,
        selectedBrand,
        productDetails,
        productCategory,
        productCategoryDescription,
        competitors,
        brandPersona,
        productDimensions,
        purchaseCriteria
      );
    }
  };
  return (
    <form onSubmit={handleFormSubmit}>
      <div style={{ marginRight: "15px" }}>
        <Select
          value={selectedBrand}
          onChange={(e) => setSelectedBrand(e.target.value)}
          displayEmpty
          fullWidth
          required
          // style={{ margin: "15px", width: "95%" }}
          // margin="normal"
          className={classes.select}
        >
          <MenuItem value="">Select Brand</MenuItem>
          {brands?.data?.data &&
            brands?.data?.data.length !== 0 &&
            brands?.data?.data
              .filter((obj) => obj?.is_active)
              .map((brand) => (
                <MenuItem value={brand?.brand?.id}>
                  {" "}
                  {brand?.brand?.name}
                </MenuItem>
              ))}
        </Select>
        <div className={classes.formGrid}>
          <TextInput
            label="Product Name"
            variant="outlined"
            fullWidth
            required
            value={productName}
            onChange={(e) => {
              setCompetitors([]);
              setProductName(e.target.value);
              setCompetitorsFromAi([]);
            }}
            className={classes.textField}
          />

          <TextInput
            label=" Product Description"
            variant="outlined"
            fullWidth
            required
            value={productDetails}
            onChange={(e) => setProductDetails(e.target.value)}
            className={classes.textField}
          />

          <TextInput
            label="Product Category Name"
            variant="outlined"
            fullWidth
            required
            value={productCategory}
            onChange={(e) => setProductCategory(e.target.value)}
            className={classes.textField}
          />
          <TextInput
            label="Product Category Description"
            variant="outlined"
            fullWidth
            required
            value={productCategoryDescription}
            onChange={(e) => setProductCategoryDescription(e.target.value)}
            className={classes.textField}
          />
        </div>

        <Box sx={{ mt: 1 }}>
          <InputLabel shrink htmlFor="input">
            Brand Competitor
          </InputLabel>
          {isLoading.competitors && <LinearProgress />}
          <RadioButtonsGroup
            options={radioOption}
            onChange={(v) => {
              setCompetersAI(v);
              setCompetitors([]);
              getCompetitorsData(v);
            }}
          ></RadioButtonsGroup>
          <div>
            <TextInput
              required
              label="Competitors Brand Description"
              variant="outlined"
              fullWidth
              margin="normal"
              value={competitors?.join(", ")}
              onChange={(e) => setCompetitors(e.target.value.split(", "))}
              // className={classes.select}
            />
          </div>
          <div
            style={{
              marginTop: "10px",
            }}
          >
            {console.log(competitorsFromAi, "competitorsFromAi")}
            {competersAI === "ai" &&
              competitorsFromAi?.map((competitor) => (
                <Chip
                  style={{ margin: "5px", borderRadius: "3px" }}
                  key={competitor}
                  label={competitor.comp_product_name}
                  onClick={() =>
                    handleCompetitorToggle(competitor.comp_product_name)
                  }
                  color={
                    competitors?.includes(competitor.comp_product_name)
                      ? "primary"
                      : "default"
                  }
                />
              ))}
          </div>
        </Box>

        <Box sx={{ margin: "auto" }}>
          <InputLabel shrink htmlFor="input">
            Product Dimensions*{" "}
            {productDimensions.length == 0 && (
              <Typography color="error" variant="caption" mt={1}>
                (Enter product category to populate Product Dimensions.)
              </Typography>
            )}
          </InputLabel>
          {isLoading.productDimensions && <LinearProgress />}
          {productDimensions.length > 0 && (
            <Box sx={{ mt: 1 }}>
              {productDimensions.map((category, categoryIndex) => (
                <Accordion key={category.name} sx={{ mb: 2, borderRadius: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      backgroundColor: "#dcebfb", // Your desired color
                      color: "rgba(0, 0, 0, 0.6)",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#7eb7ef", // Optional hover effect
                      },
                      padding: "4px 16px", // Reduce padding
                      minHeight: "32px", // Set a smaller minimum height
                      "& .MuiAccordionSummary-content": {
                        margin: 0, // Remove default margin
                        alignItems: "center", // Center content vertically
                      },
                    }}
                  >
                    <Typography variant="body2">{category.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{ backgroundColor: "#fafafa", padding: "8px 10px" }}
                  >
                    <List>
                      {category.subcategories.map(
                        (subcategory, subcategoryIndex) => (
                          <ListItem
                            key={subcategoryIndex}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "2px",
                              borderBottom: "1px solid #eee",
                            }}
                          >
                            {editing?.categoryIndex === categoryIndex &&
                            editing?.subcategoryIndex === subcategoryIndex ? (
                              <TextField
                                value={editedValue}
                                onChange={(e) => setEditedValue(e.target.value)}
                                size="small"
                                fullWidth
                              />
                            ) : (
                              <ListItemText
                                primary={subcategory}
                                primaryTypographyProps={{ fontSize: "12px" }} // Smaller text
                              />
                            )}
                            <IconButton
                              edge="end"
                              color="primary"
                              onClick={() =>
                                editing?.categoryIndex === categoryIndex &&
                                editing?.subcategoryIndex === subcategoryIndex
                                  ? handleSave(categoryIndex, subcategoryIndex)
                                  : handleEdit(categoryIndex, subcategoryIndex)
                              }
                            >
                              {editing?.categoryIndex === categoryIndex &&
                              editing?.subcategoryIndex === subcategoryIndex ? (
                                <CheckIcon />
                              ) : (
                                <EditIcon fontSize="small" />
                              )}
                            </IconButton>
                          </ListItem>
                        )
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          )}
        </Box>

        <Box mt={2}>
          <InputLabel shrink htmlFor="input">
            Purchase Criteria*{" "}
            {purchaseCriteria.length === 0 && (
              <Typography color="error" variant="caption" mt={1}>
                (Enter product category to populate Purchase Criteria.)
              </Typography>
            )}
          </InputLabel>
          {isLoading.purchaseCriteria && <LinearProgress className="mb-2" />}

          <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
            {purchaseCriteria.map((tag, index) => (
              <Box key={index}>
                {editingIndex === index ? (
                  <TextField
                    value={editedText}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onKeyPress={handleKeyPress}
                    size="small"
                    variant="outlined"
                    autoFocus
                  />
                ) : (
                  <Chip
                    label={tag}
                    color="primary"
                    variant="outlined"
                    title="Double click to edit"
                    onDoubleClick={() => handleDoubleClick(index, tag)}
                  />
                )}
              </Box>
            ))}
          </Box>
        </Box>

        <Box mt={2}>
          <InputLabel shrink htmlFor="input">
            Persona
          </InputLabel>
          <InputSelectionComponent
            selectedOptions={brandPersonaLabel}
            handleChange={handlePersonaCheckChange}
            options={personasData}
            type="persona"
          />
          {brandPersonaLabel.length === 0 && (
            <Typography color="error" variant="caption" mt={1}>
              Please select a persona.
            </Typography>
          )}
        </Box>

        <div className={classes.buttonDiv}>
          <Button
            // onClick={handleConfirm}
            // color={confirmBtnColor}
            type="submit"
            variant="contained"
            disabled={
              brandProducts?.loading ||
              !purchaseCriteria.length ||
              !productDimensions.length ||
              !brandPersonaLabel.length
            }
          >
            <div>{isUpdateModalOpen ? "Update" : "Add"}</div>
          </Button>
          <Button variant="outlined" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Addproduct;
