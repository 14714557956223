import { Skeleton } from "@mui/material";

export default function LoadingSkeleton() {
  return (
    <div>
      {Array(10)
        .fill(0)
        .map((_, index) => {
          const width = Math.random() * (8 - 5 + 1) + 5;
          return <Skeleton key={index} variant="text" height={30} width={width * 100} />;
        })}
    </div>
  );
}
