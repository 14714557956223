import { useEffect, useContext, useState, useRef } from "react";
import { ListItem, ListItemButton, ListItemText } from "@mui/material";

import MUICard from "./../../../Component/UI/MUICard/MUICard";
import MUIList from "./../../../Component/UI/MUIList/MUIList";
import { DiscoveryContext } from "../discovery-context";
import ErrorView from "../../../Component/ErrorView/ErrorView";
import api from "../../../BrandOnBoarding/api";
import MUIToolTip from "./../../../Component/UI/MUIToolTip/MUIToolTip";
import LoadingSkeleton from "./LoadingSkeleton";
import classes from "./Attributes.module.css";

export default function Attributes({ entity, market }) {
  const [error, setError] = useState();
  const discoveryCtx = useContext(DiscoveryContext);
  const discoveryType = discoveryCtx.type || "brand";

  const abortControllerRef = useRef(null);

  async function getPositiveNegativeAttributes() {
    setError(null);
    abortControllerRef.current = new AbortController();
    try {
      const payload = {
        location: "",
        market: market,
        llm: discoveryCtx.llm,
        persona: "General Profile",
        exec_count: discoveryCtx.type === "category" ? 1 : 3,
        type: discoveryCtx.type,
      };

      if (discoveryCtx.type === "brand") {
        payload.brand = entity;
      } else {
        payload.brand = entity;
      }
      if (discoveryCtx.llm === "Perplexity") {
        await new Promise((res) => setTimeout(() => res(""), 120000));
      }
      let response = await api.post(`/metrics/v3/signal-strength`, payload, {
        signal: abortControllerRef.current.signal,
      });

      const negativeAttributes = response.data.aggregatedNegatives;
      const positiveAttributes = response.data.aggregatedPositives;

      const sources = [
        ...response.data.aggregatedPositiveSources,
        ...response.data.aggregatedNegativeSources,
      ];

      discoveryCtx.updatePositiveNegativeAttributes({
        positives: positiveAttributes.slice(0, 10),
        negatives: negativeAttributes.slice(0, 10),
      });
      discoveryCtx.updateSources(sources);
    } catch (error) {
      console.log("Something went wrong", error);
      setError(error.message);
    }
  }

  useEffect(() => {
    if (discoveryCtx.abort_launch) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    }
  }, [discoveryCtx.abort_launch]);

  useEffect(() => {
    if (Object.keys(discoveryCtx.positive_negative_attributes).length > 0)
      return;
    getPositiveNegativeAttributes();

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [entity, Object.keys(discoveryCtx.positive_negative_attributes).length]);

  const renderRow = (item, index) => {
    const isEven = index % 2 === 0;
    const isFirst = index === 0;

    const style = {
      background: isEven ? "#F8FAFB" : "#FFFFFF",
      borderTop: isFirst
        ? "1px solid #9d9d9d"
        : isEven
        ? "none"
        : "1px solid #eef2f6",
      borderBottom: !isEven ? "1px solid #eef2f6" : "none",
    };

    let attributeName = item;
    let sources = [];

    if (typeof item === "object") {
      attributeName = item.name;
      sources = item?.sources
        ? item.sources.map((source) => ({ source: source, snippet: "" }))
        : [];
    }

    return (
      <ListItem disablePadding style={style} key={index}>
        <ListItemButton
          className={classes.list_btn}
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <MUIToolTip
            additionalText={`${discoveryCtx.category} ${discoveryCtx.entity} has ${attributeName}`}
            llmSources={sources}
            title={``}
            showSnippets
            disableAction
            showSources
            actionText="Add To Actions"
            llm={discoveryCtx.llm}
          >
            <ListItemText
              sx={{
                "& .MuiListItemText-primary": {
                  fontSize: 12,
                  color: "#1B2559",
                  fontWeight: 400,
                  whiteSpace: "nowrap",
                  textAlign: "center",
                  overflow: "hidden",
                  maxWidth: 240,
                  textOverflow: "ellipsis",
                },
                display: "flex",
                justifyContent: "center",
              }}
              title={attributeName}
              primary={attributeName}
            />
          </MUIToolTip>
        </ListItemButton>
      </ListItem>
    );
  };

  let positiveAttributes = [];
  let negativeAttributes = [];
  let loading = true;

  if (Object.keys(discoveryCtx.positive_negative_attributes).length > 0) {
    positiveAttributes = discoveryCtx.positive_negative_attributes.positives;
    negativeAttributes = discoveryCtx.positive_negative_attributes.negatives;
    loading = false;
  }

  return (
    <MUICard
      title={
        discoveryType === "brand" ? "Brand Attributes" : "Product Attributes"
      }
      className={classes.card}
      titleClassName={classes.title}
      variant="elevated"
      elevation={0}
    >
      {loading && !error && <LoadingSkeleton />}
      {!loading && !error && (
        <div className={classes.table_container}>
          <MUIList
            className={classes.list}
            title="Positive"
            data={positiveAttributes}
            render={renderRow}
          />
          <MUIList
            className={classes.list}
            title="Negative"
            data={negativeAttributes}
            render={renderRow}
          />
        </div>
      )}
      {error && (
        <div className={classes.error_view}>
          <ErrorView
            title={"Error fetching brand attributes"}
            message={error}
            retry
            onRetry={() => getPositiveNegativeAttributes()}
          />
        </div>
      )}
    </MUICard>
  );
}
