import { useState, useContext } from "react";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";

import MUISelect from "./../../../Component/UI/MUISelect/MUISelect";
import MUIChip from "../../../Component/UI/MUIChip/MUIChip";
import { DiscoveryContext } from "../../Discovery/discovery-context";

export default function AttributeInput({ label, placeholder, disabled }) {
  const [inputValue, setInputValue] = useState("");

  const discoveryCtx = useContext(DiscoveryContext);

  const onPrefSelect = (event) => {
    if (discoveryCtx.attributes.length === 3) {
      toast.warn("Max 3 attributes allowed");
      return;
    }
    discoveryCtx.updateAttribute([
      ...discoveryCtx.attributes,
      event.target.value,
    ]);
  };

  const handleDelete = (chipToDelete) => () => {
    discoveryCtx.updateAttribute(
      discoveryCtx.attributes.filter((chip) => chip !== chipToDelete)
    );
  };

  return (
    <div className="d-flex flex-column">
      <MUISelect
        optionValue={inputValue || ""}
        label={label}
        options={discoveryCtx.product_prefs.map((pref) => ({
          id: pref,
          name: pref,
          value: pref,
        }))}
        disabled={disabled}
        onChange={onPrefSelect}
      ></MUISelect>
      {discoveryCtx.attributes.length === 0 && (
        <Typography
          color="textDisabled"
          variant="caption"
          sx={{ fontSize: "8px" }}
        >
          After typing press 'Enter'.
        </Typography>
      )}
      <div className="d-flex mt-2">
        {discoveryCtx.attributes.map((value) => (
          <MUIChip
            disabled={discoveryCtx.loading || discoveryCtx.is_launched}
            key={value}
            label={value}
            size="small"
            onDelete={handleDelete(value)}
            sx={{ marginRight: 0.5 }}
          />
        ))}
      </div>
    </div>
  );
}
