import Markdown from "markdown-to-jsx";

import classes from "./Message.module.css";

export default function Message({ content, role }) {
  let container = classes.messageContainer;
  let message = classes.message;

  function normalizeMarkdown(text) {
    // Only bullet points
    text = text.replace(/^\d+\.\s+/gm, "- ");

    // Bold any text starting with ## and followed by a newline and `-`
    text = text.replace(
      /(^|\n)(#{1,4})\s(.*?)(\n)/g,
      (match, prefix, hashes, brand, suffix) => {
        return `${prefix}- **${brand}**${suffix}`;
      }
    );
    return text;
  }

  if (role === "user") {
    message += ` ${classes.user}`;
    container += ` ${classes.userContainer}`;
  } else {
    message += ` ${classes.assistant}`;
  }

  return (
    <div className={container}>
      <span className={message}>
        <Markdown className={classes.content}>
          {normalizeMarkdown(content)}
        </Markdown>
        {/* <MUIIconButton className={classes.copy}>
          <ContentCopyOutlinedIcon style={{ fontSize: 10 }} fontSize={"12"} />
        </MUIIconButton> */}
      </span>
    </div>
  );
}
