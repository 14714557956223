import { useContext, useEffect, useState } from "react";

import { AuthContext } from "../context/auth-context";
import api from "../BrandOnBoarding/api";

function isSameDate(date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

export function useDailyLimits(feature) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  const { user } = useContext(AuthContext);

  const getHistory = async () => {
    let response = await api.get(`/users/${user.db_id}`);
    let dbUser = response.data.data;
    setLoading(false);
    setCurrentUser(dbUser);
  };

  useEffect(() => {
    setLoading(true);
    getHistory();
  }, []);

  function resetLimits(updatedUser) {
    updatedUser.discovery_limits_remaining = updatedUser.discovery_limits;
    updatedUser.prompt_engine_limits_remaining =
      updatedUser.prompt_engine_limits;
    updatedUser.last_usage_date = Date.now();
  }

  function updateLaunches() {
    let updatedUser = {
      ...currentUser,
    };

    const currentDate = Date.now();
    const usageDate = new Date(updatedUser.last_usage_date);
    const currentMonth = new Date().getMonth() + 1;
    const usageMonth = new Date(updatedUser.last_usage_date).getMonth() + 1;

    const isNewMonth =
      updatedUser.limit_frequency === "monthly" && currentMonth !== usageMonth;
    const isNewDay =
      updatedUser.limit_frequency !== "monthly" &&
      !isSameDate(currentDate, usageDate);

    if (isNewMonth || isNewDay) {
      resetLimits(updatedUser);
    } else {
      if (feature === "Discovery") {
        updatedUser.discovery_limits_remaining -= 1;
      } else {
        updatedUser.prompt_engine_limits_remaining -= 1;
      }
      updatedUser.last_usage_date = Date.now();
    }
    return updatedUser;
  }

  async function updateUser() {
    const updatedUser = updateLaunches();

    const payload = {
      is_verified: updatedUser.is_verified,
      is_active: updatedUser.is_active,
      discovery_limits_remaining: updatedUser.discovery_limits_remaining,
      prompt_engine_limits_remaining:
        updatedUser.prompt_engine_limits_remaining,
      last_usage_date: updatedUser.last_usage_date,
    };
    setCurrentUser(updatedUser);
    await api.patch(`/users/${user.db_id}`, payload);
  }

  useEffect(() => {
    if (!currentUser) return;

    let isReset = false;
    const currentMonth = new Date().getMonth() + 1;
    const usageMonth = new Date(currentUser.last_usage_date).getMonth() + 1;
    const currentDate = Date.now();
    const usageDate = new Date(currentUser.last_usage_date);

    if (
      currentUser.limit_frequency === "monthly" &&
      currentMonth !== usageMonth
    ) {
      isReset = true;
    } else if (
      currentUser.limit_frequency !== "monthly" &&
      !isSameDate(currentDate, usageDate)
    ) {
      isReset = true;
    }
    if (isReset) {
      updateUser();
    }
  }, [currentUser]);

  let remainingCalls =
    feature === "Discovery"
      ? currentUser?.discovery_limits_remaining
      : currentUser?.prompt_engine_limits_remaining;

  let apiCalls =
    feature === "Discovery"
      ? currentUser?.discovery_limits
      : currentUser?.prompt_engine_limits;

  let limitReached = remainingCalls == 0;

  let launchText = `${remainingCalls} launch${
    remainingCalls !== 1 ? "es" : ""
  } remaining out of the ${apiCalls} launches ${
    currentUser?.limit_frequency
  } limit.`;

  if (limitReached && !currentUser?.is_special) {
    launchText = `You have reached your ${
      currentUser?.limit_frequency
    } limit. You may try again next  ${
      currentUser?.limit_frequency === "daily" ? "day" : "month"
    }.`;
  }

  if (loading || currentUser?.is_special) launchText = "";

  return {
    launchesRemaining: remainingCalls,
    launchText: launchText,
    updateLaunches: updateUser,
    isSpecial: currentUser?.is_special || false,
    frequency: currentUser?.limit_frequency ?? "monthly"
  };
}
