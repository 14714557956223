import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../../context/auth-context";
import { Spinner } from "react-bootstrap";
import EmptyView from "../../../../Component/EmptyView/EmptyView";
import FilterSelection from "../../common/FilterSelection"; // Adjust the path if needed
import { ActionTable } from "./ActionTable";
import { fetchActionMetrics } from "../../../../redux/actions/metricActions";
import { useSelector, useDispatch } from "react-redux";

export function prepareSelectOptions(dataArray) {
  const options = {
    llm: new Set(),
    persona: new Set(),
    market: new Set(),
    geo: new Set(),
    brand: new Set(),
    product: new Set(),
  };

  dataArray.forEach((item) => {
    if (item.llm) options.llm.add(item.llm);
    if (item.persona) options.persona.add(item.persona);
    if (item.market) options.market.add(item.market);
    if (item.geography) options.geo.add(item.geography);
    if (!item.isProduct && item.brand) options.brand.add(item.brand);
    if (item.isProduct && item.brand) options.product.add(item.brand);
  });

  // Convert sets to the desired array format
  const formattedOptions = Object.fromEntries(
    Object.entries(options).map(([key, values]) => [
      key,
      [...values].map((value) => ({ value, label: value })),
    ])
  );

  return formattedOptions;
}

const Actions = ({ handleChange, isLoading, actionMetricsData }) => {
  const { user } = useContext(AuthContext);
  const customerId = user?.customer_id || 0;
  const dispatch = useDispatch();
  const actionMetrics = useSelector((state) => state.actionMetrics);
  const [filters, setFilters] = useState({
    brand: [],
    product: [],
    llm: [],
    market: [],
    geo: [],
    persona: [],
  });

  useEffect(() => {
    if (actionMetrics?.data && actionMetrics?.data.length === 0) {
      dispatch(fetchActionMetrics(customerId));
    }
  }, []);

  const handleFilterChange = (key, selectedOptions) => {
    const values = selectedOptions.map((option) => option.value);
    setFilters((prevFilters) => ({ ...prevFilters, [key]: values }));
  };

  const applyFilters = (data) => {
    return data.filter((item) => {
      const isBrandValid =
        !filters.brand.length || filters.brand.includes(item?.brand);
      const isLlmValid = !filters.llm.length || filters.llm.includes(item?.llm);
      const isMarketValid =
        !filters.market.length || filters.market.includes(item?.market);
      const isGeoValid =
        !filters.geo.length || filters.geo.includes(item?.geography);
      const isPersonaValid =
        !filters.persona.length || filters.persona.includes(item?.persona);
      const isProductValid =
        !filters.product.length ||
        (item.isProduct && filters.product.includes(item?.brand));

      // Handle specific case for isProduct flag
      const isItemValid = item.isProduct ? isProductValid : isBrandValid;

      return (
        isItemValid &&
        isLlmValid &&
        isMarketValid &&
        isGeoValid &&
        isPersonaValid
      );
    });
  };

  const optionsMapping = prepareSelectOptions(actionMetricsData);

  return (
    <>
      <div>
        <div className="col-md-12 mt-4">
          {isLoading ? (
            <div
              style={{
                fontSize: "19px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              &nbsp; Loading...
            </div>
          ) : (
            <>
              {actionMetricsData?.length === 0 ? (
                <EmptyView message="Nothing here" />
              ) : (
                <div className="row my-2">
                  {["brand", "product", "llm", "market", "geo", "persona"].map(
                    (filterKey) => (
                      <div className="col-md-2" key={filterKey}>
                        <FilterSelection
                          options={optionsMapping[filterKey]}
                          type={filterKey}
                          selectedOptions={filters[filterKey].map((value) => ({
                            value,
                            label: value,
                          }))}
                          handleChange={(selectedOptions) =>
                            handleFilterChange(filterKey, selectedOptions)
                          }
                        />
                      </div>
                    )
                  )}
                </div>
              )}

              <ActionTable
                handleChange={handleChange}
                tableType="opp"
                actionMetricsData={applyFilters(actionMetricsData)}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Actions;
