import api from "../../BrandOnBoarding/api";
import {
  FETCH_CORE_ATTRIBUTES_REQUEST,
  FETCH_CORE_ATTRIBUTES_FAILURE,
  FETCH_CORE_ATTRIBUTES_SUCCESS,
  DELETE_CORE_ATTRIBUTES_REQUEST,
  DELETE_CORE_ATTRIBUTES_FAILURE,
  DELETE_CORE_ATTRIBUTES_SUCCESS,
  UPDATE_CORE_ATTRIBUTES_SUCCESS,
  UPDATE_CORE_ATTRIBUTES_FAILURE,
  UPDATE_CORE_ATTRIBUTES_REQUEST,
  CREATE_CORE_ATTRIBUTES_REQUEST,
  CREATE_CORE_ATTRIBUTES_FAILURE,
  CREATE_CORE_ATTRIBUTES_SUCCESS,
} from "../constants/actionTypes";

export const fetchcoreAttributes = (customer_id) => async (dispatch) => {
  dispatch({ type: FETCH_CORE_ATTRIBUTES_REQUEST });
  try {
    const response = await api.get(
      `/customers/core-attributes?customer_id=${customer_id}`
    );
    dispatch({ type: FETCH_CORE_ATTRIBUTES_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_CORE_ATTRIBUTES_FAILURE, error: error.message });
  }
};

export const deleteCoreAttributes = (id, customer_id) => async (dispatch) => {
  dispatch({ type: DELETE_CORE_ATTRIBUTES_REQUEST });
  try {
    await api.delete(`/customers/core-attributes?id=${id}`);
    const response = await api.get(
      `/customers/core-attributes?customer_id=${customer_id}`
    );
    dispatch({ type: DELETE_CORE_ATTRIBUTES_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: DELETE_CORE_ATTRIBUTES_FAILURE, error: error.message });
  }
};

export const updateCoreAttributes =
  (payload, customer_id) => async (dispatch) => {
    dispatch({ type: UPDATE_CORE_ATTRIBUTES_REQUEST });
    try {
      await api.patch(`/customers/core-attributes`, payload);
      const response = await api.get(
        `/customers/core-attributes?customer_id=${customer_id}`
      );
      dispatch({
        type: UPDATE_CORE_ATTRIBUTES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: UPDATE_CORE_ATTRIBUTES_FAILURE, error: error.message });
    }
  };

export const creteCoreAttributes = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_CORE_ATTRIBUTES_REQUEST });
  try {
    await api.post(`/customers/core-attributes`, payload);
    const response = await api.get(
      `/customers/core-attributes?customer_id=${payload.customer_id}`
    );
    dispatch({
      type: CREATE_CORE_ATTRIBUTES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: CREATE_CORE_ATTRIBUTES_FAILURE, error: error.message });
  }
};
