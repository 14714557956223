import React, { useState, useEffect } from "react";
import {
  prepareTableData,
  transformDataForMultibarChart,
  extractDomain,
  getFilteredPurchaseDimensions,
  generateCompDashboardJsonPrompt,
} from "../../../utils/helpers";
import ReMultibarChart from "../common/ReMultibarChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import classes from "./DashboardComponent.module.css";
import CustomSelectWithApply from "../common/CustomSelectionComponent";
import { metricExplainations, titleMapping } from "../../../utils/constant";
import CustomTooltip from "../common/CustomTooltip";
import EmptyView from "../../../Component/EmptyView/EmptyView";
import { getDashboardSummary } from "../../../services/apiService";
import Markdown from "markdown-to-jsx";
import { Spinner } from "react-bootstrap";
import MUIButton from "./../../../Component/UI/MUIButton/MUIButton";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import { Tooltip } from "@mui/material";
import Modal from "../../../Component/Modal/Modal";
import BrandReachSentimentMeta from "../../../Component/BrandIndexHistory/BrandReachSentimentMeta";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const CompComparisonComponent = ({
  compCoreAttributes,
  pastCompCoreAttributes,
  compCategoryDimensions,
  pastCompCategoryDimensions,
  compBrandIndexScore,
  pastCompBrandIndexScore,
  compPositiveAttributes,
  compNegativeAttributes,
  brandDomains,
  brandProduct,
  indexScoreMetaData,
  compIndexScoreMetaData,
  type,
  productTaxonomyData,
}) => {
  const combineBrandAndCompMeta = () => {
    let compIndexMetaData = compIndexScoreMetaData || [];
    let dataToPush = { name: brandProduct, extras: indexScoreMetaData?.extras };
    compIndexMetaData.push(dataToPush);
    return compIndexMetaData;
  };

  const filteredPurchaseDmesnions = getFilteredPurchaseDimensions(
    compCategoryDimensions
  );

  const dimensionMetricData =
    type == "Product" ? productTaxonomyData : compCoreAttributes;
  const tableData = prepareTableData(
    dimensionMetricData,
    compBrandIndexScore,
    filteredPurchaseDmesnions,
    pastCompCoreAttributes,
    pastCompBrandIndexScore,
    pastCompCategoryDimensions,
    type == "Product"
  );

  const targetHeaders = tableData?.headers;

  // comp filter

  const options = tableData?.headers; // Array of strings
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [isFilterActive, setIsFilterActive] = useState(false);
  const [selectedCompIndex, setSelectedCompIndex] = useState([]);
  const [summary, setSummary] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showBrandIndexBreakups, setShowBrandIndexBreakups] = useState(false);
  const [metaData, setMetaData] = useState();
  const [expandedRows, setExpandedRows] = useState({}); // Store expanded state for rows

  const toggleRow = (attribute) => {
    setExpandedRows((prev) => ({
      ...prev,
      [attribute]: !prev[attribute],
    }));
  };

  useEffect(() => {
    if (selectedOptions.length) {
      const indexes = selectedOptions.map(
        (input) => targetHeaders?.indexOf(input)
        // tableData?.headers.indexOf(input)
      );
      setSelectedCompIndex(indexes);
      setIsFilterActive(true);
    } else {
      setSelectedCompIndex([]);
      setIsFilterActive(false);
    }
  }, [selectedOptions]);

  const handleChange = (selected) => {
    setSelectedOptions(selected ? selected.map((item) => item.label) : []);
  };

  // comp brand pagination
  const [startIndex, setStartIndex] = useState(0);
  const pageSize = 5; //
  const visibleHeaders = isFilterActive
    ? targetHeaders.filter((item) => selectedOptions.includes(item))
    : targetHeaders.slice(startIndex, startIndex + pageSize);

  const canGoBack = startIndex > 0;
  const canGoForward = startIndex + pageSize < tableData?.headers.length;

  const handleNext = () => {
    if (canGoForward) {
      setStartIndex(startIndex + pageSize);
    }
  };

  const handlePrevious = () => {
    if (canGoBack) {
      setStartIndex(startIndex - pageSize);
    }
  };

  const getFilteredValues = (values) => {
    if (!isFilterActive) {
      return values.slice(startIndex, startIndex + pageSize);
    } else {
      return selectedCompIndex
        .filter((index) => index !== -1) // Filter out invalid indexes (-1)
        .map((index) => values[index]);
    }
  };

  const getTruncatedText = () => {
    const words = summary?.split(" ");
    return words.slice(0, 30).join(" ") + (words.length > 50 ? "...." : "");
  };

  const filteredData = (data, keysToKeep) => {
    return data
      ?.map((obj) => {
        const newObj = { name: obj.name };

        keysToKeep.forEach((key) => {
          if (obj.hasOwnProperty(key)) {
            newObj[key] = obj[key];
          }
        });

        return Object.keys(newObj).length > 1 ? newObj : null;
      })
      .filter((obj) => obj !== null);
  };

  let positiveAttBarChartData = transformDataForMultibarChart(
    compPositiveAttributes
  );
  let negativeAttBarChartData = transformDataForMultibarChart(
    compNegativeAttributes
  );

  positiveAttBarChartData = filteredData(
    positiveAttBarChartData,
    visibleHeaders
  );
  negativeAttBarChartData = filteredData(
    negativeAttBarChartData,
    visibleHeaders
  );

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const fetchSummary = async () => {
    setIsLoading(true);
    try {
      const produtSpecificMetric =
        type == "Product" ? productTaxonomyData : compCoreAttributes;
      const jsonData = generateCompDashboardJsonPrompt(
        brandProduct,
        "Competitor",
        JSON.stringify(compBrandIndexScore),
        JSON.stringify(positiveAttBarChartData),
        JSON.stringify(negativeAttBarChartData),
        produtSpecificMetric,
        filteredPurchaseDmesnions,
        type == "Product"
      );
      const payload = {
        entity: brandProduct,
        dashboardType: "compDashboard",
        jsonData,
        isProduct: type == "Product",
      };
      const response = await getDashboardSummary(payload);
      setSummary(response);
    } catch (error) {
      console.error("Error fetching summary:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const chartDataArray = [positiveAttBarChartData, negativeAttBarChartData];

  const handleIndexScoreMetaModal = (isMeta, brandProductName, metric) => {
    const reachOrSentiment = metric !== "Brand Index Score";
    const isReach = metric == "Rank";
    if (isMeta && reachOrSentiment) {
      const filterMeta = combineBrandAndCompMeta()?.filter(
        (obj) => obj?.name == brandProductName
      );
      if (filterMeta && filterMeta.length && filterMeta?.[0]?.extras) {
        setShowBrandIndexBreakups(true);
        setMetaData({ data: filterMeta?.[0]?.extras, isReach: isReach });
      }
    }
  };

  return (
    <>
      <Modal
        open={showBrandIndexBreakups}
        handleClose={() => setShowBrandIndexBreakups(false)}
        width={1200}
      >
        <BrandReachSentimentMeta
          isReach={metaData?.isReach}
          data={metaData?.data}
        />
      </Modal>
      <div className="col-md-12 mt-4">
        {/* <div className="col-md-12">
          <div className="graphGreyBg">
            <p style={{ display: "flex", justifyContent: "center" }}>
              <h4>Headlines</h4>
              &nbsp; &nbsp;
              <MUIButton
                onClick={fetchSummary}
                variant="outlined"
                className={classes.regenerate}
                startIcon={<AutoAwesomeOutlinedIcon />}
              >
                Generate
              </MUIButton>
            </p>
            <div className="graphsummary font-11">
              {isLoading ? (
                <div
                  style={{
                    fontSize: "19px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                    // color: "green",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp; Loading...
                </div>
              ) : summary && typeof summary === "string" ? (
                <>
                  <Markdown className="markTable">
                    {isExpanded ? summary : getTruncatedText()}
                  </Markdown>
                  <span
                    style={{ cursor: "pointer", color: "blue" }}
                    onClick={() => setIsExpanded(!isExpanded)}
                  >
                    {isExpanded ? "Show Less" : "Show More"}
                  </span>
                </>
              ) : (
                // news
                ""
              )}
            </div>
          </div>
        </div>

        <p> </p> */}

        <div style={{ fontSize: "15px", marginBottom: "-35px" }}>
          <label>
            <input
              style={{}}
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            &nbsp;
            {"Show Change Data"}
          </label>
        </div>

        <div
          className="d-flex justify-content-evenly"
          style={{
            margin: "-20px 0px 10px 63%",
            width: "40%",
            // marginLeft: "96%",
            cursor: "pointer",
          }}
        >
          <CustomSelectWithApply
            selectedOptions={selectedOptions}
            options={options}
            handleChange={handleChange}
          />

          {!isFilterActive && canGoBack && (
            <i
              style={{ fontSize: "22px", marginTop: "5px" }}
              onClick={handlePrevious}
              className="bi bi-arrow-left-circle-fill"
            ></i>
          )}
          {!isFilterActive && canGoForward && (
            <i
              style={{ fontSize: "22px", marginTop: "5px" }}
              onClick={handleNext}
              className="bi bi-arrow-right-circle-fill"
            ></i>
          )}
        </div>

        <div
          style={{
            position: "relative",
            maxHeight: "400px",
            overflowY: "auto",
          }}
          className="card custCard"
        >
          <table className="table table-striped">
            <thead
              style={{
                position: "sticky",
                // zIndex: 10,
                top: 0,
              }}
            >
              <tr className="tblHeader text-center">
                <th
                  scope="col"
                  style={{
                    // width: "150px",
                    textAlign: "left",
                    paddingLeft: "15px",
                    verticalAlign: "middle",
                  }}
                >
                  {/* <Tooltip title={metricExplainations["RBI"]}> RBI </Tooltip> */}
                </th>

                {visibleHeaders.map((header, idx) => (
                  <th
                    scope="col"
                    key={idx}
                    style={{
                      width: "100px",
                      verticalAlign: "top", // Ensures the header remains at the top
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center", // Centers the content horizontally
                        justifyContent: "flex-start", // Aligns the logo at the top
                        height: "100%", // Ensures full height usage
                      }}
                    >
                      <img
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "6px",
                          marginBottom: "5px", // Adds spacing between logo and text
                        }}
                        src={`https://img.logo.dev/${extractDomain(
                          brandDomains?.[header]
                        )}?token=pk_SaDf4t2eRsW5Fc84zgJRZA`}
                        alt={""}
                      />
                      <span
                        style={{
                          wordWrap: "break-word", // Ensures long text wraps
                          textAlign: "center",
                          marginTop: "5px", // Adds space between logo and text
                        }}
                      >
                        {header}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {tableData.tables.map((table, tableIndex) => (
                <React.Fragment key={tableIndex}>
                  {tableIndex !== 0 && <br />}
                  <tr className="tblHeader">
                    <th
                      style={{ textAlign: "left", paddingLeft: "15px" }}
                      colSpan={tableData.headers.length + 1}
                    >
                      <Tooltip title={metricExplainations[table.title]}>
                        <span
                          style={{
                            color: "black",
                            fontSize: "1.2rem",
                            fontWeight: "500",
                          }}
                        >
                          {table.title}
                        </span>
                      </Tooltip>
                    </th>
                  </tr>

                  {table?.rows && table?.rows.length ? (
                    table.rows.map((row, idx) => {
                      const isProductDimensions =
                        table.title === "Product Dimensions";
                      const isParentRow = !row.values?.[0]?.parentCategory;
                      const isExpanded = expandedRows[row.attribute];

                      if (isProductDimensions) {
                        if (isParentRow) {
                          // Parent Row for Product dimensions
                          return (
                            <React.Fragment key={idx}>
                              <tr>
                                <td
                                  valign="middle"
                                  style={{
                                    width: "150px",
                                    textAlign: "left",
                                    paddingLeft: "10px",
                                    // fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => toggleRow(row.attribute)}
                                >
                                  {isExpanded ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}{" "}
                                  &nbsp;
                                  {titleMapping?.[row.attribute] ||
                                    row.attribute}
                                </td>
                                {getFilteredValues(row.values)
                                  // .slice(startIndex, startIndex + pageSize)
                                  .map((val, valIdx) => {
                                    const isColorApply =
                                      table?.title == "RBI" ||
                                      table?.title == "Rank" ||
                                      table?.title == "Favorability";
                                    let color = "#dc3545";
                                    if (Number(val?.value).toFixed(0) >= 7)
                                      color = "#2196F3";
                                    if (Number(val?.value).toFixed(0) >= 9)
                                      color = "#198754";
                                    return (
                                      <td
                                        key={valIdx}
                                        style={{
                                          width: "100px",
                                          textAlign: "center",
                                          // cursor: "pointer",
                                        }}
                                      >
                                        <CustomTooltip
                                          explaination={val?.explanation}
                                        >
                                          <div
                                            // title={val?.explaination}
                                            className="d-flex align-items-center justify-content-center flex-row"
                                          >
                                            <span
                                              onClick={() => {
                                                handleIndexScoreMetaModal(
                                                  isColorApply,
                                                  visibleHeaders?.[valIdx],
                                                  row.attribute
                                                );
                                              }}
                                              onMouseEnter={(e) => {
                                                if (
                                                  row.attribute !=
                                                  "Brand Index Score"
                                                ) {
                                                  e.target.style.color =
                                                    "#2196F3";
                                                }
                                              }} // Change color on hover
                                              onMouseLeave={(e) =>
                                                (e.target.style.color =
                                                  !isColorApply && !val?.isNa
                                                    ? color
                                                    : "grey")
                                              }
                                              style={{
                                                cursor: "pointer",
                                                color:
                                                  !isColorApply && !val?.isNa
                                                    ? color
                                                    : "grey",
                                              }}
                                              className={`h5 mb-0 me-1
                              } ${classes["main-value"]}`}
                                            >
                                              {val?.value != null &&
                                              !isNaN(Number(val.value)) &&
                                              (row.attribute == "Rank" ||
                                                val.value != 0)
                                                ? Number(val.value).toFixed(0)
                                                : "NA"}
                                            </span>
                                            {val?.change != undefined &&
                                              !val?.isNa &&
                                              isChecked && (
                                                <>
                                                  <FontAwesomeIcon
                                                    className={
                                                      val?.change < 0
                                                        ? `${classes["down-icon"]} text-danger me-1`
                                                        : `${classes["up-icon"]} text-success me-1`
                                                    }
                                                    icon={
                                                      val?.change < 0
                                                        ? faSortDown
                                                        : faSortUp
                                                    }
                                                  />
                                                  <span
                                                    className={classes.delta}
                                                  >
                                                    {val?.change > 0
                                                      ? `${Number(
                                                          val?.change
                                                        ).toFixed(0)}`
                                                      : Number(
                                                          Math.abs(val?.change)
                                                        ).toFixed(0)}
                                                  </span>
                                                </>
                                              )}
                                          </div>
                                        </CustomTooltip>
                                      </td>
                                    );
                                  })}
                              </tr>

                              {/* Subcategories */}
                              {isExpanded &&
                                table.rows
                                  .filter(
                                    (subRow) =>
                                      subRow.values?.[0]?.parentCategory ===
                                      row.attribute
                                  )
                                  .map((subRow, subIdx) => (
                                    <tr key={`sub-${subIdx}`}>
                                      <td
                                        valign="middle"
                                        style={{
                                          width: "150px",
                                          textAlign: "left",
                                          paddingLeft: "42px",
                                        }}
                                      >
                                        {titleMapping?.[subRow.attribute] ||
                                          subRow.attribute}
                                      </td>
                                      {getFilteredValues(subRow.values)
                                        // .slice(startIndex, startIndex + pageSize)
                                        .map((val, valIdx) => {
                                          const isColorApply =
                                            table?.title == "RBI" ||
                                            table?.title == "Rank" ||
                                            table?.title == "Favorability";
                                          let color = "#dc3545";
                                          if (
                                            Number(val?.value).toFixed(0) >= 7
                                          )
                                            color = "#2196F3";
                                          if (
                                            Number(val?.value).toFixed(0) >= 9
                                          )
                                            color = "#198754";
                                          return (
                                            <td
                                              key={valIdx}
                                              style={{
                                                width: "100px",
                                                textAlign: "center",
                                                // cursor: "pointer",
                                              }}
                                            >
                                              <CustomTooltip
                                                explaination={val?.explanation}
                                              >
                                                <div
                                                  // title={val?.explaination}
                                                  className="d-flex align-items-center justify-content-center flex-row"
                                                >
                                                  <span
                                                    onClick={() => {
                                                      handleIndexScoreMetaModal(
                                                        isColorApply,
                                                        visibleHeaders?.[
                                                          valIdx
                                                        ],
                                                        row.attribute
                                                      );
                                                    }}
                                                    onMouseEnter={(e) => {
                                                      if (
                                                        row.attribute !=
                                                        "Brand Index Score"
                                                      ) {
                                                        e.target.style.color =
                                                          "#2196F3";
                                                      }
                                                    }} // Change color on hover
                                                    onMouseLeave={(e) =>
                                                      (e.target.style.color =
                                                        !isColorApply &&
                                                        !val?.isNa
                                                          ? color
                                                          : "grey")
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                      color:
                                                        !isColorApply &&
                                                        !val?.isNa
                                                          ? color
                                                          : "grey",
                                                    }}
                                                    className={`h5 mb-0 me-1
                              } ${classes["main-value"]}`}
                                                  >
                                                    {val?.value != null &&
                                                    !isNaN(Number(val.value)) &&
                                                    (row.attribute == "Rank" ||
                                                      val.value != 0)
                                                      ? Number(
                                                          val.value
                                                        ).toFixed(0)
                                                      : "NA"}
                                                  </span>
                                                  {val?.change != undefined &&
                                                    !val?.isNa &&
                                                    isChecked && (
                                                      <>
                                                        <FontAwesomeIcon
                                                          className={
                                                            val?.change < 0
                                                              ? `${classes["down-icon"]} text-danger me-1`
                                                              : `${classes["up-icon"]} text-success me-1`
                                                          }
                                                          icon={
                                                            val?.change < 0
                                                              ? faSortDown
                                                              : faSortUp
                                                          }
                                                        />
                                                        <span
                                                          className={
                                                            classes.delta
                                                          }
                                                        >
                                                          {val?.change > 0
                                                            ? `${Number(
                                                                val?.change
                                                              ).toFixed(0)}`
                                                            : Number(
                                                                Math.abs(
                                                                  val?.change
                                                                )
                                                              ).toFixed(0)}
                                                        </span>
                                                      </>
                                                    )}
                                                </div>
                                              </CustomTooltip>
                                            </td>
                                          );
                                        })}
                                    </tr>
                                  ))}
                            </React.Fragment>
                          );
                        }

                        return null; // Skip subcategory rows initially for Product dimensions
                      }

                      // Default behavior for other rows
                      return (
                        <tr key={idx}>
                          <td
                            valign="middle"
                            style={{
                              width: "150px",
                              textAlign: "left",
                              paddingLeft: row?.values?.[0]?.parentCategory
                                ? "25px"
                                : "15px",
                            }}
                          >
                            <Tooltip
                              title={
                                metricExplainations[
                                  titleMapping?.[row.attribute] || row.attribute
                                ]
                              }
                            >
                              {titleMapping?.[row.attribute] || row.attribute}
                            </Tooltip>
                          </td>

                          {getFilteredValues(row.values)
                            // .slice(startIndex, startIndex + pageSize)
                            .map((val, valIdx) => {
                              const isColorApply =
                                table?.title == "RBI" ||
                                table?.title == "Rank" ||
                                table?.title == "Favorability";
                              let color = "#dc3545";
                              if (Number(val?.value).toFixed(0) >= 7)
                                color = "#2196F3";
                              if (Number(val?.value).toFixed(0) >= 9)
                                color = "#198754";
                              return (
                                <td
                                  key={valIdx}
                                  style={{
                                    width: "100px",
                                    textAlign: "center",
                                    // cursor: "pointer",
                                  }}
                                >
                                  <CustomTooltip
                                    explaination={val?.explanation}
                                  >
                                    <div
                                      // title={val?.explaination}
                                      className="d-flex align-items-center justify-content-center flex-row"
                                    >
                                      <span
                                        onClick={() => {
                                          handleIndexScoreMetaModal(
                                            isColorApply,
                                            visibleHeaders?.[valIdx],
                                            row.attribute
                                          );
                                        }}
                                        onMouseEnter={(e) => {
                                          if (
                                            row.attribute != "Brand Index Score"
                                          ) {
                                            e.target.style.color = "#2196F3";
                                          }
                                        }} // Change color on hover
                                        onMouseLeave={(e) =>
                                          (e.target.style.color =
                                            !isColorApply && !val?.isNa
                                              ? color
                                              : "grey")
                                        }
                                        style={{
                                          cursor: "pointer",
                                          color:
                                            !isColorApply && !val?.isNa
                                              ? color
                                              : "grey",
                                        }}
                                        className={`h5 mb-0 me-1
                              } ${classes["main-value"]}`}
                                      >
                                        {val?.value != null &&
                                        !isNaN(Number(val.value)) &&
                                        (row.attribute == "Rank" ||
                                          val.value != 0)
                                          ? Number(val.value).toFixed(0)
                                          : "NA"}
                                      </span>
                                      {val?.change != undefined &&
                                        !val?.isNa &&
                                        isChecked && (
                                          <>
                                            <FontAwesomeIcon
                                              className={
                                                val?.change < 0
                                                  ? `${classes["down-icon"]} text-danger me-1`
                                                  : `${classes["up-icon"]} text-success me-1`
                                              }
                                              icon={
                                                val?.change < 0
                                                  ? faSortDown
                                                  : faSortUp
                                              }
                                            />
                                            <span className={classes.delta}>
                                              {val?.change > 0
                                                ? `${Number(
                                                    val?.change
                                                  ).toFixed(0)}`
                                                : Number(
                                                    Math.abs(val?.change)
                                                  ).toFixed(0)}
                                            </span>
                                          </>
                                        )}
                                    </div>
                                  </CustomTooltip>
                                </td>
                              );
                            })}
                        </tr>
                      );
                    })
                  ) : (
                    <EmptyView message="Data Not Available" />
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {chartDataArray?.map((data, index) => {
        return (
          <div className="col-md-12 mt-4">
            <div className="card p-3 custCard">
              <div className="mb-3 d-flex justify-content-between align-items-center"></div>
              <div>
                <ReMultibarChart
                  index={index}
                  data={data}
                  isCompetitor={true}
                />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default CompComparisonComponent;
