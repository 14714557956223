import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { useResolvedPath } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputSelectionComponent from "./Component/inputSelection";
import DateRangePicker from "./Component/DateRangePicker";
import useQuery from "../../Component/devDashboard2/common/useQuery";
import { AuthContext } from "../../context/auth-context";
import {
  fetchCustomerLlms,
  getMarkets,
  getGeographies,
  getLlms,
  getCompMetricsForBrand,
  getBrandAttributes,
  getProductAttributes,
  getCompMetricsForProduct,
  getAllBrands,
  getCustomerEfrLlm,
  getSourcesWithAlias,
} from "../../services/apiService";
import { fetchBrands } from "../../redux/actions/brandsActions";
import { fetchBrandProducts } from "../../redux/actions/brandProductsActions";
import CompComparisonComponent from "./Component/CompComparisonComponent";
import MultiComparisonComponent from "./Component/MultiComparisonComponent";
import {
  splitByDayAndMonth,
  splitDataObjByDayAndMonth,
  combineData,
  transformSourceAliasData,
} from "../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import SourceComponent from "./Component/SourceComponent";
import EmptyView from "../../Component/EmptyView/EmptyView";
import BrandProductOverview from "./Component/BrandProductOverview";
import Actions from "./Component/ActionComponent/Actions";
import Filter from "./Component/Filter/Filter";
import FilterList from "./Component/Filter/FilterList";
import AddFilter from "./Component/Filter/AddFilter";
import { Typography } from "@mui/material";
import {
  calculateAverageData,
  calculateAverageDataStructure,
  getBrandProductOverviewData,
  getComparisonDashboardData,
  getCompBrandIndexScoreAvgObj,
  getCompetitorMetricData,
  handleInputValidation,
  indexScoreChartData,
  processSourceData,
  transformDataForAttributesSources,
} from "../../utils/monitoring-helpers";

const Dashboard = (props) => {
  const { pathname } = useResolvedPath();
  const selectedOptionShow = pathname.includes("brand-monitoring")
    ? "Company/Brand"
    : "Product";

  const [activeTab, setActiveTab] = useState("overview");
  const [value, setValue] = useState(0);
  const [inputValidation, setInputValidation] = useState({
    selectedProductItems: false,
    selectedBrandItems: false,
    selectedPersona: false,
    selectedGeographyItems: false,
    selectedMarketItems: false,
    selectedLlmItems: false,
  });
  const [showData, setShowData] = useState(false);
  const [isDone, setIsDone] = useState(true);
  const [selectedLlmItems, setSelectedLlmItems] = useState([]);
  const [selectedMarketItems, setSelectedMarketItems] = useState([]);
  const [selectedGeographyItems, setSelectedGeographyItems] = useState([]);
  const [dbLlms, setDbLlms] = useState([]);
  const [selectedFrequencyItems, setSelectedFrequencyItems] = useState([
    "Daily",
  ]);

  const [brandItems, setBrandItems] = useState([]);
  const [productItems, setProductItems] = useState([]);
  const [selectedBrandItems, setSelectedBrandItems] = useState([]);
  const [selectedProductItems, setSelectedProductItems] = useState([]);

  const [personas, setPersonas] = useState([]);
  const [selectedPersona, setSelectedPersona] = useState([]);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [compCoreAttributes, setCompCoreAttributes] = useState();
  const [pastCompCoreAttributes, setPastCompCoreAttributes] = useState();
  const [compCategoryDimensions, setCompCategoryDimensions] = useState();
  const [pastCompCategoryDimensions, setCompPastCategoryDimensions] =
    useState();
  const [avgBrandIndex, setAvgBrandIndex] = useState();
  const [avgPastBrandIndex, setAvgPastBrandIndex] = useState();
  const [llmAttributes, setLlmAttributes] = useState();
  const [llmNegativeAttributes, setLlmNegativeAttributes] = useState();
  const [llmAttributesSources, setLlmAttributesSources] = useState();
  const [llmNegativeAttributesSources, setLlmNegativeAttributesSources] =
    useState();

  const [llmCompBrandIndex, setLlmCompBrandIndex] = useState([]);
  const [llmCompBrandReach, setLlmCompBrandReach] = useState([]);
  const [llmCompBrandFav, setLlmCompBrandFav] = useState([]);
  const [pastLlmCompBrandIndex, setPastLlmCompBrandIndex] = useState();

  const [indexScoreMetaData, setIndexScoreMetaData] = useState();
  const [compIndexScoreMetaData, setCompIndexScoreMetaData] = useState();
  const [categoryDimensionSourceData, setCategoryDimensionSourceData] =
    useState();
  const [coreAttSourceData, setCoreAttSourceData] = useState();
  const [reachSourceData, setReachSourceData] = useState();
  const [sentimentSourceData, setSentimentSourceData] = useState();
  const [
    categoryDimensionRatingSourceData,
    setCategoryDimensionRatingSourceData,
  ] = useState();
  const [compPositiveAttributes, setCompPositiveAttributes] = useState();
  const [compNegativeAttributes, setCompNegativeAttributes] = useState();

  const [customerLlm, setCustomerLlm] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [geography, setGeography] = useState([]);

  const [brandCoreAttributesScore, setBrandCoreAttributesScore] = useState();
  const [pastBrandCoreAttributesScore, setPastBrandCoreAttributesScore] =
    useState();
  const [brandCategoryDimScore, setBrandCategoryDimScore] = useState();
  const [pastBrandCategoryDimScore, setPastBrandCategoryDimScore] = useState();

  const [indexScoreDataForChart, setIndexScoreDataForChart] = useState([]);

  const [positiveAttGraphData, setPositiveAttGraphData] = useState([]);
  const [negativeAttGraphData, setNegativeAttGraphData] = useState([]);
  const [pastPositiveAttGraphData, setPastPositiveAttGraphData] = useState([]);
  const [pastNegativeAttGraphData, setPastNegativeAttGraphData] = useState([]);

  const [brandDomains, setBrandDomains] = useState();
  const [brandInfoJson, setBrandInfoJson] = useState();
  const [brandProductDomains, setBrandProductDomains] = useState();
  const [brandProductInfoJson, setBrandProductInfoJson] = useState();
  const [llmDomains, setLlmDomains] = useState();
  const [geoLogo, setGeoLogo] = useState();

  const [brandCategory, setBrandCategory] = useState();
  const [productCategory, setProductCategory] = useState();

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterModalType, setFilterModalType] = useState("");
  const [isFilterQuery, setIsFilterQuery] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [initialConfig, setInitialConfig] = useState({});
  const [sourceFilter, setSourceFilter] = useState({
    value: "LLM",
    label: "Compare by LLM",
  });

  const [productTaxonomyData, setProductTaxonomyData] = useState([]);

  const [sourceAlias, setSourceAlias] = useState();
  const [sourceFullUrls, setSourceFullUrls] = useState();

  const query = useQuery();
  let selectedBrandId = query.get("brandId");
  let productId = query.get("brandProductId");
  const selectedPersonaId = query.get("personaId");
  const selectedLlmId = query.get("llmId");
  const selectedMarketId = query.get("marketId");
  const selectedGeoId = query.get("geotId");
  const frequency = query.get("dateRange");

  const [selectedSubFrequencyItems, setSelectedSubFrequencyItems] = useState(
    frequency || "Current"
  );

  const dispatch = useDispatch();
  const brandProducts = useSelector((state) => state.brandProducts);
  const brands = useSelector((state) => state.brands);

  const { user } = useContext(AuthContext);

  const customerId = user?.customer_id || 0;

  const handleFilterChange = (option) => {
    if (option.value === "LLM") {
      const llms = dbLlms.map((obj) => obj?.name);
      setSelectedLlmItems(llms);
      setSelectedPersona(["General Persona"]);
    } else {
      const personasToSelect = personas.map((obj) => obj?.persona?.name);
      setSelectedPersona(personasToSelect);
      setSelectedLlmItems(["gpt-4o"]);
    }
    setSourceFilter(option);
  };

  useEffect(() => {
    if (
      selectedPersona.length &&
      (selectedBrandItems.length || selectedProductItems.length) &&
      !isFirstTime &&
      activeTab !== "actions"
    ) {
      handleClickShow();
      setIsFilterQuery(false);
    }
  }, [
    selectedPersona,
    selectedGeographyItems,
    selectedMarketItems,
    selectedLlmItems,
    selectedSubFrequencyItems,
  ]);

  const tabMapping = {
    overview: 0,
    competition: 1,
    llm: 2,
    market: 3,
    personas: 4,
    sources: 5,
  };

  const handleTabClick = (tab) => {
    if (tab === "llm" || tab === "sources") {
      const llms = dbLlms
        // .filter((obj) => obj?.name !== "llm-web")
        .map((obj) => obj?.name);
      setSelectedLlmItems(llms);
      setSourceFilter({
        value: "LLM",
        label: "Compare by LLM",
      });
    } else {
      setSelectedLlmItems(["gpt-4o"]);
    }
    if (tab === "personas") {
      const personasToSelect = personas.map((obj) => obj?.persona?.name);
      setSelectedPersona(personasToSelect);
    } else {
      setSelectedPersona(["General Persona"]);
    }

    if (tab === "market") {
      const marketsToSelect = markets.map((obj) => obj?.name);
      setSelectedMarketItems(marketsToSelect);
    } else {
      setSelectedMarketItems(["General Market"]);
    }
    setShowData(false);
    setActiveTab(tab);
    setValue(tabMapping[tab]);
  };

  useEffect(() => {
    if (selectedOptionShow === "Product") {
      if (
        brandProducts?.data?.data &&
        brandProducts?.data?.data.length !== 0 &&
        dbLlms &&
        dbLlms.length !== 0 &&
        customerLlm &&
        customerLlm.length !== 0 &&
        markets &&
        markets.length !== 0
      ) {
        const activeData = brandProducts?.data?.data.filter(
          (obj) => obj.is_active
        );

        const activeDataProductId = activeData?.[0]?.id;

        // const activeData = brandProducts?.data?.data;

        const selectedData = brandProducts?.data?.data.filter(
          (obj) => obj.id === (Number(productId) || activeDataProductId)
        );

        setSelectedProductItems([selectedData[0]?.product?.name]);
        setSelectedPersona([
          // selectedData[0]?.custProduct[0]?.persona?.name,
          "General Persona",
        ]);
        setSelectedLlmItems(["gpt-4o"]);
        setSelectedMarketItems(["General Market"]);
        setSelectedGeographyItems(["USA"]);

        setProductItems(activeData);

        setPersonas(selectedData[0]?.custProduct);
        const brandProductId = selectedData[0]?.id || null;

        const globalLlmId =
          selectedLlmId ||
          dbLlms.filter((item) => item?.name === "gpt-4o")[0]?.id;
        const marketId =
          selectedMarketId ||
          markets.filter((item) => item?.name === "General Market")[0]?.id;
        const geoId =
          selectedGeoId ||
          geography.filter((item) => item?.name === "USA")[0]?.id;
        const llmId = customerLlm.filter(
          (item) =>
            item?.llm_id === globalLlmId &&
            item?.market_id === marketId &&
            item?.geography_id === geoId
        )[0]?.id;
        // const personaId = [
        //   selectedData[0]?.custProduct[0]?.persona?.id,
        // ];
        const personaId = [13];
        const productName = selectedData[0]?.product?.name;
        handleDashboardInitialData(
          undefined,
          brandProductId,
          llmId,
          personaId,
          undefined,
          productName
        );
      }
    }
  }, [brandProducts, dbLlms]);

  useEffect(() => {
    if (selectedOptionShow === "Company/Brand") {
      if (
        brands?.data?.data &&
        brands?.data?.data.length !== 0 &&
        dbLlms &&
        dbLlms.length !== 0 &&
        customerLlm &&
        customerLlm.length !== 0 &&
        markets &&
        markets.length !== 0
      ) {
        const activeData = brands?.data?.data?.filter((obj) => obj?.is_active);
        const activeDataBrandId = activeData?.[0]?.id;

        // const activeData = brands?.data?.data;
        const selectedData = brands?.data?.data?.filter(
          (obj) => obj.id === (Number(selectedBrandId) || activeDataBrandId)
        );
        // let personaIndex = selectedData[0]?.persona.findIndex(
        //   (obj) => obj?.id === selectedPersonaId
        // );

        // personaIndex = personaIndex > 0 ? personaIndex : 0;
        let llmFromQuery = dbLlms.filter((obj) => obj?.id === selectedLlmId);
        llmFromQuery =
          llmFromQuery && llmFromQuery.length !== 0
            ? llmFromQuery[0]?.name
            : "gpt-4o";
        let marketFromQuery = markets.filter(
          (obj) => obj?.id === selectedMarketId
        );
        marketFromQuery =
          marketFromQuery && marketFromQuery.length !== 0
            ? marketFromQuery[0]?.name
            : "General Market";

        let geoFromQuery = geography.filter((obj) => obj?.id === selectedGeoId);
        geoFromQuery =
          geoFromQuery && geoFromQuery.length !== 0
            ? geoFromQuery[0]?.name
            : "USA";

        setSelectedBrandItems([selectedData[0]?.brand?.name]);
        setSelectedPersona([
          // selectedData[0]?.persona[personaIndex]?.persona?.name,
          "General Persona",
        ]);

        setSelectedLlmItems([llmFromQuery]);
        setSelectedMarketItems([marketFromQuery]);
        setSelectedGeographyItems([geoFromQuery]);
        // setProductItems(brandProducts?.data?.data);
        setBrandItems(activeData);

        setPersonas(selectedData[0]?.persona);
        const brandId = selectedData[0]?.id || null;

        const globalLlmId =
          selectedLlmId ||
          dbLlms.filter((item) => item?.name === "gpt-4o")[0]?.id;
        const marketId =
          selectedMarketId ||
          markets.filter((item) => item?.name === "General Market")[0]?.id;
        const geoId =
          selectedGeoId ||
          geography.filter((item) => item?.name === "USA")[0]?.id;
        const llmId = customerLlm.filter(
          (item) =>
            item?.llm_id === globalLlmId &&
            item?.market_id === marketId &&
            item?.geography_id === geoId
        )[0]?.id;
        // const personaId = [selectedData[0]?.persona[personaIndex]?.persona?.id]
        const personaId = [13];
        const brandName = selectedData[0]?.brand?.name;
        handleDashboardInitialData(
          brandId,
          undefined,
          llmId,
          personaId,
          brandName,
          undefined
        );
      }
    }
  }, [brands, dbLlms]);

  useEffect(() => {
    setInputValidation({
      selectedProductItems: !!selectedProductItems.length,
      selectedBrandItems: !!selectedBrandItems.length,
      selectedPersona: !!selectedPersona.length,
      selectedGeographyItems: !!selectedGeographyItems.length,
      selectedMarketItems: !!selectedMarketItems.length,
      selectedLlmItems: selectedLlmItems.some((v) => v !== undefined),
    });
  }, [
    selectedProductItems,
    selectedBrandItems,
    selectedPersona,
    selectedGeographyItems,
    selectedMarketItems,
    selectedLlmItems,
  ]);

  useEffect(() => {
    async function fetchData() {
      const allBrands = await getAllBrands();
      const {
        llms: allLlms,
        brand: brandInfo,
        brandProducts: brandProductInfo,
        geographies: geoCodes,
      } = allBrands?.data || {};

      const createDomainMap = (data, key = "domain_url") =>
        data?.reduce((acc, item) => {
          acc[item.name] = item[key];
          return acc;
        }, {});

      const createJsonMap = (data, key = "resp_json") =>
        data?.reduce((acc, item) => {
          acc[item.name] = item[key];
          return acc;
        }, {});
      setBrandInfoJson(createJsonMap(brandInfo));
      setBrandDomains(createDomainMap(brandInfo));
      setBrandProductInfoJson(createJsonMap(brandProductInfo));
      setBrandProductDomains(createDomainMap(brandProductInfo));
      setLlmDomains(createDomainMap(allLlms));
      setGeoLogo(createDomainMap(geoCodes, "country_code"));
      const selectedBrandCustomerId =
        query.get("brandCustomerId") || customerId;
      const customerSpecificLlms = await fetchCustomerLlms([
        Number(selectedBrandCustomerId),
      ]);

      setCustomerLlm(customerSpecificLlms?.data);

      // set the customer geographies
      let geo = await getGeographies();
      const geographyIds = [
        ...new Set(customerSpecificLlms?.data.map((obj) => obj?.geography_id)),
      ];
      geo = geo?.data.filter((obj) => geographyIds.includes(obj?.id));
      setGeography(geo);

      // set customer geography markets

      let market = await getMarkets();
      const filtercustomeLlm = customerSpecificLlms?.data.filter(
        (obj) => obj?.geography_id === 1
      );
      const marketIds = [
        ...new Set(filtercustomeLlm.map((obj) => obj?.market_id)),
      ];
      market = market?.data.filter((obj) => marketIds.includes(obj?.id));
      setMarkets(market);

      if (brandProducts?.data && brandProducts?.data.length === 0) {
        dispatch(fetchBrandProducts(customerId));
      }

      if (brands?.data && brands?.data.length === 0) {
        dispatch(fetchBrands(customerId));
      }

      const llms = await getLlms();
      setDbLlms(llms?.data);
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchCustomerLlmsData() {
      if (
        !isFirstTime &&
        !isFilterQuery &&
        brandProducts?.data?.data &&
        brandProducts?.data?.data.length !== 0 &&
        selectedProductItems &&
        selectedProductItems.length > 0
      ) {
        const selectedBrandProductCustomerId = brandProducts?.data?.data
          .filter(
            (data) =>
              selectedProductItems.includes(data?.product?.name) &&
              data?.is_active
          )
          .map((obj) => obj?.customer?.id);

        if (selectedBrandProductCustomerId) {
          const customerSpecificLlms = await fetchCustomerLlms(
            selectedBrandProductCustomerId
          );
          // set the customer geographies
          let geo = await getGeographies();
          const geographyIds = [
            ...new Set(
              customerSpecificLlms?.data.map((obj) => obj?.geography_id)
            ),
          ];
          geo = geo?.data.filter((obj) => geographyIds.includes(obj?.id));
          setGeography(geo);

          // set customer geography markets

          let market = await getMarkets();
          const filtercustomeLlm = customerSpecificLlms?.data.filter(
            (obj) => obj?.geography_id === 1
          );
          const marketIds = [
            ...new Set(filtercustomeLlm.map((obj) => obj?.market_id)),
          ];
          market = market?.data.filter((obj) => marketIds.includes(obj?.id));
          setMarkets(market);
          setCustomerLlm(customerSpecificLlms?.data);
          setSelectedGeographyItems(["USA"]);
          setSelectedMarketItems(["General Market"]);
          setSelectedPersona(["General Persona"]);
        }
      }
    }

    fetchCustomerLlmsData();
  }, [selectedProductItems, brandProducts]);

  useEffect(() => {
    async function fetchCustomerLlmsData() {
      if (
        !isFirstTime &&
        !isFilterQuery &&
        brands?.data?.data &&
        brands?.data?.data.length !== 0 &&
        selectedBrandItems &&
        selectedBrandItems.length > 0
      ) {
        const selectedBrandCustomerId = brands?.data?.data
          .filter(
            (data) =>
              selectedBrandItems.includes(data?.brand?.name) && data?.is_active
          )
          .map((obj) => obj?.customer?.id);

        if (selectedBrandCustomerId) {
          const customerSpecificLlms = await fetchCustomerLlms(
            selectedBrandCustomerId
          );

          // set the customer geographies
          let geo = await getGeographies();
          const geographyIds = [
            ...new Set(
              customerSpecificLlms?.data.map((obj) => obj?.geography_id)
            ),
          ];
          geo = geo?.data.filter((obj) => geographyIds.includes(obj?.id));
          setGeography(geo);

          // set customer geography markets

          let market = await getMarkets();
          const filtercustomeLlm = customerSpecificLlms?.data.filter(
            (obj) => obj?.geography_id === 1
          );
          const marketIds = [
            ...new Set(filtercustomeLlm.map((obj) => obj?.market_id)),
          ];
          market = market?.data.filter((obj) => marketIds.includes(obj?.id));
          setMarkets(market);
          setCustomerLlm(customerSpecificLlms?.data);
          setSelectedGeographyItems(["USA"]);
          setSelectedMarketItems(["General Market"]);
          setSelectedPersona(["General Persona"]);
        }
      }
    }

    fetchCustomerLlmsData();
  }, [selectedBrandItems, brands]);

  const handleLlmCheckChange = (selected) => {
    const selectedValues = selected?.map((option) => option?.name) || [];
    const isSpecialCase =
      value === 2 || (value === 5 && sourceFilter?.value === "LLM");

    setSelectedLlmItems(
      isSpecialCase ? selectedValues : [selectedValues.at(-1)]
    );
  };

  const handlesubFrequencyCheckChange = (key) => {
    setSelectedSubFrequencyItems(key);
  };

  const handleMarketCheckChange = (selected) => {
    let selectedValues = selected?.map((option) => option?.name);
    if (value !== 3) {
      let lastIndex = selectedValues.length - 1;
      selectedValues = selectedValues?.filter(
        (item, index) => index === lastIndex
      );
    }

    setSelectedMarketItems(selectedValues || []);
  };

  const handleGeographyCheckChange = async (selected) => {
    let selectedValues = selected?.map((option) => option?.name);
    if (value !== 3) {
      let lastIndex = selectedValues.length - 1;
      selectedValues = selectedValues?.filter(
        (item, index) => index === lastIndex
      );
    }
    const latestValue = selectedValues[selectedValues.length - 1];
    setSelectedGeographyItems(selectedValues || []);

    let geoId = geography.filter((obj) => obj?.name === latestValue)[0]?.id;
    let market = await getMarkets();
    const filtercustomeLlm = customerLlm.filter(
      (obj) => obj?.geography_id === geoId
    );

    if (selectedValues && selectedValues.length === 1) {
      // set customer geography markets

      const marketIds = [
        ...new Set(filtercustomeLlm.map((obj) => obj?.market_id)),
      ];
      market = market?.data.filter((obj) => marketIds.includes(obj?.id));
      setMarkets(market);
    } else if (selectedValues && selectedValues.length > 1) {
      // set customer geography markets

      market = market?.data.filter((obj) => obj?.name === "General Market");
      setMarkets(market);
    }
  };

  const handleProductCheckChange = (selected) => {
    let selectedValues = selected?.map((option) => option?.product?.name);
    let lastIndex = selectedValues.length - 1;
    selectedValues = selectedValues?.filter(
      (item, index) => index === lastIndex
    );
    const latestValue = selectedValues[0];
    // setSelectedPersona(["General Persona"]);
    setShowData(false);
    setIsDone(false);
    setSelectedProductItems(selectedValues || []);
    setSelectedPersona([]);
    const selectedProduct = brandProducts?.data?.data.filter(
      (data) => data?.product?.name === latestValue && data?.is_active
    );

    const persona = selectedProduct[0]?.custProduct;
    setPersonas(persona);
  };

  const handleBrandCheckChange = (selected) => {
    let selectedValues = selected?.map((option) => option?.brand?.name);
    let lastIndex = selectedValues.length - 1;
    selectedValues = selectedValues?.filter(
      (item, index) => index === lastIndex
    );
    const latestValue = selectedValues[0];
    setShowData(false);
    setIsDone(false);
    setSelectedBrandItems(selectedValues || []);
    const selectedBrand = brands?.data?.data.filter(
      (data) => data?.brand?.name === latestValue && data?.is_active
    );
    const persona = selectedBrand[0]?.persona;
    setPersonas(persona);
  };

  const handlePersonaCheckChange = (selected) => {
    const selectedValues = selected?.map((option) => option?.persona?.name);
    if (value === 4 || sourceFilter?.value === "PERSONA") {
      setSelectedPersona(selectedValues || []);
    } else {
      let lastIndex = selectedValues.length - 1;
      const lastSelectedValue = selectedValues?.filter(
        (item, index) => index === lastIndex
      );
      setSelectedPersona(lastSelectedValue || []);
    }
  };

  const getInputIds = () => {
    const brandId =
      brandItems.filter(
        (item) => item?.brand?.name === selectedBrandItems[0]
      )[0]?.id || null;

    const productId =
      productItems.filter(
        (item) => item?.product?.name === selectedProductItems[0]
      )[0]?.id || null;

    const brandIds = brandItems
      .filter((item) => selectedBrandItems.includes(item?.brand?.name))
      .map((obj) => obj?.id);

    const productIds = productItems
      .filter((item) => selectedProductItems.includes(item?.product?.name))
      .map((obj) => obj?.id);

    const globalLlmId = dbLlms.filter(
      (item) => item?.name === selectedLlmItems[0]
    )[0]?.id;
    const marketId = markets.filter(
      (item) => item?.name === selectedMarketItems[0]
    )[0]?.id;

    const geoId = geography.filter(
      (item) => item?.name === selectedGeographyItems[0]
    )[0]?.id;

    const llmId = customerLlm.filter(
      (item) =>
        item?.llm_id === globalLlmId &&
        item?.market_id === marketId &&
        item?.geography_id === geoId
    )[0]?.id;
    const personaId = personas
      .filter((item) => selectedPersona.includes(item?.persona?.name))
      .map((obj) => obj?.persona_id);

    return {
      brandId,
      brandIds,
      productId,
      productIds,
      globalLlmId,
      llmId,
      personaId,
    };
  };

  const handleDashboardInitialData = async (
    brandId,
    productId,
    llmId,
    personaId,
    brandName,
    productName
  ) => {
    await getCompDashboardDataFromDB(
      brandId,
      productId,
      llmId,
      personaId,
      true,
      brandName,
      productName
    );
    setIsFirstTime(false);
  };

  const handleClickShow = async () => {
    const error = handleInputValidation(
      selectedOptionShow,
      selectedBrandItems,
      selectedProductItems,
      selectedPersona,
      selectedFrequencyItems,
      value,
      selectedSubFrequencyItems,
      selectedLlmItems,
      selectedGeographyItems,
      selectedMarketItems
    );

    if (error) {
      toast.error(error, {
        autoClose: 700,
      });
      return;
    }
    const { brandId, brandIds, productId, productIds, llmId, personaId } =
      getInputIds();
    if (value === 0) {
      await getCompDashboardDataFromDB(
        brandId,
        productId,
        llmId,
        personaId,
        false,
        undefined,
        undefined,
        undefined,
        brandIds,
        productIds
      );
    } else if (value === 1) {
      await getCompDashboardDataFromDB(
        brandId,
        productId,
        llmId,
        personaId,
        false
      );
    } else {
      await getLlmComparisonDashboardDataFromDB(
        brandId,
        productId,
        llmId,
        personaId,
        false
      );
    }
  };

  const getCompetitorIndexScores = (
    result,
    brandName,
    productName,
    realPastDate
  ) => {
    if (result?.brandIndexcumulativeData) {
      const splittedData = splitDataObjByDayAndMonth(
        realPastDate?.day,
        realPastDate?.month,
        realPastDate?.year,
        result?.brandIndexcumulativeData,
        selectedSubFrequencyItems === "Current"
      );

      const brandIndexChartArray = indexScoreChartData(
        splittedData?.filteredData,
        "avg_brand_index_score",
        brandName,
        productName,
        selectedOptionShow,
        selectedProductItems,
        selectedBrandItems
      );

      setIndexScoreDataForChart(brandIndexChartArray);

      let currentAvgData = splittedData?.filteredData;
      let pastAvgData = splittedData?.remainingData;

      currentAvgData = getCompBrandIndexScoreAvgObj(
        currentAvgData,
        brandName,
        productName,
        selectedOptionShow,
        selectedBrandItems,
        selectedProductItems
      );
      pastAvgData = getCompBrandIndexScoreAvgObj(
        pastAvgData,
        brandName,
        productName,
        selectedOptionShow,
        selectedBrandItems,
        selectedProductItems
      );
      setAvgBrandIndex(currentAvgData);
      setAvgPastBrandIndex(pastAvgData);
    }
  };

  const getIndexScoreMetaData = async (result, processJsonResponse = false) => {
    let indexScoreMeta = result?.indeScoreMetaData || [];
    let compIndexScoreMeta = result?.compIndexScoreMetaData || [];

    if (processJsonResponse) {
      if (indexScoreMeta.length) {
        indexScoreMeta = indexScoreMeta?.[0]?.brand_index_json_response;
        setIndexScoreMetaData(indexScoreMeta || {});
      }

      if (compIndexScoreMeta.length) {
        compIndexScoreMeta = compIndexScoreMeta.map((obj) => ({
          extras: obj?.brand_index_json_response?.extras,
          name: obj?.name,
        }));
        setCompIndexScoreMetaData(compIndexScoreMeta || []);
      }

      return; // Early return if processJsonResponse is true
    }

    const currentBrandProduct =
      selectedOptionShow === "Product"
        ? selectedProductItems?.[0]
        : selectedBrandItems?.[0];

    const personaQuery =
      selectedOptionShow !== "Product"
        ? "customer_brand_persona"
        : "customer_brand_product_persona";

    // Combine and deduplicate llm_efr_ids
    const uniqueLlmEfrIds = [
      ...new Set([
        ...indexScoreMeta.map((obj) => obj.llm_efr_id),
        ...compIndexScoreMeta.map((obj) => obj.llm_efr_id),
      ]),
    ];

    if (uniqueLlmEfrIds.length === 0) {
      return { indexScoreMeta, compIndexScoreMeta }; // Return early if no IDs
    }

    // Fetch efrData based on unique IDs
    const payload = { efr_ids: uniqueLlmEfrIds };
    const efrData = await getCustomerEfrLlm(payload);

    if (!efrData || efrData.length === 0) {
      return { indexScoreMeta, compIndexScoreMeta }; // Return early if no data
    }

    // Create a lookup map for faster access
    const efrDataMap = efrData.reduce((map, item) => {
      map[item.id] = item;
      return map;
    }, {});

    // Map metadata with fetched data
    const mapWithEfrData = (metaArray) =>
      metaArray.map((obj) => ({
        extras: obj?.brand_index_json_response?.extras,
        name: obj?.name || currentBrandProduct,
        llm: efrDataMap[obj.llm_efr_id]?.customer_llm?.llm?.name,
        persona: efrDataMap[obj.llm_efr_id]?.[personaQuery]?.persona?.name,
        market: efrDataMap[obj.llm_efr_id]?.customer_llm?.market?.name,
      }));

    setIndexScoreMetaData(
      [
        ...mapWithEfrData(indexScoreMeta),
        ...mapWithEfrData(compIndexScoreMeta),
      ] || []
    );
  };

  function getUniqueSourceIds(...arrays) {
    const combinedArray = arrays.flat();
    const uniqueSourceIds = new Set(combinedArray.map((obj) => obj?.source_id));
    return Array.from(uniqueSourceIds);
  }

  const getSourcesInBrandOverview = async (result) => {
    const categoryDimensionSources = result?.llmCategoryDimensionSources;

    const categoryDimensionsSourceData = processSourceData(
      categoryDimensionSources,
      false,
      selectedOptionShow,
      sourceFilter,
      selectedBrandItems,
      selectedProductItems
    );

    let coreAttSources = result?.llmCoreAttributesSource;
    coreAttSources = processSourceData(
      coreAttSources,
      true,
      selectedOptionShow,
      sourceFilter,
      selectedBrandItems,
      selectedProductItems
    );

    let reachSources = result?.llmReachSource;
    reachSources = processSourceData(
      reachSources,
      true,
      selectedOptionShow,
      sourceFilter,
      selectedBrandItems,
      selectedProductItems
    );

    let sentimentSources = result?.llmSentimentSource;
    sentimentSources = processSourceData(
      sentimentSources,
      true,
      selectedOptionShow,
      sourceFilter,
      selectedBrandItems,
      selectedProductItems
    );

    setCategoryDimensionSourceData(
      calculateAverageData(categoryDimensionsSourceData?.sourceResult)?.avg
    );
    setCategoryDimensionRatingSourceData(
      calculateAverageData(categoryDimensionsSourceData?.ratingSourceResult)
        ?.avg
    );
    setCoreAttSourceData(
      calculateAverageData(coreAttSources?.ratingSourceResult)?.avg
    );
    setReachSourceData(
      calculateAverageData(reachSources?.ratingSourceResult)?.avg
    );
    setSentimentSourceData(
      calculateAverageData(sentimentSources?.ratingSourceResult)?.avg
    );

    const sourceIds = getUniqueSourceIds(
      result?.llmCategoryDimensionSources?.llmCategoryDimensionsRatingSource,
      result?.llmCategoryDimensionSources?.llmCategoryDimensionsSource,
      result?.llmCoreAttributesSource,
      result?.llmReachSource,
      result?.llmSentimentSource
    );

    const sources = await getSourcesWithAlias({
      sourceIds,
      sourceAliasIds: result?.finalSourceAliasIds,
    });
    setSourceFullUrls(transformSourceAliasData(sources?.data));
  };

  const getSourceDashboardData = async (result, result2) => {
    const sourceData = transformDataForAttributesSources(
      result?.cumulativeSourcesData,
      sourceFilter,
      selectedBrandItems,
      selectedProductItems
    );

    setLlmAttributesSources(
      calculateAverageData(sourceData?.positiveAttributesStructuredData)?.[
        "avg"
      ]
    );
    setLlmNegativeAttributesSources(
      calculateAverageData(sourceData?.negativeAttributesStructuredData)?.[
        "avg"
      ]
    );

    const categoryDimensionSources = result2?.llmCategoryDimensionSources;

    const categoryDimensionsSourceData = processSourceData(
      categoryDimensionSources,
      false,
      selectedOptionShow,
      sourceFilter,
      selectedBrandItems,
      selectedProductItems
    );

    let coreAttSources = result2?.llmCoreAttributesSource;
    coreAttSources = processSourceData(
      coreAttSources,
      true,
      selectedOptionShow,
      sourceFilter,
      selectedBrandItems,
      selectedProductItems
    );

    let reachSources = result2?.llmReachSource;
    reachSources = processSourceData(
      reachSources,
      true,
      selectedOptionShow,
      sourceFilter,
      selectedBrandItems,
      selectedProductItems
    );

    let sentimentSources = result2?.llmSentimentSource;
    sentimentSources = processSourceData(
      sentimentSources,
      true,
      selectedOptionShow,
      sourceFilter,
      selectedBrandItems,
      selectedProductItems
    );

    const sourceIds = getUniqueSourceIds(
      result?.cumulativeSourcesData,
      result2?.llmCategoryDimensionSources?.llmCategoryDimensionsRatingSource,
      result2?.llmCategoryDimensionSources?.llmCategoryDimensionsSource,
      result2?.llmCoreAttributesSource,
      result2?.llmReachSource,
      result2?.llmSentimentSource
    );
    const sourceAliasIds = Array.from(
      new Set([...result?.attSourceAlias, ...result2?.finalSourceAliasIds])
    );
    const sources = await getSourcesWithAlias({
      sourceIds,
      sourceAliasIds,
    });
    setSourceAlias(transformSourceAliasData(sources?.data));

    setCategoryDimensionSourceData(
      calculateAverageData(categoryDimensionsSourceData?.sourceResult)?.avg
    );
    setCategoryDimensionRatingSourceData(
      calculateAverageData(categoryDimensionsSourceData?.ratingSourceResult)
        ?.avg
    );
    setCoreAttSourceData(
      calculateAverageData(coreAttSources?.ratingSourceResult)?.avg
    );
    setReachSourceData(
      calculateAverageData(reachSources?.ratingSourceResult)?.avg
    );
    setSentimentSourceData(
      calculateAverageData(sentimentSources?.ratingSourceResult)?.avg
    );
  };

  const getLlmComparisonData = async (brandId, productId, personaId, llmId) => {
    const customerLlmIds = getCustomerLlmInputIds();

    let result;
    let result2;

    if (selectedOptionShow === "Company/Brand") {
      const payload = {
        brandId: brandId,
        llmId: llmId,
        llmIds: customerLlmIds,
        personaIds: personaId,
        frequency: selectedFrequencyItems[0],
        timePeriod: selectedSubFrequencyItems,
      };

      result = await getBrandAttributes(payload);
      result2 = await getCompMetricsForBrand(payload);
    } else {
      const payload = {
        productId: productId,
        llmId: llmId,
        llmIds: customerLlmIds,
        personaIds: personaId,
        frequency: selectedFrequencyItems[0],
        timePeriod: selectedSubFrequencyItems,
        categoryType: activeTab,
      };

      result = await getProductAttributes(payload);
      result2 = await getCompMetricsForProduct(payload);
    }

    if (value === 5) {
      await getSourceDashboardData(result, result2);
      return;
    }
    await getIndexScoreMetaData(result2);
    setProductTaxonomyData(result2?.productTaxonomyData);
    getCategoryComparisonDataAndUpdateStates(result, result2);
  };

  const getCategoryComparisonDataAndUpdateStates = (result, result2) => {
    const {
      data,
      coreAttdata,
      pastCoreAttdata,
      categoryDimdata,
      pastCategoryDimdata,
      brandIndexFromFavAndRach,
      pastBrandIndexFromFavAndRach,
      selectedBrandCategory,
      selectedBrandProductCategory,
      currentBrandIndexScore,
      pastBrandIndexScore,
    } = getComparisonDashboardData(
      result,
      result2,
      selectedBrandItems,
      selectedProductItems,
      value,
      selectedSubFrequencyItems,
      selectedOptionShow,
      brands,
      brandProducts
    );

    setLlmAttributes(
      calculateAverageData(data?.positiveAttributesStructuredData)?.["avg"]
    );
    setLlmNegativeAttributes(
      calculateAverageData(data?.negativeAttributesStructuredData)?.["avg"]
    );

    setLlmCompBrandIndex(brandIndexFromFavAndRach);
    setLlmCompBrandFav(
      calculateAverageData(currentBrandIndexScore?.brandFavData)?.["avg"]
    );
    setLlmCompBrandReach(
      calculateAverageData(currentBrandIndexScore?.brandReachData)?.["avg"]
    );

    setPastLlmCompBrandIndex({
      indexScore: pastBrandIndexFromFavAndRach,
      fav: calculateAverageData(pastBrandIndexScore?.brandFavData)?.["avg"],
      rank: calculateAverageData(pastBrandIndexScore?.brandReachData)?.["avg"],
    });

    setCompCoreAttributes(
      calculateAverageDataStructure(coreAttdata?.dateSeriesData)?.["avg"]
    );
    setPastCompCoreAttributes(
      calculateAverageDataStructure(pastCoreAttdata?.dateSeriesData)?.["avg"]
    );

    setCompCategoryDimensions(
      calculateAverageDataStructure(categoryDimdata?.dateSeriesData)?.["avg"]
    );
    setCompPastCategoryDimensions(
      calculateAverageDataStructure(pastCategoryDimdata?.dateSeriesData)?.[
        "avg"
      ]
    );
    setBrandCategory(selectedBrandCategory);
    setProductCategory(selectedBrandProductCategory);
  };

  const getCustomerLlmInputIds = () => {
    const llmIds = dbLlms
      .filter((item) => selectedLlmItems.includes(item?.name))
      .map((obj) => obj?.id);

    const marketIds = markets
      .filter((item) => selectedMarketItems.includes(item?.name))
      .map((obj) => obj?.id);

    const geoIds = geography
      .filter((item) => selectedGeographyItems.includes(item?.name))
      .map((obj) => obj?.id);

    const customerLlmIds = customerLlm
      .filter(
        (item) =>
          llmIds.includes(item?.llm_id) &&
          marketIds.includes(item?.market_id) &&
          geoIds.includes(item?.geography_id)
      )
      .map((obj) => obj?.id);

    return customerLlmIds;
  };

  const getCompetitorComparisonData = async (
    brandId,
    productId,
    personaId,
    llmId,
    isInitial,
    brandName,
    productName,
    categoryName,
    brandIds,
    productIds
  ) => {
    let result;

    const customerLlmIds = getCustomerLlmInputIds();

    if (selectedOptionShow === "Company/Brand") {
      const payload = {
        brandId: brandId,
        llmId: llmId,
        brandIds: brandIds,
        llmIds: customerLlmIds.length === 0 ? [llmId] : customerLlmIds,
        personaIds: personaId,
        frequency: selectedFrequencyItems[0],
        timePeriod: selectedSubFrequencyItems,
      };

      result = await getCompMetricsForBrand(payload);
    } else {
      const payload = {
        productId: productId,
        llmId: llmId,
        llmIds: customerLlmIds.length === 0 ? [llmId] : customerLlmIds,
        personaIds: personaId,
        productIds: productIds,
        frequency: selectedFrequencyItems[0],
        timePeriod: selectedSubFrequencyItems,
      };

      result = await getCompMetricsForProduct(payload);
    }

    setProductTaxonomyData(result?.productTaxonomyData);

    getCompetitorIndexScores(
      result,
      brandName,
      productName,
      result?.realPastDate
    );
    const {
      data,
      attributesData,
      pastCategoryDimData,
      categoryDimdata,
      remainingData,
    } = getCompetitorMetricData(
      result,
      selectedOptionShow,
      selectedBrandItems,
      selectedProductItems,
      brandName,
      productName,
      selectedSubFrequencyItems
    );

    setCompCoreAttributes(calculateAverageDataStructure(data)?.["avg"]);

    setPastCompCoreAttributes(
      calculateAverageDataStructure(remainingData)?.["avg"]
    );

    setCompCategoryDimensions(
      calculateAverageDataStructure(categoryDimdata)?.["avg"]
    );
    setCompPastCategoryDimensions(
      calculateAverageDataStructure(pastCategoryDimData)?.["avg"]
    );

    await getIndexScoreMetaData(result, true);
    // await getSourcesInBrandOverview(result);
    if (value === 0) {
      const {
        data,
        remainingData,
        categoryDimData,
        pastCategoryDimData,
        attDataForGraph,
        pastAttDataForGraph,
      } = getBrandProductOverviewData(
        result,
        selectedOptionShow,
        value,
        selectedSubFrequencyItems,
        selectedProductItems
      );
      setBrandCoreAttributesScore(
        calculateAverageData(data?.structuredData)?.["avg"]
      );

      setPastBrandCoreAttributesScore(
        calculateAverageData(remainingData?.structuredData)?.["avg"]
      );
      setBrandCategoryDimScore(
        calculateAverageData(categoryDimData?.structuredData)?.["avg"]
      );

      setPastBrandCategoryDimScore(
        calculateAverageData(pastCategoryDimData?.structuredData)?.["avg"]
      );

      setPositiveAttGraphData(
        attDataForGraph?.positiveAttResult?.slice(0, 8) || []
      );
      setNegativeAttGraphData(
        attDataForGraph?.negativeAttResult?.slice(0, 8) || []
      );
      setPastPositiveAttGraphData(
        pastAttDataForGraph?.positiveAttResult?.slice(0, 8) || []
      );
      setPastNegativeAttGraphData(
        pastAttDataForGraph?.negativeAttResult?.slice(0, 8) || []
      );
    } else {
      setCompPositiveAttributes(
        calculateAverageData(attributesData?.mergedData)?.["avg"]
      );
      setCompNegativeAttributes(
        calculateAverageData(attributesData?.negativeAttributesMergedData)?.[
          "avg"
        ]
      );
    }

    const selectedBrandCategory = brands?.data?.data?.filter(
      (data) => data?.brand?.name === selectedBrandItems[0] || brandName
    )[0]?.category?.name;

    const selectedBrandProductCategory = brandProducts?.data?.data?.filter(
      (data) => data?.product?.name === selectedProductItems[0] || productName
    )[0]?.customer_brand_product_categories[0]?.category?.name;

    setBrandCategory(selectedBrandCategory);
    setProductCategory(selectedBrandProductCategory);
  };

  const NoDataFound = () => {
    return (
      <div style={{ textAlign: "center", padding: "20px", color: "#555" }}>
        <h3>No Data</h3>
        <p>
          Please fill in the valid input to get monitoring and analysis data.
        </p>
      </div>
    );
  };

  const getLlmComparisonDashboardDataFromDB = async (
    brandId,
    productId,
    llmId,
    personaId,
    isInitial
  ) => {
    setShowData(false);
    setIsDone(false);

    try {
      await getLlmComparisonData(
        brandId,
        productId,
        personaId,
        llmId,
        isInitial
      );

      setShowData(true);
      setIsDone(true);
    } catch (err) {
      setIsDone(true);
    }
  };

  const getCompDashboardDataFromDB = async (
    brandId,
    productId,
    llmId,
    personaId,
    isInitial,
    brandName,
    productName,
    categoryName,
    brandIds,
    productIds
  ) => {
    setShowData(false);
    setIsDone(false);

    try {
      await getCompetitorComparisonData(
        brandId,
        productId,
        personaId,
        llmId,
        isInitial,
        brandName,
        productName,
        categoryName,
        brandIds,
        productIds
      );

      setShowData(true);
      setIsDone(true);
    } catch (err) {
      setIsDone(true);
    }
  };

  function handleFilterMenuSelect(type) {
    if (type === "reset") {
      const {
        brandItems,
        LlmItems,
        persona,
        geographyItems,
        frequencyItems,
        marketItems,
      } = initialConfig;
      setSelectedBrandItems(brandItems);
      setSelectedLlmItems(LlmItems);
      setSelectedPersona(persona);
      setSelectedGeographyItems(geographyItems);
      setSelectedFrequencyItems(frequencyItems);
      setSelectedMarketItems(selectedMarketItems);
      setIsFilterActive(false);
      return;
    }
    setFilterModalType(type);
    setShowFilterModal(true);
  }

  function handleFilterItemSelect(item) {
    if (!isFilterActive) {
      setInitialConfig({
        brandItems: selectedBrandItems,
        LlmItems: selectedLlmItems,
        persona: selectedPersona,
        geographyItems: selectedGeographyItems,
        frequencyItems: selectedSubFrequencyItems,
        marketItems: selectedMarketItems,
      });
    }

    const {
      selectedLlmItems: llm,
      selectedBrandItems: brand,
      selectedPersona: persona,
      selectedGeographyItems: geo,
      selectedFrequencyItems: freq,
      selectedMarketItems: market,
    } = item.config;

    handleBrandCheckChange([{ brand: { name: brand[0] } }]);
    setShowFilterModal(false);
    setSelectedBrandItems(brand);
    setSelectedLlmItems(llm);
    setSelectedPersona(persona);
    setSelectedGeographyItems(geo);
    setSelectedSubFrequencyItems(freq);
    setSelectedMarketItems(market);
    setIsFilterQuery(true);
    setIsFilterActive(true);
  }

  if (
    selectedOptionShow === "Product" &&
    brandProducts?.data?.data &&
    !brandProducts?.data?.data.length
  ) {
    return <EmptyView message="No Products Found" />;
  }

  return (
    <div className="bodycontent">
      <div>
        <nav className="customTabs">
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button
              className={`nav-link ${activeTab === "overview" ? "active" : ""}`}
              id="nav-overview-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-overview"
              type="button"
              role="tab"
              aria-controls="nav-home"
              aria-selected={activeTab === "overview"}
              onClick={() => handleTabClick("overview")}
            >
              {selectedOptionShow === "Product" ? "Product" : "Brand"} Overview
            </button>
            <button
              className={`nav-link ${
                activeTab === "competition" ? "active" : ""
              }`}
              id="nav-competition-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-competition"
              type="button"
              role="tab"
              aria-controls="nav-competition"
              aria-selected={activeTab === "competition"}
              onClick={() => handleTabClick("competition")}
            >
              Competition
            </button>
            <button
              className={`nav-link ${activeTab === "llm" ? "active" : ""}`}
              id="nav-llm-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-llm"
              type="button"
              role="tab"
              aria-controls="nav-llm"
              aria-selected={activeTab === "llm"}
              onClick={() => handleTabClick("llm")}
            >
              LLM
            </button>
            <button
              className={`nav-link ${activeTab === "market" ? "active" : ""}`}
              id="nav-market-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-market"
              type="button"
              role="tab"
              aria-controls="nav-market"
              aria-selected={activeTab === "market"}
              onClick={() => handleTabClick("market")}
            >
              Market
            </button>
            <button
              className={`nav-link ${activeTab === "personas" ? "active" : ""}`}
              id="nav-personas-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-persons"
              type="button"
              role="tab"
              aria-controls="nav-personas"
              aria-selected={activeTab === "personas"}
              onClick={() => handleTabClick("personas")}
            >
              Personas
            </button>
            <button
              className={`nav-link ${activeTab === "sources" ? "active" : ""}`}
              id="nav-sources-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-sources"
              type="button"
              role="tab"
              aria-controls="nav-sources"
              aria-selected={activeTab === "sources"}
              onClick={() => handleTabClick("sources")}
            >
              Sources
            </button>
          </div>
        </nav>

        {activeTab !== "actions" && (
          <div className="customTabContent tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              // id="nav-overview"
              role="tabpanel"
              aria-labelledby="nav-competition-tab"
            >
              <div className="row">
                <div className="col-md-3 position-relative">
                  <div className="input-group input-group-lg mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="feather icon-briefcase"></i>
                    </span>
                    {selectedOptionShow === "Company/Brand" ? (
                      <InputSelectionComponent
                        options={brandItems}
                        selectedOptions={selectedBrandItems}
                        handleChange={handleBrandCheckChange}
                        type="brand"
                      />
                    ) : (
                      <InputSelectionComponent
                        options={productItems}
                        selectedOptions={selectedProductItems}
                        handleChange={handleProductCheckChange}
                        type="product"
                      />
                    )}
                  </div>
                  {(selectedOptionShow === "Product"
                    ? !inputValidation.selectedProductItems
                    : !inputValidation.selectedBrandItems) &&
                    !isFirstTime && (
                      <div className="position-absolute bottom-0 start-1">
                        <Typography color="error" variant="caption">
                          Please select a {selectedOptionShow}.
                        </Typography>
                      </div>
                    )}
                </div>
                <div className="col-md-3 position-relative">
                  <div className="input-group input-group-lg mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="feather icon-user"></i>
                    </span>

                    <InputSelectionComponent
                      selectedOptions={selectedPersona}
                      handleChange={handlePersonaCheckChange}
                      options={personas}
                      type="persona"
                    />
                  </div>
                  {!inputValidation.selectedPersona && !isFirstTime && (
                    <div className="position-absolute bottom-0 start-1">
                      <Typography color="error" variant="caption">
                        Please select a persona.
                      </Typography>
                    </div>
                  )}
                </div>
                <div className="col-md-3 position-relative">
                  <div className="input-group input-group-lg mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="feather icon-map-pin"></i>
                    </span>

                    <InputSelectionComponent
                      selectedOptions={selectedGeographyItems}
                      handleChange={handleGeographyCheckChange}
                      options={geography}
                      type="geography"
                    />
                  </div>
                  {!inputValidation.selectedGeographyItems && !isFirstTime && (
                    <div className="position-absolute bottom-0 start-1">
                      <Typography color="error" variant="caption">
                        Please select a geography.
                      </Typography>
                    </div>
                  )}
                </div>
                <div className="col-md-3 position-relative">
                  <div className="input-group input-group-lg mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="feather icon-map-pin"></i>
                    </span>

                    <InputSelectionComponent
                      selectedOptions={selectedMarketItems}
                      handleChange={handleMarketCheckChange}
                      options={markets}
                      type="market"
                    />
                  </div>
                  {!inputValidation.selectedMarketItems && !isFirstTime && (
                    <div className="position-absolute bottom-0 start-1">
                      <Typography color="error" variant="caption">
                        Please select a market.
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-md-3 position-relative">
                  <div className="input-group input-group-lg mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="feather icon-target"></i>
                    </span>

                    <InputSelectionComponent
                      selectedOptions={selectedLlmItems}
                      handleChange={handleLlmCheckChange}
                      options={dbLlms}
                      type="llm"
                    />
                  </div>
                  {!inputValidation.selectedLlmItems && !isFirstTime && (
                    <div className="position-absolute bottom-0 start-1">
                      <Typography color="error" variant="caption">
                        Please select a llm.
                      </Typography>
                    </div>
                  )}
                </div>

                <DateRangePicker
                  activeTarget={selectedSubFrequencyItems}
                  handleChange={handlesubFrequencyCheckChange}
                />

                <div className="col-md-2 d-flex align-items-start">
                  <div className="brandBtnGroup">
                    <button
                      disabled={isFirstTime || (!showData && !isDone)}
                      onClick={handleClickShow}
                      className="btn btn-transparent"
                      title="Refresh"
                    >
                      <FontAwesomeIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "4px",
                          marginTop: "-4px",
                        }}
                        icon={faRotateLeft}
                      />
                      Refresh
                    </button>
                  </div>
                  <Filter
                    onSelectMenu={handleFilterMenuSelect}
                    isFilterSet={isFilterActive}
                  />
                  {showFilterModal && filterModalType === "filter-list" && (
                    <FilterList
                      open={showFilterModal}
                      onClose={() => setShowFilterModal(false)}
                      onSelect={handleFilterItemSelect}
                      type={
                        selectedOptionShow === "Product" ? "product" : "brand"
                      }
                    />
                  )}
                  {showFilterModal && filterModalType === "add-new" && (
                    <AddFilter
                      config={{
                        selectedLlmItems,
                        selectedBrandItems,
                        selectedPersona,
                        selectedGeographyItems,
                        selectedFrequencyItems: selectedSubFrequencyItems,
                        selectedMarketItems,
                      }}
                      type={
                        selectedOptionShow === "Product" ? "product" : "brand"
                      }
                      open={showFilterModal}
                      onClose={() => setShowFilterModal(false)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <p> </p>
      <div>
        {!isFirstTime &&
          ((selectedOptionShow === "Product"
            ? !inputValidation.selectedProductItems
            : !inputValidation.selectedBrandItems) ||
            !inputValidation.selectedPersona ||
            !inputValidation.selectedGeographyItems ||
            !inputValidation.selectedMarketItems ||
            !inputValidation.selectedLlmItems) &&
          NoDataFound()}

        {((!showData &&
          !isDone &&
          (selectedOptionShow === "Product"
            ? inputValidation.selectedProductItems
            : inputValidation.selectedBrandItems) &&
          inputValidation.selectedPersona &&
          inputValidation.selectedGeographyItems &&
          inputValidation.selectedMarketItems &&
          inputValidation.selectedLlmItems) ||
          isFirstTime) && (
          <div
            style={{
              fontSize: "19px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
              color: "green",
            }}
          >
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Please Wait...
          </div>
        )}
        {activeTab === "actions" && <Actions />}

        {showData &&
          isDone &&
          (selectedOptionShow === "Product"
            ? inputValidation.selectedProductItems
            : inputValidation.selectedBrandItems) &&
          inputValidation.selectedPersona &&
          inputValidation.selectedGeographyItems &&
          inputValidation.selectedMarketItems &&
          inputValidation.selectedLlmItems && (
            <>
              {activeTab === "overview" && (
                <BrandProductOverview
                  selectedInput={{
                    llm: selectedLlmItems?.[0],
                    market: selectedMarketItems?.[0],
                    persona: selectedPersona?.[0],
                    brand: selectedBrandItems?.[0],
                    product: selectedProductItems?.[0],
                    geography: selectedGeographyItems?.[0],
                  }}
                  productTaxonomyData={productTaxonomyData}
                  sourceFullUrls={sourceFullUrls}
                  indexScoreMetaData={indexScoreMetaData}
                  compIndexScoreMetaData={compIndexScoreMetaData}
                  domain={
                    selectedOptionShow == "Product"
                      ? brandProductDomains[selectedProductItems?.[0]]
                      : brandDomains[selectedBrandItems?.[0]]
                  }
                  brandProductInfo={
                    selectedOptionShow == "Product"
                      ? brandProductInfoJson[selectedProductItems?.[0]]
                      : brandInfoJson[selectedBrandItems?.[0]]
                  }
                  brandProduct={
                    selectedOptionShow == "Product"
                      ? selectedProductItems?.[0]
                      : selectedBrandItems?.[0]
                  }
                  type={selectedOptionShow}
                  brandProductCategory={
                    selectedOptionShow == "Product"
                      ? productCategory
                      : brandCategory
                  }
                  indexScoreDataForLineChart={indexScoreDataForChart}
                  brandCoreAttributesScore={brandCoreAttributesScore}
                  compCoreAttributes={compCoreAttributes}
                  compCategoryDimensions={compCategoryDimensions}
                  brandDomains={
                    selectedOptionShow == "Product"
                      ? brandProductDomains
                      : brandDomains
                  }
                  positiveAttGraphData={positiveAttGraphData}
                  negativeAttGraphData={negativeAttGraphData}
                  pastPositiveAttGraphData={pastPositiveAttGraphData}
                  pastNegativeAttGraphData={pastNegativeAttGraphData}
                  compBrandIndexScore={avgBrandIndex}
                  pastCompBrandIndexScore={avgPastBrandIndex}
                  pastCompCoreAttributes={pastCompCoreAttributes}
                  pastCompCategoryDimensions={pastCompCategoryDimensions}
                  categoryDimensionSource={categoryDimensionSourceData}
                  categoryDimensionRatingSource={
                    categoryDimensionRatingSourceData
                  }
                  coreAttSourceData={coreAttSourceData}
                  reachSourceData={reachSourceData}
                  sentimentSourceData={sentimentSourceData}
                  frequency={selectedSubFrequencyItems}
                />
              )}

              {activeTab === "competition" && (
                <CompComparisonComponent
                  productTaxonomyData={productTaxonomyData}
                  indexScoreMetaData={indexScoreMetaData}
                  compIndexScoreMetaData={compIndexScoreMetaData}
                  compCoreAttributes={compCoreAttributes}
                  pastCompCoreAttributes={pastCompCoreAttributes}
                  compCategoryDimensions={compCategoryDimensions}
                  pastCompCategoryDimensions={pastCompCategoryDimensions}
                  compBrandIndexScore={avgBrandIndex}
                  pastCompBrandIndexScore={avgPastBrandIndex}
                  compPositiveAttributes={compPositiveAttributes}
                  compNegativeAttributes={compNegativeAttributes}
                  brandDomains={
                    selectedOptionShow == "Product"
                      ? brandProductDomains
                      : brandDomains
                  }
                  brandProduct={
                    selectedOptionShow == "Product"
                      ? selectedProductItems?.[0]
                      : selectedBrandItems?.[0]
                  }
                  type={selectedOptionShow}
                />
              )}

              {activeTab === "sources" && (
                <SourceComponent
                  sourceAlias={sourceAlias}
                  sourceFilter={sourceFilter}
                  handleFilterChange={handleFilterChange}
                  positiveAttSource={llmAttributesSources}
                  negativeAttSource={llmNegativeAttributesSources}
                  categoryDimensionSource={categoryDimensionSourceData}
                  categoryDimensionRatingSource={
                    categoryDimensionRatingSourceData
                  }
                  coreAttSourceData={coreAttSourceData}
                  reachSourceData={reachSourceData}
                  sentimentSourceData={sentimentSourceData}
                  isProductDashboard={selectedOptionShow == "Product"}
                  brandProduct={
                    selectedOptionShow == "Product"
                      ? selectedProductItems?.[0]
                      : selectedBrandItems?.[0]
                  }
                />
              )}
              {activeTab !== "overview" &&
                activeTab !== "competition" &&
                activeTab !== "sources" &&
                activeTab !== "actions" && (
                  <MultiComparisonComponent
                    selectedInput={{
                      llm: selectedLlmItems?.[0],
                      market: selectedMarketItems?.[0],
                      persona: selectedPersona?.[0],
                    }}
                    productTaxonomyData={productTaxonomyData}
                    indexScoreMetaData={indexScoreMetaData}
                    compCoreAttributes={compCoreAttributes}
                    pastCompCoreAttributes={pastCompCoreAttributes}
                    compCategoryDimensions={compCategoryDimensions}
                    pastCompCategoryDimensions={pastCompCategoryDimensions}
                    brandIndex={llmCompBrandIndex}
                    brandReach={llmCompBrandReach}
                    brandFav={llmCompBrandFav}
                    pastIndexScoreObj={pastLlmCompBrandIndex}
                    positiveAttSource={llmAttributesSources}
                    negativeAttSource={llmNegativeAttributesSources}
                    positiveAttributes={llmAttributes}
                    negativeAttributes={llmNegativeAttributes}
                    llmDomains={llmDomains}
                    geoLogo={geoLogo}
                    activeTab={activeTab}
                    brandDomains={
                      selectedOptionShow == "Product"
                        ? brandProductDomains
                        : brandDomains
                    }
                    brandProduct={
                      selectedOptionShow == "Product"
                        ? selectedProductItems?.[0]
                        : selectedBrandItems?.[0]
                    }
                    type={selectedOptionShow}
                  />
                )}
            </>
          )}
      </div>

      <ToastContainer />
    </div>
  );
};

export default Dashboard;
