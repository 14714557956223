import React from "react";
import Select, { components } from "react-select";

import Tooltip from "@mui/material/Tooltip";

function FilterSelection({ options, type, selectedOptions, handleChange }) {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none", // Remove the border
      boxShadow: "none", // Remove box shadow
      padding: "0", // Remove padding in control
      minHeight: "24px", // Set a fixed height
      maxHeight: "24px", // Set a max height to avoid expanding
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0 8px", // Adjust padding inside the value container
      margin: "0", // Remove any margin that might affect spacing
      height: "24px", // Set a fixed height
      overflowY: "auto", // Enable vertical scrollbar if items overflow
      display: "flex",
      flexWrap: "nowrap", // Prevent wrapping of selected values
    }),
    input: (provided) => ({
      ...provided,
      margin: "0", // Remove any margin
      padding: "0", // Remove any padding
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "12px", // Set desired font size
      color: "#aaa", // Optional: Customize placeholder color
    }),
    multiValue: (provided) => ({
      ...provided,
      marginTop: "0px", // Adjust margin to move selected value up
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      padding: "0", // Adjust padding for selected value labels
      marginTop: "0px",
    }),
    indicatorSeparator: () => ({
      display: "none", // Remove the separator between the dropdown indicator and the value
      marginTop: "0px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "0", // Remove padding for the dropdown indicator
      marginTop: "0px",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: "0",
      marginTop: "0px", // Move the clear icon up
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "transparent"
        : provided.backgroundColor,
      color: "black",
      ":hover": {
        backgroundColor: "#f0f0f0",
      },
    }),
  };

  // Custom option component to include checkboxes
  const Option = ({ children, ...props }) => {
    return (
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        {children}
      </components.Option>
    );
  };

  // Custom MultiValue to handle displaying the first value and "+N selected"
  const MultiValue = ({ index, getValue, ...props }) => {
    const selectedValues = getValue();
    if (index === 0) {
      return (
        <components.MultiValue {...props}>
          <Tooltip
            title={`${props?.children} ${
              selectedValues.length > 1 ? `+${selectedValues.length - 1}` : ""
            }`}
          >
            <span>{props?.children} </span>
          </Tooltip>
          {selectedValues.length > 1 && ` +${selectedValues.length - 1}`}
        </components.MultiValue>
      );
    }
    return null;
  };

  return (
    <Select
      styles={customStyles}
      options={options}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      value={selectedOptions}
      components={{
        Option,
        MultiValue,
        // Menu: DropdownWithApply
      }}
      onChange={handleChange}
      className="form-control"
      classNamePrefix="react-select"
      placeholder={`Select ${type}s`}
      isMulti={type ? true : false}
    />
  );
}

export default FilterSelection;
