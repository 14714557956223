import api from "../../BrandOnBoarding/api";

import {
  FETCH_ACTION_METRICS_REQUEST,
  FETCH_ACTION_METRICS_SUCCESS,
  FETCH_ACTION_METRICS_FAILURE,
  CREATE_ACTION_METRIC_REQUEST,
  CREATE_ACTION_METRIC_SUCCESS,
  CREATE_ACTION_METRIC_FAILURE,
  UPDATE_ACTION_METRIC_REQUEST,
  UPDATE_ACTION_METRIC_SUCCESS,
  UPDATE_ACTION_METRIC_FAILURE,
  DELETE_ACTION_METRIC_REQUEST,
  DELETE_ACTION_METRIC_SUCCESS,
  DELETE_ACTION_METRIC_FAILURE,
} from "../constants/actionTypes";

export const fetchActionMetrics = (id) => async (dispatch) => {
  dispatch({ type: FETCH_ACTION_METRICS_REQUEST });
  try {
    const response = await api.get(`/customer/${id}/metric-actions`);
    dispatch({ type: FETCH_ACTION_METRICS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_ACTION_METRICS_FAILURE, error: error.message });
  }
};

export const createActionMetric = (id, actionJsons) => async (dispatch) => {
  dispatch({ type: CREATE_ACTION_METRIC_REQUEST });
  try {
    const response = await api.post(
      `/customer/${id}/metric-action`,
      actionJsons
    );
    dispatch({ type: CREATE_ACTION_METRIC_SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: CREATE_ACTION_METRIC_FAILURE, error: error.message });
    throw error;
  }
};

export const updateActionMetric = (id, actionId, action) => async (dispatch) => {
  dispatch({ type: UPDATE_ACTION_METRIC_REQUEST });
  try {
    const response = await api.put(
      `/customer/${id}/metric-action/${actionId}`,
      action
    );
    dispatch({ type: UPDATE_ACTION_METRIC_SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: UPDATE_ACTION_METRIC_FAILURE, error: error.message });
    throw error;
  }
};

export const deleteOrArchiveActionMetric =
  (id, actionId, isArchived) => async (dispatch) => {
    dispatch({ type: DELETE_ACTION_METRIC_REQUEST });
    try {
      await api.delete(
        `/customer/${id}/metric-action/${actionId}?is_archived=${isArchived}`
      );
      dispatch({ type: DELETE_ACTION_METRIC_SUCCESS, payload: id });
    } catch (error) {
      dispatch({ type: DELETE_ACTION_METRIC_FAILURE, error: error.message });
    }
  };
