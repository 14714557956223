import React, { useState, useEffect } from "react";
import {
  prepareComparisonTableData,
  transformDataForMultibarChart,
  extractDomain,
  generateDashboardJsonPrompt,
} from "../../../utils/helpers";
import ReMultibarChart from "../common/ReMultibarChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import classes from "./DashboardComponent.module.css";
import { metricExplainations, titleMapping } from "../../../utils/constant";
import CustomTooltip from "../common/CustomTooltip";
import EmptyView from "../../../Component/EmptyView/EmptyView";
import { getDashboardSummary } from "../../../services/apiService";
import Markdown from "markdown-to-jsx";
import { Spinner } from "react-bootstrap";
import MUIButton from "./../../../Component/UI/MUIButton/MUIButton";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import { Tooltip } from "@mui/material";
import Modal from "../../../Component/Modal/Modal";
import BrandReachSentimentMeta from "../../../Component/BrandIndexHistory/BrandReachSentimentMeta";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
export const dashboardTypeMapping = {
  llm: { type: "LLM", dashboardType: "llmDashboard" },
  market: { type: "Market", dashboardType: "marketDashboard" },
  personas: { type: "Persona", dashboardType: "personaDashboard" },
};

const MultiComparisonComponent = ({
  compCoreAttributes,
  pastCompCoreAttributes,
  compCategoryDimensions,
  pastCompCategoryDimensions,
  brandIndex,
  brandReach,
  brandFav,
  pastIndexScoreObj,
  positiveAttributes,
  negativeAttributes,
  llmDomains,
  geoLogo,
  activeTab,
  brandDomains,
  brandProduct,
  indexScoreMetaData,
  selectedInput,
  type,
  productTaxonomyData,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isDeltaDisplay, setIsDeltaDisplay] = useState(false);
  const [indexScore, setIndexScore] = useState();
  const [reachScore, setReachScore] = useState();
  const [favScore, setFavScore] = useState();
  const [summary, setSummary] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showBrandIndexBreakups, setShowBrandIndexBreakups] = useState(false);
  const [metaData, setMetaData] = useState();

  const [expandedRows, setExpandedRows] = useState({}); // Store expanded state for rows

  const toggleRow = (attribute) => {
    setExpandedRows((prev) => ({
      ...prev,
      [attribute]: !prev[attribute],
    }));
  };

  useEffect(() => {
    let index = brandIndex;
    let reach = brandReach;
    let fav = brandFav;
    if (!isChecked) {
      index = filteredCompData(brandIndex, brandProduct);
      reach = filteredCompData(brandReach, brandProduct);
      fav = filteredCompData(brandFav, brandProduct);
    }

    setIndexScore(index);
    setReachScore(reach);
    setFavScore(fav);
  }, [isChecked]);

  // Function to toggle the checkbox
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleDeltaCheckboxChange = () => {
    setIsDeltaDisplay(!isDeltaDisplay);
  };

  const filteredCompData = (data, mainBrand) => {
    if (data && Object?.keys(data).length) {
      let obj = {};
      obj[mainBrand] = data[mainBrand];
      return obj;
    }
  };

  function filterPurchaseDims(DATA1, DATA2) {
    // Extract attributes from DATA1
    const data1Attributes = new Set(DATA1.map((item) => item.attribute));
    for (const llm in DATA2) {
      if (DATA2.hasOwnProperty(llm)) {
        DATA2[llm] = Object.fromEntries(
          Object.entries(DATA2[llm]).filter(([attribute]) =>
            data1Attributes.has(attribute)
          )
        );
      }
    }

    return DATA2; // Return the modified DATA2
  }

  const brandProductMetric =
    type == "Product" ? productTaxonomyData : compCoreAttributes;
  const tableData = prepareComparisonTableData(
    brandProductMetric,
    indexScore,
    reachScore,
    favScore,
    compCategoryDimensions,
    pastCompCoreAttributes,
    pastIndexScoreObj?.indexScore,
    pastIndexScoreObj?.rank,
    pastIndexScoreObj?.fav,
    pastCompCategoryDimensions,
    type == "Product"
  );

  const positiveAttBarChartData =
    transformDataForMultibarChart(positiveAttributes);
  const negativeAttBarChartData =
    transformDataForMultibarChart(negativeAttributes);

  const chartDataArray = [positiveAttBarChartData, negativeAttBarChartData];

  const fetchSummary = async (brandIndex, brandReach, brandFav) => {
    setIsLoading(true);

    const purchaseCriterias = filterPurchaseDims(
      tableData?.tables?.[4]?.rows,
      compCategoryDimensions
    );

    try {
      const produtSpecificMetric =
        type == "Product" ? productTaxonomyData : compCoreAttributes;
      const jsonData = generateDashboardJsonPrompt(
        brandProduct,
        dashboardTypeMapping[activeTab]?.type,
        JSON.stringify(brandIndex),
        JSON.stringify(brandReach),
        JSON.stringify(brandFav),
        JSON.stringify(positiveAttBarChartData),
        JSON.stringify(negativeAttBarChartData),
        produtSpecificMetric,
        purchaseCriterias,
        type == "Product"
      );
      const payload = {
        entity: brandProduct,
        dashboardType: dashboardTypeMapping[activeTab]?.dashboardType,
        jsonData,
        isProduct: type == "Product",
      };
      const response = await getDashboardSummary(payload);
      setSummary(response);
    } catch (error) {
      console.error("Error fetching summary:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getTruncatedText = () => {
    const words = summary?.split(" ");
    return words.slice(0, 30).join(" ") + (words.length > 50 ? "...." : "");
  };

  function extractTextInsideBrackets(input) {
    const match = input.match(/\(([^)]+)\)/);
    return match ? match[1] : null;
  }

  const handleIndexScoreMetaModal = (
    isMeta,
    brandProductName,
    targetTitle,
    metric
  ) => {
    const reachOrSentiment = metric !== "RBI";
    const isReach = metric === "Reach";
    if (isMeta && reachOrSentiment) {
      const filterMeta = indexScoreMetaData?.filter(
        (obj) =>
          obj?.name === brandProductName &&
          obj?.llm ===
            (activeTab === "llm" ? targetTitle : selectedInput?.llm) &&
          obj.market ===
            (activeTab === "market"
              ? extractTextInsideBrackets(targetTitle)
              : selectedInput?.market) &&
          obj.persona ===
            (activeTab === "personas" ? targetTitle : selectedInput?.persona)
      );
      if (filterMeta && filterMeta.length && filterMeta?.[0]?.extras) {
        setShowBrandIndexBreakups(true);
        setMetaData({ data: filterMeta?.[0]?.extras, isReach: isReach });
      }
    }
  };

  if (!tableData) {
    return;
  }

  return (
    <>
      <Modal
        open={showBrandIndexBreakups}
        handleClose={() => setShowBrandIndexBreakups(false)}
        width={1200}
      >
        <BrandReachSentimentMeta
          isReach={metaData?.isReach}
          data={metaData?.data}
        />
      </Modal>

      <br />

      {/* <div className="col-md-12">
        <div className="graphGreyBg">
          <p style={{ display: "flex", justifyContent: "center" }}>
            <h4>Headlines</h4>
            &nbsp; &nbsp;
            <MUIButton
              onClick={() => {
                fetchSummary(indexScore, reachScore, favScore);
              }}
              variant="outlined"
              className={classes.regenerate}
              startIcon={<AutoAwesomeOutlinedIcon />}
            >
              Generate
            </MUIButton>
          </p>
          <div className="graphsummary font-11">
            {isLoading ? (
              <div
                style={{
                  fontSize: "19px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                  // color: "green",
                }}
              >
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp; Loading...
              </div>
            ) : summary && typeof summary === "string" ? (
              <>
                <Markdown className="markTable">
                  {isExpanded ? summary : getTruncatedText()}
                </Markdown>
                <span
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  {isExpanded ? "Show Less" : "Show More"}
                </span>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div> */}

      <div
        style={{ fontSize: "15px", marginTop: "12px", marginBottom: "-4px" }}
      >
        <label>
          <input
            style={{}}
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          &nbsp;
          {"Show Competitor"}
        </label>
        &nbsp;
        <label>
          <input
            style={{}}
            type="checkbox"
            checked={isDeltaDisplay}
            onChange={handleDeltaCheckboxChange}
          />
          &nbsp;
          {"Show Change Data"}
        </label>
      </div>

      <div className="col-md-12 mt-4">
        <div
          style={{
            position: "relative",
            maxHeight: "400px",
            overflowY: "auto",
          }}
          className="card custCard"
        >
          <table className="table table-striped">
            <thead
              style={{
                position: "sticky",
                // zIndex: 10,
                top: 0,
              }}
            >
              <tr className="tblHeader text-center">
                <th
                  scope="col"
                  style={{
                    width: "150px",
                    textAlign: "left",
                    paddingLeft: "15px",
                    verticalAlign: "middle",
                  }}
                >
                  {/* <Tooltip title={metricExplainations["RBI"]}>RBI</Tooltip> */}
                </th>

                {tableData.headers.map((header, idx) => {
                  const extractGeo = header
                    .split("(")[0]
                    .trim()
                    .replace(/\s+/g, "");
                  return (
                    <th
                      scope="col"
                      key={idx}
                      className={classes["comp-logo-header"]}
                    >
                      <div className={classes["comp-logos"]}>
                        {activeTab !== "llm" ? (
                          geoLogo?.[extractGeo] && (
                            <img
                              className={classes?.logo}
                              src={`https://flagcdn.com/40x30/${geoLogo?.[extractGeo]}.png`}
                              alt={""}
                            />
                          )
                        ) : (
                          <img
                            className={classes?.logo}
                            src={
                              header == "llm-web"
                                ? "/menu.png"
                                : `https://img.logo.dev/${extractDomain(
                                    llmDomains?.[header]
                                  )}?token=pk_SaDf4t2eRsW5Fc84zgJRZA`
                            }
                            alt={""}
                          />
                        )}
                        <span
                          style={{
                            wordWrap: "break-word", // Ensures long text wraps
                            textAlign: "center",
                            marginTop: "5px", // Adds space between logo and text
                          }}
                        >
                          {header}
                        </span>
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody>
              {tableData.tables.map((table, tableIndex) => (
                <React.Fragment key={tableIndex}>
                  {tableIndex !== 0 && <br />}
                  <tr className="tblHeader">
                    {/* {tableIndex !== 0 && ( )} */}
                    <th
                      style={{ textAlign: "left", paddingLeft: "15px" }}
                      colSpan={tableData.headers.length + 1}
                    >
                      <Tooltip
                        title={metricExplainations[titleMapping[table.title]]}
                      >
                        <span
                          style={{
                            color: "black",
                            fontSize: "1.2rem",
                            fontWeight: "500",
                          }}
                        >
                          {titleMapping[table.title]}
                        </span>
                      </Tooltip>
                      {/* {titleMapping[table.title]} */}
                    </th>
                  </tr>
                  {table?.rows && table?.rows?.length ? (
                    table.rows?.map((row, idx) => {
                      const isProductDimensions =
                        table.title === "Product Dimensions";
                      const isParentRow = !row.values?.[0]?.parentCategory;
                      const isExpanded = expandedRows[row.attribute];

                      if (isProductDimensions) {
                        if (isParentRow) {
                          // Parent Row for Product dimensions
                          return (
                            <React.Fragment key={idx}>
                              <tr>
                                <td
                                  valign="middle"
                                  style={{
                                    width: "150px",
                                    textAlign: "left",
                                    paddingLeft: "10px",
                                    // fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => toggleRow(row.attribute)}
                                >
                                  {isExpanded ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}{" "}
                                  &nbsp;
                                  {titleMapping?.[row.attribute] ||
                                    row.attribute}
                                </td>
                                {row.values
                                  // .slice(startIndex, startIndex + pageSize)
                                  .map((val, valIdx) => {
                                    const isColorApply =
                                      table?.title == "RBI" ||
                                      table?.title == "Rank" ||
                                      table?.title == "Favorability";
                                    let color = "#dc3545";
                                    if (Number(val?.value).toFixed(0) >= 7)
                                      color = "#2196F3";
                                    if (Number(val?.value).toFixed(0) >= 9)
                                      color = "#198754";
                                    return (
                                      <td
                                        key={valIdx}
                                        style={{
                                          width: "100px",
                                          textAlign: "center",
                                          // cursor: "pointer",
                                        }}
                                      >
                                        <CustomTooltip
                                          explaination={val?.explaination}
                                        >
                                          <div
                                            // title={val?.explaination}
                                            className="d-flex align-items-center justify-content-center flex-row"
                                          >
                                            <span
                                              onClick={() => {
                                                handleIndexScoreMetaModal(
                                                  isColorApply,
                                                  row.attribute,
                                                  tableData?.headers?.[valIdx],
                                                  titleMapping[table.title]
                                                );
                                              }}
                                              onMouseEnter={(e) => {
                                                if (
                                                  titleMapping[table.title] !=
                                                  "RBI"
                                                ) {
                                                  e.target.style.color =
                                                    "#2196F3";
                                                }
                                              }} // Change color on hover
                                              onMouseLeave={(e) =>
                                                (e.target.style.color =
                                                  !isColorApply && !val?.isNa
                                                    ? color
                                                    : "grey")
                                              }
                                              style={{
                                                cursor: "pointer",
                                                color:
                                                  !isColorApply && !val?.isNa
                                                    ? color
                                                    : "grey",
                                              }}
                                              className={`h5 mb-0 me-1
                              } ${classes["main-value"]}`}
                                            >
                                              {val?.value != null &&
                                              !isNaN(Number(val.value)) &&
                                              (table.title == "Rank" ||
                                                val.value != 0)
                                                ? Number(val.value).toFixed(0)
                                                : "NA"}
                                            </span>
                                            {val?.change != undefined &&
                                              !val?.isNa &&
                                              isChecked && (
                                                <>
                                                  <FontAwesomeIcon
                                                    className={
                                                      val?.change < 0
                                                        ? `${classes["down-icon"]} text-danger me-1`
                                                        : `${classes["up-icon"]} text-success me-1`
                                                    }
                                                    icon={
                                                      val?.change < 0
                                                        ? faSortDown
                                                        : faSortUp
                                                    }
                                                  />
                                                  <span
                                                    className={classes.delta}
                                                  >
                                                    {val?.change > 0
                                                      ? `${Number(
                                                          val?.change
                                                        ).toFixed(0)}`
                                                      : Number(
                                                          Math.abs(val?.change)
                                                        ).toFixed(0)}
                                                  </span>
                                                </>
                                              )}
                                          </div>
                                        </CustomTooltip>
                                      </td>
                                    );
                                  })}
                              </tr>

                              {/* Subcategories */}
                              {isExpanded &&
                                table.rows
                                  .filter(
                                    (subRow) =>
                                      subRow.values?.[0]?.parentCategory ===
                                      row.attribute
                                  )
                                  .map((subRow, subIdx) => (
                                    <tr key={`sub-${subIdx}`}>
                                      <td
                                        valign="middle"
                                        style={{
                                          width: "150px",
                                          textAlign: "left",
                                          paddingLeft: "42px",
                                        }}
                                      >
                                        {titleMapping?.[subRow.attribute] ||
                                          subRow.attribute}
                                      </td>
                                      {subRow.values
                                        // .slice(startIndex, startIndex + pageSize)
                                        .map((val, valIdx) => {
                                          const isColorApply =
                                            table?.title == "RBI" ||
                                            table?.title == "Rank" ||
                                            table?.title == "Favorability";
                                          let color = "#dc3545";
                                          if (
                                            Number(val?.value).toFixed(0) >= 7
                                          )
                                            color = "#2196F3";
                                          if (
                                            Number(val?.value).toFixed(0) >= 9
                                          )
                                            color = "#198754";
                                          return (
                                            <td
                                              key={valIdx}
                                              style={{
                                                width: "100px",
                                                textAlign: "center",
                                                // cursor: "pointer",
                                              }}
                                            >
                                              <CustomTooltip
                                                explaination={val?.explaination}
                                              >
                                                <div
                                                  // title={val?.explaination}
                                                  className="d-flex align-items-center justify-content-center flex-row"
                                                >
                                                  <span
                                                    onClick={() => {
                                                      handleIndexScoreMetaModal(
                                                        isColorApply,
                                                        row.attribute,
                                                        tableData?.headers?.[
                                                          valIdx
                                                        ],
                                                        titleMapping[
                                                          table.title
                                                        ]
                                                      );
                                                    }}
                                                    onMouseEnter={(e) => {
                                                      if (
                                                        titleMapping[
                                                          table.title
                                                        ] != "RBI"
                                                      ) {
                                                        e.target.style.color =
                                                          "#2196F3";
                                                      }
                                                    }} // Change color on hover
                                                    onMouseLeave={(e) =>
                                                      (e.target.style.color =
                                                        !isColorApply &&
                                                        !val?.isNa
                                                          ? color
                                                          : "grey")
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                      color:
                                                        !isColorApply &&
                                                        !val?.isNa
                                                          ? color
                                                          : "grey",
                                                    }}
                                                    className={`h5 mb-0 me-1
                              } ${classes["main-value"]}`}
                                                  >
                                                    {val?.value != null &&
                                                    !isNaN(Number(val.value)) &&
                                                    (table.title == "Rank" ||
                                                      val.value != 0)
                                                      ? Number(
                                                          val.value
                                                        ).toFixed(0)
                                                      : "NA"}
                                                  </span>
                                                  {val?.change != undefined &&
                                                    !val?.isNa &&
                                                    isChecked && (
                                                      <>
                                                        <FontAwesomeIcon
                                                          className={
                                                            val?.change < 0
                                                              ? `${classes["down-icon"]} text-danger me-1`
                                                              : `${classes["up-icon"]} text-success me-1`
                                                          }
                                                          icon={
                                                            val?.change < 0
                                                              ? faSortDown
                                                              : faSortUp
                                                          }
                                                        />
                                                        <span
                                                          className={
                                                            classes.delta
                                                          }
                                                        >
                                                          {val?.change > 0
                                                            ? `${Number(
                                                                val?.change
                                                              ).toFixed(0)}`
                                                            : Number(
                                                                Math.abs(
                                                                  val?.change
                                                                )
                                                              ).toFixed(0)}
                                                        </span>
                                                      </>
                                                    )}
                                                </div>
                                              </CustomTooltip>
                                            </td>
                                          );
                                        })}
                                    </tr>
                                  ))}
                            </React.Fragment>
                          );
                        }

                        return null; // Skip subcategory rows initially for Product dimensions
                      }

                      return (
                        <tr key={idx}>
                          {brandDomains?.[row?.attribute] ? (
                            <>
                              <td
                                valign="middle"
                                style={{
                                  width: "150px",
                                  textAlign: "left",
                                  paddingLeft: "15px",
                                }}
                              >
                                <img
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "6px",
                                    marginBottom: "5px", // Adds spacing between logo and text
                                  }}
                                  src={`https://img.logo.dev/${extractDomain(
                                    brandDomains?.[row?.attribute]
                                  )}?token=pk_SaDf4t2eRsW5Fc84zgJRZA`}
                                  alt={""}
                                />
                                &nbsp;
                                {row.attribute}
                              </td>
                            </>
                          ) : (
                            <td
                              // valign="middle"
                              style={{
                                width: "150px",
                                textAlign: "left",
                                paddingLeft: row?.values?.[0]?.parentCategory
                                  ? "25px"
                                  : "15px",
                              }}
                            >
                              <Tooltip
                                title={metricExplainations[row.attribute]}
                              >
                                {row.attribute}
                              </Tooltip>
                            </td>
                          )}

                          {row.values.map((val, valIdx) => {
                            const isColorApply =
                              table?.title == "RBI" ||
                              table?.title == "Rank" ||
                              table?.title == "Favorability";
                            let color = "#dc3545";
                            if (Number(val?.value).toFixed(0) >= 7)
                              color = "#2196F3";
                            if (Number(val?.value).toFixed(0) >= 9)
                              color = "#198754";

                            return (
                              <td
                                key={valIdx}
                                style={{
                                  width: "100px",
                                  textAlign: "center",
                                  // cursor: "pointer",
                                }}
                              >
                                <CustomTooltip explaination={val?.explaination}>
                                  <div className="d-flex align-items-center justify-content-center flex-row">
                                    <span
                                      onClick={() => {
                                        handleIndexScoreMetaModal(
                                          isColorApply,
                                          row.attribute,
                                          tableData?.headers?.[valIdx],
                                          titleMapping[table.title]
                                        );
                                      }}
                                      onMouseEnter={(e) => {
                                        if (
                                          titleMapping[table.title] != "RBI"
                                        ) {
                                          e.target.style.color = "#2196F3";
                                        }
                                      }} // Change color on hover
                                      onMouseLeave={(e) =>
                                        (e.target.style.color =
                                          !isColorApply && !val?.isNa
                                            ? color
                                            : "grey")
                                      }
                                      style={{
                                        cursor: "pointer",
                                        color:
                                          !isColorApply && !val?.isNa
                                            ? color
                                            : "grey",
                                      }}
                                      className={`h5 mb-0 me-1  ${classes["main-value"]}`}
                                    >
                                      {val?.value != null &&
                                      !isNaN(Number(val.value)) &&
                                      (table.title == "Rank" || val.value != 0)
                                        ? Number(val.value).toFixed(0)
                                        : "NA"}
                                    </span>

                                    {val?.change != undefined &&
                                      !val?.isNa &&
                                      isDeltaDisplay && (
                                        <>
                                          <FontAwesomeIcon
                                            className={
                                              val?.change < 0
                                                ? `${classes["down-icon"]} text-danger me-1`
                                                : `${classes["up-icon"]} text-success me-1`
                                            }
                                            icon={
                                              val?.change < 0
                                                ? faSortDown
                                                : faSortUp
                                            }
                                          />
                                          <span className={classes.delta}>
                                            {val?.change > 0
                                              ? `${Number(val?.change).toFixed(
                                                  0
                                                )}`
                                              : Number(
                                                  Math.abs(val?.change)
                                                ).toFixed(0)}
                                          </span>
                                        </>
                                      )}
                                  </div>
                                </CustomTooltip>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <EmptyView message="Data Not Available" />
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {chartDataArray?.map((data, index) => {
        return (
          <div className="col-md-12 mt-4">
            <div className="card p-3 custCard">
              <div className="mb-3 d-flex justify-content-between align-items-center"></div>
              <div>
                <ReMultibarChart index={index} data={data} />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default MultiComparisonComponent;
