import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/system";

import classes from "./MUISelect.module.css";

const CustomFormControl = styled(FormControl)({
  "& .MuiNativeSelect-select": {
    borderRadius: 4,
    padding: 9,
    fontSize: 14,
  },
});

const MUINativeSelect = React.forwardRef(function MUINativeSelect(
  { label, options, placeholder = "Select", optionValue, defaultValue = "", onChange = () => {}, ...props },
  ref
) {
  const [value, setValue] = React.useState(null);

  React.useImperativeHandle(ref, () => {
    return {
      getValue: () => value,
    };
  });

  return (
    <Box className={classes.box}>
      <Typography className={classes.label}>{label}</Typography>
      <CustomFormControl fullWidth variant="outlined" size="small">
        <NativeSelect
          input={<OutlinedInput notched />}
          inputProps={{
            name: label,
          }}
          value={optionValue}
          ref={ref}
          onChange={(e) => {
            onChange(e);
            setValue(e.target.value);
          }}
          {...props}
        >
          <option
            value=""
            className={classes.placeholder}
            disabled
            key="placeholder"
          >
            {placeholder}
          </option>
          {options.length > 0 && options.map((option) => {
            return (
              <option key={option?.id} value={option.value}>
                {option.name}
              </option>
            );
          })}
        </NativeSelect>
      </CustomFormControl>
    </Box>
  );
});

export default MUINativeSelect;
