import { useEffect, useState, useContext, useRef } from "react";

import { DiscoveryContext } from "../../Discovery/discovery-context";
import api from "../../../BrandOnBoarding/api";

export default function useClassifyProduct() {
  const [classifiedProducts, setClassifiedProducts] = useState([]);
  const discoveryCtx = useContext(DiscoveryContext);
  const abortControllerRef = useRef();

  async function classifyProducts() {
    abortControllerRef.current = new AbortController();
    try {
      const products = discoveryCtx.competitors.map(
        (competitor) => competitor.name
      );
      const response = await api.post(
        "/classify-products",
        {
          products: products,
        },
        {
          signal: abortControllerRef.current.signal,
        }
      );
      setClassifiedProducts(response.data.data);
    } catch (error) {
      console.error("Error classifying products:", error);
    }
  }

  useEffect(() => {
    if (
      discoveryCtx.competitors.length !== 4 ||
      discoveryCtx.type !== "category"
    )
      return;
    classifyProducts();
  }, [discoveryCtx.competitors.length, discoveryCtx.type]);

  useEffect(() => {
    if (discoveryCtx.abort_launch) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    }
  }, [discoveryCtx.abort_launch]);

  return { classifiedProducts };
}
