import { useEffect, useState, useContext, useRef } from "react";

import { DiscoveryContext } from "../../Discovery/discovery-context";
import api from "../../../BrandOnBoarding/api";

export default function useCustomerReviews() {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false);

  const abortControllerRef = useRef();
  const discoveryCtx = useContext(DiscoveryContext);

  async function getReviews() {
    abortControllerRef.current = new AbortController();
    const maxRetries = 3; // Maximum retry attempts for empty products
    const delayBetweenRetries = 2000; // Delay in milliseconds between retries

    try {
      setLoading(true);
      const products = discoveryCtx.competitors.map(
        (competitor) => competitor.name
      );

      // Function to fetch reviews for a list of products
      async function fetchProductReviews(productsToFetch) {
        try {
          const response = await api.post(
            "/utils/fetch-reviews", // Uses grounded search gemini
            { products: productsToFetch },
            { signal: abortControllerRef.current.signal }
          );
          return response.data.data || [];
        } catch (error) {
          console.error("Error fetching reviews:", error);
          return productsToFetch.map((product) => ({
            product_name: product,
            reviews: [],
          }));
        }
      }

      // Initialize with all products
      let allReviews = await fetchProductReviews(products);

      for (let attempt = 1; attempt <= maxRetries; attempt++) {
        // Find products with empty reviews
        const emptyProducts = allReviews
          .filter((product) => (product.reviews || []).length === 0)
          .map((product) => product.product_name);

        if (emptyProducts.length === 0) {
          break; // No more empty products, exit retry loop
        }

        console.log(
          `Retrying fetch for products with empty reviews: ${emptyProducts.join(
            ", "
          )}. Attempt ${attempt}`
        );

        // Retry fetch for only products with empty reviews
        const retryReviews = await fetchProductReviews(emptyProducts);

        // Update the original `allReviews` with the retried data
        allReviews = allReviews.map((product) => {
          const retryData = retryReviews.find(
            (retryProduct) => retryProduct.product_name === product.product_name
          );
          return retryData?.reviews?.length > 0 ? retryData : product;
        });

        // Wait before the next attempt
        await new Promise((resolve) =>
          setTimeout(resolve, delayBetweenRetries)
        );
      }

      setReviews(allReviews);
    } catch (error) {
      console.error("Error fetching reviews:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (discoveryCtx.abort_launch) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    }
  }, [discoveryCtx.abort_launch]);

  useEffect(() => {
    if (
      discoveryCtx.competitors.length !== 4 ||
      discoveryCtx.type !== "category"
    )
      return;
    getReviews();
  }, [discoveryCtx.competitors.length, discoveryCtx.type]);

  return { loading, reviews };
}
