import { Skeleton } from "@mui/material";

export default function LoadingSkeleton() {
  return (
    <div>
      {Array(10)
        .fill(0)
        .map((_, index) => {
          return <Skeleton key={index} variant="text" height={40} />;
        })}
    </div>
  );
}
