import { useEffect, useState, useContext, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useLocation } from "react-router";
import { LinearProgress } from "@mui/material";
import { toast } from "react-toastify";

import Header from "./../../Component/Header/Header";
import classes from "./Discovery.module.css";
import BrandScore from "./BrandScore/BrandScore";
import CoreAttributes from "./CoreAttributes/CoreAttributes";
import Competitors from "./Competitors/Competitors";
import { DiscoveryContext } from "./discovery-context";
import Sources from "./Sources/Sources";
import Tabs from "./../../Component/UI/Tabs/Tabs";
import Form from "./Form";
import AIGenerated from "../../Component/AIGenerated/AIGenerated";
import MUIButton from "../../Component/UI/MUIButton/MUIButton";
import CategoryDimensions from "./CategoryDimensions/CategoryDimensions";
import History from "./History/History";
import { useDailyLimits } from "../../hooks/useDailyLimits";
import PDFHeader from "../../Component/PDFHeader/PDFHeader";
import PDFFooter from "../../Component/PDFFooter/PDFFooter";
import Taxonomy from "./Taxonomy/Taxonomy";
import { getSingleDocFromFirebase } from "../../DatabaseFirebase/firebaseService";
import api from "../../BrandOnBoarding/api";
import Suggestions from "../CategoryDiscovery/Suggestions/Suggestions";
import ProductSpecs from "./../CategoryDiscovery/ProductSpecs/ProductSpecs";
import ProductRecommendations from "../CategoryDiscovery/ProductRecommendations/ProductRecommendations";
import useTopUserProducts from "./useTopUserProducts";
import useGenerateProductTaxonomies from "./useGenerateProductTaxonomies";
import useProductPreference from "./useProductPreference";
import useGeneratePreCategoryDimensions from "./useGeneratePreCategoryDimensions";

export default function Discovery({
  onKeyPlayerChange,
  onProductBrandSelection = () => {},
  selectedBrandsProducts = [],
  onDelete = () => {},
}) {
  const [inputValues, setInputValues] = useState({
    brand: "",
    category: "",
    market: "",
    llm: "",
    type: "brand",
  });
  const [tabSelected, setTabSelected] = useState("overview");
  const [externalReportLoading, setExternalReportLoading] = useState(false);
  // const [keyPlayers, setKeyPlayers] = useState([]);

  const { keyPlayers, getBrandOrProductSuggestions } = useTopUserProducts();
  const { generateProductTaxonomies } = useGenerateProductTaxonomies();

  const {
    launchText,
    updateLaunches,
    launchesRemaining,
    isSpecial,
    frequency: launchLimitFrequency,
  } = useDailyLimits("Discovery");

  const discoveryCtx = useContext(DiscoveryContext);
  const abortControllerRef = useRef(null);
  const location = useLocation();

  const categoryChangeTimerRef = useRef();
  const keyPlayerChangeTimerRef = useRef();
  const entity = discoveryCtx.entity;

  const { getProductPrefs } = useProductPreference();
  const { generatePreCategoryDimensions } = useGeneratePreCategoryDimensions();

  const pdfRef = useRef();

  async function getCompetitors() {
    const prompt = `
    You are a market research expert. For the given product category and location scope (country-specific or worldwide), provide the top 3 ${
      discoveryCtx.type
    } in that category, excluding the specified ${
      discoveryCtx.type
    }. Also include the specified ${
      discoveryCtx.type
    } in the list. Your response must be in valid JSON format with the following structure:
    {
      "competitors": [
        {
          "name": "${discoveryCtx.entity}",
          "domain": "domain.com"
        },
        {
          "name": "Top Competitor 1 Name",
          "domain": "topcompetitor1.com"
        },
        {
          "name": "Top Competitor 2 Name",
          "domain": "topcompetitor2.com"
        },
        {
          "name": "Top Competitor 3 Name",
          "domain": "topcompetitor3.com"
        }
      ]
    }

    Ensure that:
    - The JSON is properly formatted and valid.
    - The "competitors" array always contains exactly 4 items (including the provided ${
      discoveryCtx.type
    } itself).
    - The first competitor is always the provided ${
      discoveryCtx.type
    } with its exact name and the main website URL (without "http://" or "https://" prefixes).
    - Each competitor is represented by an object with "name" and "domain" keys.
    - The competitor names are strings and do not include any additional information.
    - The domain URLs are the main website addresses for each brand or product.
    - If a country is specified, the top 3 competitors are the most prominent brands in that country for the specified category.
    - If no country is specified, the top 3 competitors are the most prominent brands worldwide for the specified category.
    - In both cases, exclude the provided ${discoveryCtx.type} from the top 3.

    Product category to analyze: ${discoveryCtx.category}
    Brand to exclude from top 3: ${discoveryCtx.entity}
    Country to analyze: ${discoveryCtx.market || "worldwide"}`;

    abortControllerRef.current = new AbortController();

    const payload = {
      llm: discoveryCtx.llm,
      prompt: prompt,
    };
    try {
      const response = await api.post("/llm-execute", payload, {
        signal: abortControllerRef.current.signal,
      });
      const comp = response.data.data;
      discoveryCtx.updatedCompetitors(comp.competitors);
    } catch (error) {
      console.log("Error", error.message);
    }
  }

  useEffect(() => {
    if (!discoveryCtx.category || discoveryCtx.type === "brand") return;
    if (keyPlayerChangeTimerRef.current) {
      clearTimeout(keyPlayerChangeTimerRef.current);
    }
    keyPlayerChangeTimerRef.current = setTimeout(() => {
      if(discoveryCtx.type === 'category')
      getBrandOrProductSuggestions();
      if (discoveryCtx?.category_taxonomies?.length === 0)
        generateProductTaxonomies();
    }, 1000);
  }, [
    discoveryCtx.category,
    discoveryCtx.occupation,
    discoveryCtx.location,
    discoveryCtx.attributes?.length,
  ]);

  useEffect(() => {
    if (!discoveryCtx.category) return;
    if (categoryChangeTimerRef.current) {
      clearTimeout(categoryChangeTimerRef.current);
    }

    categoryChangeTimerRef.current = setTimeout(() => {
      if(discoveryCtx.type == "category") {
        generateProductTaxonomies();
        getProductPrefs();
      } else {
        generatePreCategoryDimensions();
      }
    }, 1000);
  }, [discoveryCtx.category]);

  function tabChangeHandler(val) {
    setTabSelected(val);
  }

  useEffect(() => {
    if (location.search && location.search.includes("?id=")) {
      const id = location.search.split("?id=")[1];
      loadDiscovery(id);
    }
    if (discoveryCtx.abort_launch) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    }
  }, [discoveryCtx.abort_launch]);

  async function loadDiscovery(id) {
    try {
      setExternalReportLoading(true);
      const item = await getSingleDocFromFirebase(id, "discovery_history");
      let coreAttributeSummary = item?.core_attributes?.summary ?? "";
      if (!coreAttributeSummary) {
        item?.core_attributes &&
          item?.core_attributes?.products &&
          item?.core_attributes?.products.map((product) => {
            coreAttributeSummary += product.summary;
            coreAttributeSummary += "\n\n";
          });
      }
      discoveryCtx.updateEntireState({
        ...item,
        id: id,
        core_attributes: {
          ...item.core_attributes,
          summary: coreAttributeSummary,
        },
        is_launched: true,
      });
    } catch (error) {
      toast.error(
        "Failed to load discovery report. Please check if URL is correct"
      );
    } finally {
      setExternalReportLoading(false);
    }
  }

  useEffect(() => {
    if (!inputValues.brand || !inputValues.category) return;
    getCompetitors();

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [inputValues]);

  let coreAttributesSummary = "";
  let categoryDimensionsSummary = "";
  let productDimensionSummary = "";
  if (
    discoveryCtx?.core_attributes &&
    Object.keys(discoveryCtx?.core_attributes).length > 0
  ) {
    coreAttributesSummary = discoveryCtx.core_attributes.summary;
  }
  if (
    discoveryCtx?.category_dimensions &&
    Object.keys(discoveryCtx?.category_dimensions).length > 0
  ) {
    categoryDimensionsSummary = discoveryCtx.category_dimensions.summary;
  }

  if (
    discoveryCtx?.taxonomies &&
    Object.keys(discoveryCtx?.taxonomies).length > 0
  ) {
    productDimensionSummary = discoveryCtx.taxonomies.summary;
  }

  let competitors = discoveryCtx.competitors;

  const exportToPDF = useReactToPrint({
    contentRef: pdfRef,
    documentTitle: `${entity} Overview`,
    removeAfterPrint: true,
    pageStyle: `
      @page {
        size: 380mm 400mm;
        margin-left: 10mm;
        margin-right: 10mm;
        margin-bottom: 5mm;
        margin-top: 15mm;
      }
      @media print {
        body {
          -webkit-print-color-adjust: exact; /* Ensures accurate color printing */
        }
        .page-footer {
          position: fixed;
          bottom: 10mm;
          left: 10mm;
          right: 10mm;
          font-size: 10pt;
          color: #777;
          text-align: center;
        }
      }
    `,
  });

  const discoveryType = discoveryCtx.type || "brand";

  return (
    <>
      {externalReportLoading && <LinearProgress />}
      <section
        className={classes.content_section}
        style={{
          gridTemplateRows: discoveryType === "category" && "auto 1fr",
        }}
      >
        <Header actionText={launchText} actionVariant="text" />
        <div
          className={classes.container}
          style={{
            gridTemplateColumns: discoveryType === "category" && "1fr",
          }}
        >
          <div className={classes.content}>
            <Tabs
              options={[]}
              onChange={tabChangeHandler}
              selected={tabSelected}
            />
            <Form
              keyPlayers={keyPlayers}
              id="discovery"
              onReset={() =>
                setInputValues((prevValues) => {
                  return {
                    ...prevValues,
                    brand: "",
                    category: "",
                    market: "",
                    llm: "",
                  };
                })
              }
              onKeyPlayerChange={onKeyPlayerChange}
              onProductBrandSelection={onProductBrandSelection}
              onSubmit={(values) => {
                if (
                  (selectedBrandsProducts.length === 0 ||
                    selectedBrandsProducts.length < 4) &&
                  discoveryCtx.type === "category"
                ) {
                  toast.warn("You must choose 4 key players");
                  return;
                }
                if (launchesRemaining == 0 && !isSpecial) {
                  toast.warn(
                    `You have reached your ${launchLimitFrequency} limit`
                  );
                  return;
                }
                if (!isSpecial) updateLaunches();
                if (externalReportLoading) return;
                if (values.type !== "category") {
                  discoveryCtx.updateEntity(values.brand);
                }
                discoveryCtx.updateMarket(values.market);
                discoveryCtx.updateCategory(values.category);
                discoveryCtx.updateLLM(values.llm);
                discoveryCtx.updateType(discoveryCtx.type);
                discoveryCtx.updateName(`${values.brand} Brand Overview`);
                discoveryCtx.updatedLaunched(true);
                setInputValues(values);
              }}
              onTypeChanged={(val) => {
                setInputValues((prevVals) => {
                  return {
                    ...prevVals,
                    type: val,
                  };
                });
              }}
            />
            {selectedBrandsProducts.length > 0 && (
              <Suggestions
                options={selectedBrandsProducts}
                onDelete={onDelete}
              />
            )}
          </div>
          {discoveryType !== "category" && (
            <div className={classes.history}>
              <History filter={discoveryCtx.type} />
            </div>
          )}
        </div>
        {discoveryCtx.is_launched && (
          <div className={classes.result} id="print-pdf" ref={pdfRef}>
            <PDFHeader />
            {discoveryType !== "category" && (
              <BrandScore
                category={inputValues.category}
                market={inputValues.market}
                entity={inputValues.brand}
              />
            )}
            <div className="page-break print-only" />
            <div className={classes.section}>
              {competitors.length > 0 && (
                <Competitors
                  category={inputValues.category}
                  entity={inputValues.brand}
                />
              )}
            </div>
            {discoveryType === "brand" && discoveryCtx?.core_attributes && (
              <div className={classes.section_summary}>
                <div className={classes.flex_one}>
                  {competitors.length > 0 && (
                    <CoreAttributes category={inputValues.category} />
                  )}
                </div>
                <div className={classes.flex_one}>
                  <AIGenerated
                    edit
                    text={coreAttributesSummary}
                    loading={coreAttributesSummary === ""}
                    firebaseNode="core_attributes"
                  />
                </div>
              </div>
            )}

            {discoveryType === "category" && (
              <div className={classes.section_summary}>
                <div className={classes.flex_one}>
                  {competitors && competitors.length > 0 && (
                    <CategoryDimensions category={inputValues.category} />
                  )}
                </div>
                <div className={classes.flex_one}>
                  <AIGenerated
                    edit={discoveryType != "category"}
                    text={categoryDimensionsSummary}
                    loading={categoryDimensionsSummary === ""}
                    firebaseNode="category_dimensions"
                  />
                </div>
              </div>
            )}

            {(discoveryType === "product" || discoveryType === "category") && (
              <div className={classes.section_summary}>
                <div className={classes.flex_one}>
                  {competitors && competitors.length > 0 && <Taxonomy />}
                </div>
                <div className={classes.flex_one}>
                  <AIGenerated
                    edit={discoveryType != "category"}
                    text={productDimensionSummary}
                    loading={productDimensionSummary === ""}
                    firebaseNode="taxonomies"
                  />
                </div>
              </div>
            )}

            {discoveryType === "category" && (
              <div className={classes.product_recommendation_container}>
                {competitors && competitors.length > 0 && <ProductSpecs />}
              </div>
            )}

            <div className="page-break print-only" />
            {discoveryType != "category" && (
              <div className={classes.section_summary}>
                <div className={classes.flex_one}>
                  {competitors && competitors.length > 0 && (
                    <CategoryDimensions category={inputValues.category} />
                  )}
                </div>
                <div className={classes.flex_one}>
                  <AIGenerated
                    edit
                    text={categoryDimensionsSummary}
                    loading={categoryDimensionsSummary === ""}
                    firebaseNode="category_dimensions"
                  />
                </div>
              </div>
            )}

            {discoveryType === "category" && (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "2fr 1fr",
                  background: "white",
                  padding: "1rem",
                }}
              >
                {competitors && competitors.length > 0 && (
                  <ProductRecommendations />
                )}
              </div>
            )}

            <div className="page-break print-only" />
            {discoveryType != "category" && (
              <div className={classes.section}>
                <Sources />
              </div>
            )}

            {!discoveryCtx.loading && discoveryType != "category" && (
              <div className={`${classes.action_btn_group} no-print`}>
                <MUIButton onClick={exportToPDF}>Export</MUIButton>
              </div>
            )}
            <PDFFooter />
          </div>
        )}
      </section>
    </>
  );
}
