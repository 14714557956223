import { useContext, useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import { CircularProgress, Typography } from "@mui/material";
import lodash from "lodash";

import TextInput from "./../../Component/UI/TextInput/TextInput";
import MUIButton from "../../Component/UI/MUIButton/MUIButton";
import RadioGroup from "../../Component/UI/RadioGroup/RadioGroup";
import MUISelect from "../../Component/UI/MUISelect/MUISelect";
import classes from "./Form.module.css";
import { DiscoveryContext } from "./discovery-context";
import { LLM_OPTIONS } from "../../utils/constant";
import { validateBrandInput } from "../../utils/helpers";
import MUIChip from "./../../Component/UI/MUIChip/MUIChip";
import MUIAutocomplete from "../../Component/UI/MUIAutocomplete/MUIAutocomplete";
import useCountry from "./useCountry";
import AttributeInput from "./../CategoryDiscovery/AttributeInput/AttributeInput";
import UserKeyPlayer from "./UserKeyPlayer";

const RADIO_OPTIONS = [
  {
    value: "brand",
    label: "Brand",
  },
  {
    value: "product",
    label: "Product",
  },
  /*{
    value: "market",
    label: "Market Research",
  },*/
];

export default function Form({
  onSubmit,
  disabled,
  onTypeChanged = () => {},
  onCategoryChange = () => {},
  onKeyPlayerChange = () => {},
  onProductBrandSelection = () => {},
  showBrandInput = true,
  keyPlayers,
  onReset = () => {},
}) {
  const discoveryCtx = useContext(DiscoveryContext);
  const [loading, setLoading] = useState(false);
  const [isInputValid, setIsInputValid] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [keyPlayerInput, setKeyPlayerInput] = useState("");
  const [showManualKeyPlayerInput, setShowManualKeyPlayerInput] =
    useState(false);

  const timerRef = useRef();
  const { countries } = useCountry();

  const {
    entity: brand,
    type: discoveryType,
    llm,
    category,
    market,
    location,
    occupation,
    attributes,
  } = discoveryCtx;

  async function checkIfInputValid(input) {
    setLoading(true);
    setSuggestions([]);
    try {
      let response = await validateBrandInput(input);
      setLoading(false);
      if (response.data.ans === "Yes") {
        setIsInputValid(true);
      } else {
        setSuggestions(response?.data?.correct_inputs);
        setIsInputValid(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  function handleSuggestionClick(text) {
    discoveryCtx.updateEntity(text);
    setIsInputValid(true);
    setSuggestions([]);
  }

  function handleBrandInputChange(e) {
    setIsInputValid(false);
    discoveryCtx.updateEntity(e.target.value);
  }

  function handleCategoryChange(e) {
    discoveryCtx.updateCategory(e.target.value);
  }

  function handleDiscoveryTypeChange(value) {
    discoveryCtx.resetState();
    setIsInputValid(false);
    onTypeChanged(value);
    discoveryCtx.updateType(value);
    discoveryCtx.updateEntity("");
    discoveryCtx.updateCategory("");
    discoveryCtx.updateLLM("gpt-4o");
    onReset();
  }

  /*useEffect(() => {
    if (!brand || isInputValid || discoveryCtx.is_launched) return;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      checkIfInputValid(brand);
    }, 1000);
  }, [brand, isInputValid]);*/

  useEffect(() => {
    if (discoveryCtx.is_launched) {
      setIsInputValid(false);
    }
  }, [discoveryCtx.is_launched]);

  const debounceLaunch = lodash.debounce(function () {
    console.log("Function debounced after 1000ms!");

    if ((!brand && discoveryType !== "category") || !category || !llm) {
      toast.warn("Please fill all required fields");
      return;
    }

    if (
      discoveryType === "category" &&
      (!discoveryCtx.location ||
        !discoveryCtx.occupation ||
        discoveryCtx.attributes.length === 0)
    ) {
      toast.warn("Please fill all required fields");
      return;
    }

    if (discoveryCtx.loading) {
      discoveryCtx.updateAbortLaunch(true);
      return;
    }

    onSubmit({
      brand,
      category,
      market,
      llm,
      discoveryType,
    });
  }, 1000);

  return (
    <div className={classes.form}>
      {discoveryCtx.type !== "category" && (
        <RadioGroup
          disabled={discoveryCtx.loading || discoveryCtx.is_launched}
          className={classes.type_radio}
          options={RADIO_OPTIONS}
          onChange={handleDiscoveryTypeChange}
        />
      )}
      <div className={classes.input_group}>
        {discoveryType !== "category" && (
          <div className={classes.brand_input}>
            <TextInput
              disabled={discoveryCtx.loading || discoveryCtx.is_launched}
              fullWidth
              value={brand}
              // error={!isInputValid && brand != "" && !loading}
              label={discoveryType === "brand" ? "Brand*" : "Product*"}
              placeholder={
                discoveryType === "brand" ? "Nike" : "Nike Air Jordan 1"
              }
              onChange={handleBrandInputChange}
            />
            {loading && (
              <CircularProgress className={classes.loading} size={15} />
            )}
          </div>
        )}

        <div className="d-flex flex-column">
          <TextInput
            disabled={discoveryCtx.loading || discoveryCtx.is_launched}
            label={
              discoveryType !== "category"
                ? "Category*"
                : "What product category are you interested in?"
            }
            value={category}
            placeholder="e.g Athletic Apparel"
            onChange={handleCategoryChange}
          />
          {!category && (
            <Typography
              color="textDisabled"
              variant="caption"
              sx={{ fontSize: "8px" }}
            >
              Enter valid category
            </Typography>
          )}
        </div>

        {discoveryType !== "category" && (
          <TextInput
            label="Market"
            disabled={discoveryCtx.loading || discoveryCtx.is_launched}
            placeholder="e.g North America"
            onChange={(e) => {
              discoveryCtx.updateMarket(e.target.value);
            }}
          />
        )}
        {discoveryType !== "category" && (
          <MUISelect
            disabled={discoveryCtx.loading || discoveryCtx.is_launched}
            label={"LLM*"}
            defaultValue="gpt-4o"
            options={LLM_OPTIONS}
            onChange={(e) => {
              discoveryCtx.updateLLM(e.target.value);
            }}
          />
        )}
        {discoveryType === "category" && (
          <MUIAutocomplete
            disabled={discoveryCtx.loading || discoveryCtx.is_launched}
            onInputChange={() => {
              if (location === "United States") return;
              discoveryCtx.updateLocation("");
            }}
            optionValue={location ?? "United States"}
            label="What country are you in?"
            options={countries.length > 0 ? countries : []}
            onChange={(e) => {
              if (!e?.value) return;
              discoveryCtx.updateLocation(e?.value ?? "");
            }}
          />
        )}

        {discoveryType === "category" && (
          <div className="d-flex flex-column">
            <TextInput
              value={occupation}
              disabled={discoveryCtx.loading || discoveryCtx.is_launched}
              label="Who will be using this product?"
              placeholder="e.g Home maker"
              onChange={(e) => discoveryCtx.updateOccupation(e.target.value)}
            />
            {!occupation && (
              <Typography
                color="textDisabled"
                variant="caption"
                sx={{ fontSize: "8px" }}
              >
                Enter valid users
              </Typography>
            )}
          </div>
        )}

        {discoveryCtx.type === "category" && (
          <AttributeInput
            disabled={discoveryCtx.loading || discoveryCtx.is_launched}
            label="Attribute you care about"
            placeholder="e.g Budget (Press enter to add)"
          />
        )}

        {discoveryCtx.type === "category" && (
          <div className="d-flex flex-column">
            <MUISelect
              disabled={discoveryCtx.loading || discoveryCtx.is_launched}
              fullWidth
              optionValue={keyPlayerInput || ""}
              label="Key Players"
              options={[...keyPlayers, { name: "Add Manually", domain: "" }]}
              onChange={(e) => {
                if (e.target.value === "Add Manually") {
                  if (discoveryCtx.category.trim().length === 0) {
                    toast.warn("Please enter product category first");
                    return;
                  }
                  setShowManualKeyPlayerInput(true);
                  return;
                }
                const option = keyPlayers.find(
                  (opt) => opt.name === e.target.value
                );
                onProductBrandSelection(option);
              }}
            />
            {discoveryCtx.competitors.length <= 3 && (
              <Typography
                color="textDisabled"
                variant="caption"
                sx={{ fontSize: "8px" }}
              >
                Select key players.
                <Typography
                  color="error"
                  variant="caption"
                  sx={{ fontSize: "8px" }}
                >
                  (Exactly 4)
                </Typography>
              </Typography>
            )}
          </div>
        )}

        {showManualKeyPlayerInput && (
          <UserKeyPlayer
            open={showManualKeyPlayerInput}
            handleClose={() => setShowManualKeyPlayerInput(false)}
            handleConfirm={(val, domain) => {
              onProductBrandSelection({ name: val, domain });
              setShowManualKeyPlayerInput(false);
            }}
          />
        )}

        <div className="d-flex flex-column">
          {!discoveryCtx.loading && (
            <MUIButton
              variant="contained"
              color={discoveryCtx.loading ? "error" : "primary"}
              disabled={
                (loading || !category || discoveryCtx.is_launched)
              }
              onClick={debounceLaunch}
            >
              Launch
            </MUIButton>
          )}
          {discoveryCtx.loading && (
            <MUIButton color="error" onClick={debounceLaunch}>
              Cancel
            </MUIButton>
          )}
          {discoveryCtx.is_launched && !discoveryCtx.loading && (
            <span
              className={classes.start_new}
              onClick={() => discoveryCtx.updateAbortLaunch(true)}
            >
              Start Over
            </span>
          )}
        </div>
      </div>

      {suggestions.length > 0 && (
        <span className={classes.hint_text}>
          Did you mean
          <MUIChip
            onClick={() => handleSuggestionClick(suggestions[0])}
            className={classes.hint_chip}
            label={suggestions[0]}
          ></MUIChip>
        </span>
      )}
      <ToastContainer />
    </div>
  );
}
