import {
  combineData,
  combineObjectValues,
  splitByDayAndMonth,
  splitDataObjByDayAndMonth,
} from "./helpers";

export function brandCoreAttDataTransform(
  data,
  selectedOptionShow,
  value,
  selectedProductItems
) {
  let structuredData = {};
  const dateSeriesData = {};

  // Process each entry in the data array
  data.forEach((entry) => {
    const {
      day,
      month,
      year,
      core_attribute,
      avg_score,
      customer_llm,
      score_explanation,
    } = entry;
    const { name } = core_attribute;
    const selectedBrandOrProductName =
      selectedOptionShow === "Product"
        ? entry?.customer_brand_product_persona?.customer_brand_product?.product
            ?.name ||
          selectedProductItems[0] ||
          `${month}-${day}-${year}`
        : entry?.customer_brand_persona?.customer_brand?.brand?.name;
    const { llm, market, geography } = customer_llm;
    const { persona } =
      selectedOptionShow === "Product"
        ? entry?.customer_brand_product_persona
        : entry?.customer_brand_persona;
    const displayName =
      value === 3
        ? `${geography?.name} (${market?.name})`
        : value === 4
        ? persona?.name
        : value === 6
        ? `${month}-${day}-${year}`
        : llm?.name;

    // const displayName = `${month}-${day}-${year}`;

    if (!structuredData[day]) {
      structuredData[day] = {};
    }
    if (!dateSeriesData[day]) {
      dateSeriesData[day] = {};
    }

    structuredData[day][selectedBrandOrProductName] = {
      ...structuredData[day][selectedBrandOrProductName],
      [name]: Number(avg_score).toFixed(0),
    };
    dateSeriesData[day][displayName] = {
      ...dateSeriesData[day][displayName],
      [name]: {
        value: Number(avg_score).toFixed(0),
        explanation: score_explanation,
      },
    };
  });

  return { structuredData, dateSeriesData };
}

export function brandCategoryDimDataTransform(
  data,
  selectedOptionShow,
  value,
  selectedProductItems
) {
  let structuredData = {};
  const dateSeriesData = {};

  // Process each entry in the data array
  data.forEach((entry) => {
    const {
      day,
      month,
      year,
      category_dimension,
      avg_rating,
      customer_llm,
      explanation,
    } = entry;
    const { name } = category_dimension;
    const selectedBrandOrProductName =
      selectedOptionShow === "Product"
        ? entry?.customer_brand_product_persona?.customer_brand_product?.product
            ?.name ||
          selectedProductItems[0] ||
          `${month}-${day}-${year}`
        : entry?.customer_brand_persona?.customer_brand?.brand?.name;
    const { llm, market, geography } = customer_llm;
    const { persona } =
      selectedOptionShow === "Product"
        ? entry?.customer_brand_product_persona
        : entry?.customer_brand_persona;
    const displayName =
      value === 3
        ? `${geography?.name} (${market?.name})`
        : value === 4
        ? persona?.name
        : value === 6
        ? `${month}-${day}-${year}`
        : llm?.name;

    // const displayName = `${month}-${day}-${year}`;

    if (!structuredData[day]) {
      structuredData[day] = {};
    }
    if (!dateSeriesData[day]) {
      dateSeriesData[day] = {};
    }

    structuredData[day][selectedBrandOrProductName] = {
      ...structuredData[day][selectedBrandOrProductName],
      [name]: Number(avg_rating).toFixed(0),
    };
    dateSeriesData[day][displayName] = {
      ...dateSeriesData[day][displayName],
      [name]: { value: Number(avg_rating).toFixed(0), explanation },
    };
  });

  return { structuredData, dateSeriesData };
}

export function transformBrandIndexMultiLlmData(
  compData,
  data,
  targetIndex,
  selectedOptionShow,
  value,
  selectedProductItems,
  selectedBrandItems
) {
  const result = {};
  const structuredData = {};
  const selectedBrandOrProductName =
    selectedOptionShow === "Product"
      ? selectedProductItems[0] || "MainProduct"
      : selectedBrandItems[0] || "MainBrand";

  // Process each entry in the data array
  data.forEach((entry) => {
    const { day, month, year, customer_llm } = entry;
    const { llm, market, geography } = customer_llm;
    const { persona } =
      selectedOptionShow === "Product"
        ? entry?.customer_brand_product_persona
        : entry?.customer_brand_persona;
    const displayName =
      value === 3
        ? `${geography?.name} (${market?.name})`
        : value === 4
        ? persona?.name
        : value === 6
        ? `${month}-${day}-${year}`
        : llm?.name;

    if (!structuredData[day]) {
      structuredData[day] = {};
    }

    structuredData[day][selectedBrandOrProductName] = {
      ...structuredData[day][selectedBrandOrProductName],
      [displayName]: entry[targetIndex],
    };
  });

  // Iterate through the data array
  compData.forEach((item) => {
    const day = item.day;
    const month = item.month;
    const year = item.year;
    const brandName =
      selectedOptionShow === "Product"
        ? item.brand_product?.name
        : item.brand.name;
    const llmName = item?.customer_llm?.llm?.name;
    const { market, geography } = item?.customer_llm;
    const { persona } =
      selectedOptionShow === "Product"
        ? item?.customer_brand_product_persona
        : item?.customer_brand_persona;
    const displayName =
      value === 3
        ? `${geography?.name} (${market?.name})`
        : value === 4
        ? persona?.name
        : value === 6
        ? `${month}-${day}-${year}`
        : llmName;
    const avgScore = item[targetIndex];

    // Initialize the day object if it doesn't exist
    if (!result[day]) {
      result[day] = {};
    }

    // Initialize the brand object if it doesn't exist
    if (!result[day][brandName]) {
      result[day][brandName] = {};
    }

    // Add the core attribute and its average score to the brand object
    result[day][brandName][displayName] = Number(avgScore).toFixed(0);
  });

  let mergedData = mergeDeep({ ...result }, structuredData);
  mergedData = sortDataByMarket(
    mergedData,
    selectedBrandItems,
    selectedProductItems
  );
  return mergedData;
}

export const mergeDeep = (target, source) => {
  for (const key in source) {
    if (source[key] instanceof Object && target[key] instanceof Object) {
      // Merge subkeys
      for (const subKey in source[key]) {
        target[key][subKey] = source[key][subKey];
      }
    } else if (source[key] instanceof Object) {
      // Add new nested object
      target[key] = { ...source[key] };
    } else {
      // Assign value from source to target
      target[key] = source[key];
    }
  }
  return target;
};

export function sortDataByMarket(
  data,
  selectedBrandItems,
  selectedProductItems
) {
  const sortedData = {};

  // Sort dates in ascending order
  const dates = Object.keys(data).sort((a, b) => parseInt(a) - parseInt(b));

  // Collect all unique markets from all dates
  const allMarkets = new Set();
  dates.forEach((date) => {
    Object.values(data[date]).forEach((locations) => {
      Object.keys(locations).forEach((market) => {
        allMarkets.add(market);
      });
    });
  });

  // Sort markets with "General Market" first
  const sortedMarkets = Array.from(allMarkets).sort((a, b) => {
    if (
      a === "General Market" ||
      a === "General Persona" ||
      a === selectedBrandItems[0] ||
      a === selectedProductItems[0]
    )
      return -1;
    if (
      b === "General Market" ||
      b === "General Persona" ||
      b === selectedBrandItems[0] ||
      b === selectedProductItems[0]
    )
      return 1;
    return 0;
  });

  // Sort data according to the sorted markets
  dates.forEach((date) => {
    sortedData[date] = {};
    Object.keys(data[date]).forEach((company) => {
      sortedData[date][company] = {};
      sortedMarkets.forEach((market) => {
        if (data[date][company][market] !== undefined) {
          sortedData[date][company][market] = data[date][company][market];
        }
      });
    });
  });

  return sortedData;
}

export function calculateAverageData(data) {
  if (data) {
    const result = {};
    const dates = Object.keys(data);

    // Initialize result object with all markets and attributes
    dates.forEach((date) => {
      const markets = data[date];
      Object.keys(markets).forEach((market) => {
        if (!result[market]) {
          result[market] = {};
        }
        Object.keys(markets[market]).forEach((attribute) => {
          if (!result[market][attribute]) {
            result[market][attribute] = { sum: 0, count: 0 };
          }
        });
      });
    });

    // Accumulate sums and counts
    dates.forEach((date) => {
      const markets = data[date];
      Object.keys(markets).forEach((market) => {
        Object.keys(markets[market]).forEach((attribute) => {
          const value = parseFloat(markets[market][attribute]) || 0;

          // result[market][attribute].sum += value;
          // result[market][attribute].count += 1;

          if (value && value !== 0) {
            result[market][attribute].sum += value;
            result[market][attribute].count += 1;
          }
        });
      });
    });

    // Calculate averages
    const averages = {};
    Object.keys(result).forEach((market) => {
      averages[market] = {};
      Object.keys(result[market]).forEach((attribute) => {
        const { sum, count } = result[market][attribute];
        // averages[market][attribute] = sum / count;
        averages[market][attribute] = count > 0 ? Number(sum / count).toFixed(0) : 0;
      });
    });

    return { avg: averages };
  }
}

export function calculateAverageDataStructure(data) {
  if (data) {
    const result = {};
    const dates = Object.keys(data);

    // Initialize result object with all markets and attributes
    dates.forEach((date) => {
      const markets = data[date];
      Object.keys(markets).forEach((market) => {
        if (!result[market]) {
          result[market] = {};
        }
        Object.keys(markets[market]).forEach((attribute) => {
          if (!result[market][attribute]) {
            result[market][attribute] = {
              sum: 0,
              count: 0,
              closestExplanation: null,
              minDiff: Infinity,
            };
          }
        });
      });
    });

    // Accumulate sums, counts, and find closest explanation
    dates.forEach((date) => {
      const markets = data[date];
      Object.keys(markets).forEach((market) => {
        Object.keys(markets[market]).forEach((attribute) => {
          const attributeData = markets[market][attribute];
          const value = parseFloat(attributeData.value) || 0;

          if (value && value !== 0) {
            result[market][attribute].sum += value;
            result[market][attribute].count += 1;

            // Keep track of explanation closest to the average
            const avg =
              result[market][attribute].sum / result[market][attribute].count;
            const diff = Math.abs(value - avg);
            if (diff < result[market][attribute].minDiff) {
              result[market][attribute].minDiff = diff;
              result[market][attribute].closestExplanation =
                attributeData.explanation;
            }
          }
        });
      });
    });

    // Calculate averages and return the closest explanation
    const averages = {};
    Object.keys(result).forEach((market) => {
      averages[market] = {};
      Object.keys(result[market]).forEach((attribute) => {
        const { sum, count, closestExplanation } = result[market][attribute];
        averages[market][attribute] = {
          avgValue: count > 0 ? sum / count : 0,
          explanation: closestExplanation,
        };
      });
    });

    return { avg: averages };
  }
}

export const transformDataForAttributes = (
  data,
  value,
  selectedBrandItems,
  selectedProductItems
) => {
  if (data && data.length > 0) {
    let positiveAttributesStructuredData = {};
    let negativeAttributesStructuredData = {};

    const positiveAttributes = data.filter((obj) => obj.is_positive);
    const negativeAttributes = data.filter((obj) => !obj.is_positive);

    // Process each entry in the data array

    positiveAttributes.forEach((entry) => {
      const { day, month, year, attribute, signal_strength, customer_llm } =
        entry;
      const { name } = attribute;
      const { llm, market, geography } = customer_llm;
      const persona =
        entry?.customer_brand_persona?.persona?.name ||
        entry?.customer_brand_product_persona?.persona?.name;
      const displayName =
        value === 3
          ? `${geography?.name} (${market?.name})`
          : value === 4
          ? persona
          : value === 6
          ? `${month}-${day}-${year}`
          : llm?.name;

      if (!positiveAttributesStructuredData[day]) {
        positiveAttributesStructuredData[day] = {};
      }

      positiveAttributesStructuredData[day][displayName] = {
        ...positiveAttributesStructuredData[day][displayName],
        [name]: signal_strength,
      };
    });

    negativeAttributes.forEach((entry) => {
      const { day, month, year, attribute, signal_strength, customer_llm } =
        entry;
      const { name } = attribute;
      const { llm, market, geography } = customer_llm;
      const persona =
        entry?.customer_brand_persona?.persona?.name ||
        entry?.customer_brand_product_persona?.persona?.name;
      const displayName =
        value === 3
          ? `${geography?.name} (${market?.name})`
          : value === 4
          ? persona
          : value === 6
          ? `${month}-${day}-${year}`
          : llm?.name;

      if (!negativeAttributesStructuredData[day]) {
        negativeAttributesStructuredData[day] = {};
      }

      negativeAttributesStructuredData[day][displayName] = {
        ...negativeAttributesStructuredData[day][displayName],
        [name]: signal_strength,
      };
    });

    positiveAttributesStructuredData = sortDataByTargetKey(
      positiveAttributesStructuredData,
      selectedBrandItems,
      selectedProductItems
    );
    negativeAttributesStructuredData = sortDataByTargetKey(
      negativeAttributesStructuredData,
      selectedBrandItems,
      selectedProductItems
    );
    return {
      positiveAttributesStructuredData,
      negativeAttributesStructuredData,
    };
  }
};

export function sortDataByTargetKey(
  data,
  selectedBrandItems,
  selectedProductItems
) {
  const sortedData = {};

  const dates = Object.keys(data).sort((a, b) => parseInt(a) - parseInt(b));

  // Collect all unique markets from all dates
  const allMarkets = new Set();
  dates.forEach((date) => {
    Object.keys(data[date]).forEach((market) => {
      allMarkets.add(market);
    });
  });

  // Sort markets with "General Market" first
  const sortedMarkets = Array.from(allMarkets).sort((a, b) => {
    if (
      a === "General Market" ||
      a === "General Persona" ||
      a === selectedBrandItems[0] ||
      a === selectedProductItems[0]
    )
      return -1;
    if (
      b === "General Market" ||
      b === "General Persona" ||
      b === selectedBrandItems[0] ||
      b === selectedProductItems[0]
    )
      return 1;
    return 0;
  });

  // Sort data according to the sorted markets
  dates.forEach((date) => {
    sortedData[date] = {};
    sortedMarkets.forEach((market) => {
      if (data[date][market] !== undefined) {
        sortedData[date][market] = data[date][market];
      }
    });
  });

  return sortedData;
}

export const indexScoreChartData = (
  data,
  index,
  brandName,
  productName,
  selectedOptionShow,
  selectedProductItems,
  selectedBrandItems
) => {
  const brandData =
    selectedOptionShow === "Product" ? data?.product : data?.brand;
  const compBrandsData =
    selectedOptionShow === "Product" ? data?.compProducts : data?.compBrands;
  const selectedBrandOrProduct =
    selectedOptionShow === "Product"
      ? selectedProductItems[0] || productName
      : selectedBrandItems[0] || brandName;

  const groupByDay = (arr, keyField, valueField) => {
    return arr.reduce((acc, obj) => {
      const day = obj.day;
      const month = obj.month;
      const year = obj.year;
      const key =
        keyField === selectedBrandOrProduct
          ? keyField
          : selectedOptionShow === "Product"
          ? obj.brand_product?.name
          : obj.brand.name;
      const value = parseFloat(obj[valueField]);

      if (!acc[`${month}-${day}-${year}`]) {
        acc[`${month}-${day}-${year}`] = {};
      }

      acc[`${month}-${day}-${year}`][key] = value;
      return acc;
    }, {});
  };

  const compBrandsGrouped = compBrandsData.length
    ? groupByDay(compBrandsData, "name", index)
    : {};
  const brandGrouped = brandData.length
    ? groupByDay(brandData, selectedBrandOrProduct, index)
    : {};

  const allDays = new Set([
    ...Object.keys(compBrandsGrouped),
    ...Object.keys(brandGrouped),
  ]);

  let finalArray = Array.from(allDays).map((day) => {
    return {
      date: day, // Adding the date property
      ...(compBrandsGrouped[day] || {}),
      ...(brandGrouped[day] || {}),
    };
  });

  finalArray = finalArray.sort((a, b) => {
    // Convert the date strings into actual Date objects
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA - dateB; // Sort in ascending order
  });

  return finalArray;
};

export const handleInputValidation = (
  selectedOptionShow,
  selectedBrandItems,
  selectedProductItems,
  selectedPersona,
  selectedFrequencyItems,
  value,
  selectedSubFrequencyItems,
  selectedLlmItems,
  selectedGeographyItems,
  selectedMarketItems
) => {
  let errorMessage = "";
  if (
    selectedOptionShow === "Company/Brand" &&
    selectedBrandItems &&
    selectedBrandItems.length === 0
  ) {
    errorMessage = "Please Select a Brand !";
  } else if (
    selectedOptionShow === "Product" &&
    selectedProductItems &&
    selectedProductItems.length === 0
  ) {
    errorMessage = "Please Select a Product !";
  } else if (selectedPersona && selectedPersona.length === 0) {
    errorMessage = "Please select a Persona !";
  } else if (selectedFrequencyItems && selectedFrequencyItems.length === 0) {
    errorMessage = "Please select Frequency !";
  } else if (
    value !== 0 &&
    selectedSubFrequencyItems &&
    selectedSubFrequencyItems.length === 0
  ) {
    errorMessage = "Please Select a valid date";
  } else if (selectedLlmItems && selectedLlmItems.length === 0) {
    errorMessage = "Please select LLM !";
  } else if (selectedMarketItems && selectedMarketItems.length === 0) {
    errorMessage = "Please select Market !";
  } else if (selectedGeographyItems && selectedGeographyItems.length === 0) {
    errorMessage = "Please select Geography !";
  }

  return errorMessage;
};

export const getComparisonBrandIndexScore = (
  data,
  selectedOptionShow,
  value,
  selectedProductItems,
  selectedBrandItems
) => {
  const cumulativeCompData =
    selectedOptionShow === "Product" ? data?.compProducts : data?.compBrands;
  const cumulativebrandOrProductData =
    selectedOptionShow === "Product" ? data?.product : data?.brand;
  const brandIndexData = transformBrandIndexMultiLlmData(
    cumulativeCompData,
    cumulativebrandOrProductData,
    "avg_brand_index_score",
    selectedOptionShow,
    value,
    selectedProductItems,
    selectedBrandItems
  );

  const brandFavData = transformBrandIndexMultiLlmData(
    cumulativeCompData,
    cumulativebrandOrProductData,
    "avg_brand_favorability_score",
    selectedOptionShow,
    value,
    selectedProductItems,
    selectedBrandItems
  );

  const brandReachData = transformBrandIndexMultiLlmData(
    cumulativeCompData,
    cumulativebrandOrProductData,
    "avg_brand_reach_score",
    selectedOptionShow,
    value,
    selectedProductItems,
    selectedBrandItems
  );

  return { brandIndexData, brandFavData, brandReachData };
};

export const transformedGraphData = (data) => {
  if (data && data.length > 0) {
    const filterData = data.filter(
      (obj) => obj?.attribute?.attribute_category !== null
    );

    const positiveAttributesData = filterData.filter((obj) => obj?.is_positive);
    const negativeAttributesData = filterData.filter(
      (obj) => !obj?.is_positive
    );

    const processAttributesData = (attributesData) => {
      return attributesData.reduce((acc, item) => {
        const categoryName = item?.attribute?.attribute_category?.category_name;
        const signalStrength = Number(item?.signal_strength);
        const attributeName = item?.attribute?.name;

        // Find existing category in the accumulator
        const existingCategory = acc.find((cat) => cat?.name === categoryName);

        if (existingCategory) {
          const attributeIndex =
            existingCategory.altNames.indexOf(attributeName);

          if (attributeIndex !== -1) {
            // If the attribute already exists, update total and count for averaging
            existingCategory.signalStrengths[attributeIndex].total +=
              signalStrength;
            existingCategory.signalStrengths[attributeIndex].count += 1;
          } else {
            // Add new attribute and initialize total and count
            existingCategory.altNames.push(attributeName);
            existingCategory.signalStrengths.push({
              total: signalStrength,
              count: 1,
            });
          }

          // Recalculate the maximum value based on the average
          const maxAvgSignalStrength = Math.max(
            ...existingCategory.signalStrengths.map(
              (entry) => entry.total / entry.count
            )
          );
          existingCategory.value = maxAvgSignalStrength.toFixed(0);
        } else {
          // Create a new category entry
          acc.push({
            name: categoryName,
            value: signalStrength.toFixed(0),
            altNames: [attributeName],
            signalStrengths: [{ total: signalStrength, count: 1 }],
          });
        }

        return acc;
      }, []);
    };

    const positiveAttResult = processAttributesData(positiveAttributesData);
    const negativeAttResult = processAttributesData(negativeAttributesData);

    return { positiveAttResult, negativeAttResult };
  }
};

export const transformDataForAttributesSources = (
  data,
  sourceFilter,
  selectedBrandItems,
  selectedProductItems
) => {
  if (data && data.length > 0) {
    let positiveAttributesStructuredData = {};
    let negativeAttributesStructuredData = {};

    const positiveAttributes = data.filter((obj) => obj.is_positive);
    const negativeAttributes = data.filter((obj) => !obj.is_positive);

    // Process each entry in the data array

    positiveAttributes.forEach((entry) => {
      const { day, month, year, source, signal_strength, customer_llm } = entry;
      const { domain_url } = source;
      const { llm, market, geography } = customer_llm;
      const persona =
        entry?.customer_brand_persona?.persona?.name ||
        entry?.customer_brand_product_persona?.persona?.name;
      const displayName = sourceFilter?.value === "LLM" ? llm?.name : persona;

      if (!positiveAttributesStructuredData[day]) {
        positiveAttributesStructuredData[day] = {};
      }

      positiveAttributesStructuredData[day][displayName] = {
        ...positiveAttributesStructuredData[day][displayName],
        [domain_url]: signal_strength,
      };
    });

    negativeAttributes.forEach((entry) => {
      const { day, month, year, source, signal_strength, customer_llm } = entry;
      const { domain_url } = source;
      const { llm, market, geography } = customer_llm;
      const persona =
        entry?.customer_brand_persona?.persona?.name ||
        entry?.customer_brand_product_persona?.persona?.name;
      const displayName = sourceFilter?.value === "LLM" ? llm?.name : persona;
      if (!negativeAttributesStructuredData[day]) {
        negativeAttributesStructuredData[day] = {};
      }

      negativeAttributesStructuredData[day][displayName] = {
        ...negativeAttributesStructuredData[day][displayName],
        [domain_url]: signal_strength,
      };
    });

    positiveAttributesStructuredData = sortDataByTargetKey(
      positiveAttributesStructuredData,
      selectedBrandItems,
      selectedProductItems
    );
    negativeAttributesStructuredData = sortDataByTargetKey(
      negativeAttributesStructuredData,
      selectedBrandItems,
      selectedProductItems
    );

    return {
      positiveAttributesStructuredData,
      negativeAttributesStructuredData,
    };
  }
};

export const transformData = (
  data,
  index,
  brandName,
  productName,
  selectedOptionShow,
  selectedBrandItems,
  selectedProductItems
) => {
  const brandData =
    selectedOptionShow === "Product" ? data?.product : data?.brand;
  const compBrandsData =
    selectedOptionShow === "Product" ? data?.compProducts : data?.compBrands;
  const brand = selectedBrandItems[0] || brandName;
  const product = selectedProductItems[0] || productName;
  const selectedBrandOrProduct =
    selectedOptionShow === "Product" ? product : brand;

  const groupByDay = (arr, keyField, valueField) => {
    return arr.reduce((acc, obj) => {
      const day = obj.day;
      const key =
        keyField === selectedBrandOrProduct
          ? keyField
          : selectedOptionShow === "Product"
          ? obj.brand_product?.name
          : obj.brand.name;
      const value = parseFloat(obj[valueField]).toFixed(0);

      if (!acc[day]) {
        acc[day] = {};
      }

      acc[day][key] = Number(value);
      return acc;
    }, {});
  };

  const compBrandsGrouped = compBrandsData.length
    ? groupByDay(compBrandsData, "name", index)
    : {};
  const brandGrouped = brandData.length
    ? groupByDay(brandData, selectedBrandOrProduct, index)
    : {};

  const allDays = new Set([
    ...Object.keys(compBrandsGrouped),
    ...Object.keys(brandGrouped),
  ]);

  const finalArray = Array.from(allDays).map((day) => {
    return {
      ...(compBrandsGrouped[day] || {}),
      ...(brandGrouped[day] || {}),
    };
  });

  return finalArray;
};

export const getCompBrandIndexScoreAvgObj = (
  obj,
  brandName,
  productName,
  selectedOptionShow,
  selectedBrandItems,
  selectedProductItems
) => {
  const brandFavArray = transformData(
    obj,
    "avg_brand_favorability_score",
    brandName,
    productName,
    selectedOptionShow,
    selectedBrandItems,
    selectedProductItems
  );
  const brandReachArray = transformData(
    obj,
    "avg_brand_reach_score",
    brandName,
    productName,
    selectedOptionShow,
    selectedBrandItems,
    selectedProductItems
  );

  // const avgIndex = calculateAverageIndex(brandIndexArray);
  const avgFav = calculateAverageIndex(brandFavArray);
  const avgReach = calculateAverageIndex(brandReachArray);
  const avgIndex = combineObjectValues(avgReach, avgFav);

  return {
    "Brand Index Score": avgIndex,
    Favorability: avgFav,
    Rank: avgReach,
  };
};

export const calculateAverageIndex = (arr) => {
  const totals = {};
  const counts = {};

  // Iterate over each object in the array
  arr.forEach((obj) => {
    for (const key in obj) {
      if (totals[key]) {
        totals[key] += obj[key];
        counts[key] += 1;
      } else {
        totals[key] = obj[key];
        counts[key] = 1;
      }
    }
  });

  // Calculate the averages
  const averages = {};
  for (const key in totals) {
    averages[key] = Number(totals[key] / counts[key]).toFixed(0);
  }

  return averages;
};

export function transformDataForCoreAttributes(
  compData,
  data,
  brandName,
  productName,
  selectedBrandItems,
  selectedProductItems,
  selectedOptionShow
) {
  // Initialize the result object

  const result = {};
  const structuredData = {};
  const brand = selectedBrandItems[0] || brandName;
  const product = selectedProductItems[0] || productName;
  const selectedBrandOrProductName =
    selectedOptionShow === "Product" ? product : brand;

  // Process each entry in the data array
  data.forEach((entry) => {
    const { day, month, year, core_attribute, avg_score, score_explanation } =
      entry;
    const { name } = core_attribute;

    if (!structuredData[day]) {
      structuredData[day] = {};
    }

    structuredData[day][selectedBrandOrProductName] = {
      ...structuredData[day][selectedBrandOrProductName],
      [name]: {
        value: Number(avg_score).toFixed(0),
        explanation: score_explanation,
      },
    };
  });

  // Iterate through the data array
  compData.forEach((item) => {
    const day = item.day;
    const month = item.month;
    const year = item.year;
    const brandName =
      selectedOptionShow === "Product"
        ? item?.brand_product?.name || "test"
        : item.brand.name;
    const coreAttributeName = item.core_attribute.name;
    const avgScore = item?.avg_score;
    const explanation = item?.score_explanation;

    // Initialize the day object if it doesn't exist
    if (!result[day]) {
      result[day] = {};
    }

    // Initialize the brand object if it doesn't exist
    if (!result[day][brandName]) {
      result[day][brandName] = {};
    }

    // Add the core attribute and its average score to the brand object
    result[day][brandName][coreAttributeName] = {
      value: Number(avgScore).toFixed(0),
      explanation,
    };
  });

  let mergedData = mergeDeep({ ...result }, structuredData);
  mergedData = sortDataByTargetKey(
    mergedData,
    selectedBrandItems,
    selectedProductItems
  );
  return mergedData;
}

export function transformDataForCompAttributes(
  compData,
  data,
  selectedOptionShow,
  selectedBrandItems,
  selectedProductItems
) {
  const positiveAttributesData = data.filter((obj) => obj.is_positive);
  const positiveCompAttributesData = compData.filter((obj) => obj.is_positive);
  const negativeAttributesData = data.filter((obj) => !obj.is_positive);
  const negativeCompAttributesData = compData.filter((obj) => !obj.is_positive);
  // Initialize the result object
  const result = {};
  const structuredData = {};
  const negativeAttributesResult = {};
  const negativeAttributesStructuredData = {};

  const brandName = selectedBrandItems[0] || "MainBrand";
  const productName = selectedProductItems[0] || "MainProduct";
  const selectedBrandOrProductName =
    selectedOptionShow === "Product" ? productName : brandName;

  // Process each entry in the positive attributes data array
  positiveAttributesData.forEach((entry) => {
    const { day, attribute, signal_strength } = entry;
    const { name } = attribute;

    if (!structuredData[day]) {
      structuredData[day] = {};
    }

    structuredData[day][selectedBrandOrProductName] = {
      ...structuredData[day][selectedBrandOrProductName],
      [name]: signal_strength,
    };
  });

  // Iterate through the positive comp attributes data array
  positiveCompAttributesData.forEach((item) => {
    const day = item.day;
    const brandName =
      selectedOptionShow === "Product"
        ? item?.brand_product?.name || "test"
        : item?.brand?.name;
    const attributeName = item.attribute.name;
    const signalStrength = item.signal_strength;

    // Initialize the day object if it doesn't exist
    if (!result[day]) {
      result[day] = {};
    }

    // Initialize the brand object if it doesn't exist
    if (!result[day][brandName]) {
      result[day][brandName] = {};
    }

    // Add the attribute and its signal strength to the brand object
    result[day][brandName][attributeName] = signalStrength;
  });

  // Process each entry in the positive attributes data array
  negativeAttributesData.forEach((entry) => {
    const { day, attribute, signal_strength } = entry;
    const { name } = attribute;

    if (!negativeAttributesStructuredData[day]) {
      negativeAttributesStructuredData[day] = {};
    }

    negativeAttributesStructuredData[day][selectedBrandOrProductName] = {
      ...negativeAttributesStructuredData[day][selectedBrandOrProductName],
      [name]: signal_strength,
    };
  });

  // Iterate through the positive comp attributes data array
  negativeCompAttributesData.forEach((item) => {
    const day = item.day;
    const brandName =
      selectedOptionShow === "Product"
        ? item?.brand_product?.name || "test"
        : item?.brand?.name;
    const attributeName = item.attribute.name;
    const signalStrength = item.signal_strength;

    // Initialize the day object if it doesn't exist
    if (!negativeAttributesResult[day]) {
      negativeAttributesResult[day] = {};
    }

    // Initialize the brand object if it doesn't exist
    if (!negativeAttributesResult[day][brandName]) {
      negativeAttributesResult[day][brandName] = {};
    }

    // Add the attribute and its signal strength to the brand object
    negativeAttributesResult[day][brandName][attributeName] = signalStrength;
  });

  let mergedData = mergeDeep({ ...result }, structuredData);
  let negativeAttributesMergedData = mergeDeep(
    { ...negativeAttributesResult },
    negativeAttributesStructuredData
  );
  mergedData = sortDataByTargetKey(
    mergedData,
    selectedBrandItems,
    selectedProductItems
  );
  negativeAttributesMergedData = sortDataByTargetKey(
    negativeAttributesMergedData,
    selectedBrandItems,
    selectedProductItems
  );

  return { mergedData, negativeAttributesMergedData };
}

export function transformDataForCategoryDim(
  compData,
  data,
  brandName,
  productName,
  selectedOptionShow,
  selectedBrandItems,
  selectedProductItems
) {
  // Initialize the result object

  const result = {};
  const structuredData = {};
  const brand = selectedBrandItems[0] || brandName;
  const product = selectedProductItems[0] || productName;
  const selectedBrandOrProductName =
    selectedOptionShow === "Product" ? product : brand;

  // Process each entry in the data array
  data.forEach((entry) => {
    const { day, month, year, category_dimension, avg_rating, explanation } =
      entry;
    const { name } = category_dimension;

    if (!structuredData[day]) {
      structuredData[day] = {};
    }

    structuredData[day][selectedBrandOrProductName] = {
      ...structuredData[day][selectedBrandOrProductName],
      [name]: { value: Number(avg_rating).toFixed(0), explanation },
    };
  });

  // Iterate through the data array
  compData.forEach((item) => {
    const day = item.day;
    const month = item.month;
    const year = item.year;
    const brandName =
      selectedOptionShow === "Product"
        ? item?.brand_product?.name || "test"
        : item.brand.name;
    const categoryDimName = item?.category_dimension?.name;
    const avgRating = item?.avg_rating;
    const explanation = item?.explanation;

    // Initialize the day object if it doesn't exist
    if (!result[day]) {
      result[day] = {};
    }

    // Initialize the brand object if it doesn't exist
    if (!result[day][brandName]) {
      result[day][brandName] = {};
    }

    // Add the core attribute and its average score to the brand object
    result[day][brandName][categoryDimName] = {
      value: Number(avgRating).toFixed(0),
      explanation,
    };
  });

  let mergedData = mergeDeep({ ...result }, structuredData);
  mergedData = sortDataByTargetKey(
    mergedData,
    selectedBrandItems,
    selectedProductItems
  );
  return mergedData;
}

export function processSourceData(
  data,
  isCoreAtt,
  selectedOptionShow,
  sourceFilter,
  selectedBrandItems,
  selectedProductItems
) {
  let ratingSourceResult = {};
  let sourceResult = {};

  const processEntry = (entry, resultObject) => {
    const { day, month, year, source, signal_strength, customer_llm } = entry;
    const { domain_url } = source;
    const { llm, market, geography } = customer_llm;
    const persona =
      selectedOptionShow === "Product"
        ? entry?.customer_brand_product_persona.persona.name
        : entry?.customer_brand_persona.persona.name;
    const displayName = sourceFilter?.value === "LLM" ? llm?.name : persona;

    if (!resultObject[day]) {
      resultObject[day] = {};
    }

    resultObject[day][displayName] = {
      ...resultObject[day][displayName],
      [domain_url]: signal_strength,
    };
  };

  if (isCoreAtt) {
    if (Array.isArray(data)) {
      data.forEach((entry) => processEntry(entry, ratingSourceResult));
    }

    ratingSourceResult = sortDataByMarket(
      ratingSourceResult,
      selectedBrandItems,
      selectedProductItems
    );

    return { ratingSourceResult };
  }

  const dataArray = data?.llmCategoryDimensionsRatingSource;
  const sourceDataArray = data?.llmCategoryDimensionsSource;

  if (Array.isArray(dataArray)) {
    dataArray.forEach((entry) => processEntry(entry, ratingSourceResult));
  }

  if (Array.isArray(sourceDataArray)) {
    sourceDataArray.forEach((entry) => processEntry(entry, sourceResult));
  }

  ratingSourceResult = sortDataByMarket(
    ratingSourceResult,
    selectedBrandItems,
    selectedProductItems
  );
  sourceResult = sortDataByMarket(
    sourceResult,
    selectedBrandItems,
    selectedProductItems
  );

  return { ratingSourceResult, sourceResult };
}

export const getCompetitorMetricData = (
  result,
  selectedOptionShow,
  selectedBrandItems,
  selectedProductItems,
  brandName,
  productName,
  selectedSubFrequencyItems
) => {
  let cumulativeCompData =
    selectedOptionShow === "Product"
      ? result?.coreAttributescumulativeData?.compProducts
      : result?.coreAttributescumulativeData?.compBrands;
  let cumulativebrandOrProductData =
    selectedOptionShow === "Product"
      ? result?.coreAttributescumulativeData?.product
      : result?.coreAttributescumulativeData?.brand;
  cumulativeCompData = splitByDayAndMonth(
    result?.realPastDate?.day,
    result?.realPastDate?.month,
    result?.realPastDate?.year,
    cumulativeCompData,
    selectedSubFrequencyItems === "Current"
  );

  cumulativebrandOrProductData = splitByDayAndMonth(
    result?.realPastDate?.day,
    result?.realPastDate?.month,
    result?.realPastDate?.year,
    cumulativebrandOrProductData,
    selectedSubFrequencyItems === "Current"
  );

  const data = transformDataForCoreAttributes(
    cumulativeCompData?.filteredData,
    cumulativebrandOrProductData?.filteredData,
    brandName,
    productName,
    selectedBrandItems,
    selectedProductItems,
    selectedOptionShow
  );

  const remainingData = transformDataForCoreAttributes(
    cumulativeCompData?.remainingData,
    cumulativebrandOrProductData?.remainingData,
    brandName,
    productName,
    selectedBrandItems,
    selectedProductItems,
    selectedOptionShow
  );

  let cumulativeCompCategoryData =
    selectedOptionShow === "Product"
      ? result?.categoryDimCumulativeData?.compProducts
      : result?.categoryDimCumulativeData?.compBrands;
  let cumulativebrandOrProductCategoryData =
    selectedOptionShow === "Product"
      ? result?.categoryDimCumulativeData?.product
      : result?.categoryDimCumulativeData?.brand;
  cumulativeCompCategoryData = splitByDayAndMonth(
    result?.realPastDate?.day,
    result?.realPastDate?.month,
    result?.realPastDate?.year,
    cumulativeCompCategoryData,
    selectedSubFrequencyItems === "Current"
  );

  cumulativebrandOrProductCategoryData = splitByDayAndMonth(
    result?.realPastDate?.day,
    result?.realPastDate?.month,
    result?.realPastDate?.year,
    cumulativebrandOrProductCategoryData,
    selectedSubFrequencyItems === "Current"
  );

  const categoryDimdata = transformDataForCategoryDim(
    cumulativeCompCategoryData?.filteredData,
    cumulativebrandOrProductCategoryData?.filteredData,
    brandName,
    productName,
    selectedOptionShow,
    selectedBrandItems,
    selectedProductItems
  );

  const pastCategoryDimData = transformDataForCategoryDim(
    cumulativeCompCategoryData?.remainingData,
    cumulativebrandOrProductCategoryData?.remainingData,
    brandName,
    productName,
    selectedOptionShow,
    selectedBrandItems,
    selectedProductItems
  );

  const cumulativeCompAttributesData =
    selectedOptionShow === "Product"
      ? result?.attributescumulativeData?.compProducts
      : result?.attributescumulativeData?.compBrands;
  const cumulativebrandOrProductAttributesData =
    selectedOptionShow === "Product"
      ? result?.attributescumulativeData?.product
      : result?.attributescumulativeData?.brand;

  const attributesData = transformDataForCompAttributes(
    cumulativeCompAttributesData,
    cumulativebrandOrProductAttributesData,
    selectedOptionShow,
    selectedBrandItems,
    selectedProductItems
  );

  return {
    data,
    attributesData,
    pastCategoryDimData,
    categoryDimdata,
    remainingData,
  };
};

export const getBrandProductOverviewData = (
  result,
  selectedOptionShow,
  value,
  selectedSubFrequencyItems,
  selectedProductItems
) => {
  let cumulativebrandOrProductData =
    selectedOptionShow === "Product"
      ? result?.coreAttributescumulativeData?.product
      : result?.coreAttributescumulativeData?.brand;
  cumulativebrandOrProductData = splitByDayAndMonth(
    result?.realPastDate?.day,
    result?.realPastDate?.month,
    result?.realPastDate?.year,
    cumulativebrandOrProductData,
    selectedSubFrequencyItems === "Current"
  );
  const data = brandCoreAttDataTransform(
    cumulativebrandOrProductData?.filteredData,
    selectedOptionShow,
    value,
    selectedProductItems
  );

  const remainingData = brandCoreAttDataTransform(
    cumulativebrandOrProductData?.remainingData,
    selectedOptionShow,
    value,
    selectedProductItems
  );

  let cumulativeCategoryDimData =
    selectedOptionShow === "Product"
      ? result?.categoryDimCumulativeData?.product
      : result?.categoryDimCumulativeData?.brand;
  cumulativeCategoryDimData = splitByDayAndMonth(
    result?.realPastDate?.day,
    result?.realPastDate?.month,
    result?.realPastDate?.year,
    cumulativeCategoryDimData,
    selectedSubFrequencyItems === "Current"
  );
  const categoryDimData = brandCategoryDimDataTransform(
    cumulativeCategoryDimData?.filteredData,
    selectedOptionShow,
    value,
    selectedProductItems
  );

  const pastCategoryDimData = brandCategoryDimDataTransform(
    cumulativeCategoryDimData?.remainingData,
    selectedOptionShow,
    value,
    selectedProductItems
  );

  let cumulativebrandOrProductAttributesData =
    selectedOptionShow === "Product"
      ? result?.attributescumulativeData?.product
      : result?.attributescumulativeData?.brand;

  cumulativebrandOrProductAttributesData = splitByDayAndMonth(
    result?.realPastDate?.day,
    result?.realPastDate?.month,
    result?.realPastDate?.year,
    cumulativebrandOrProductAttributesData,
    selectedSubFrequencyItems === "Current"
  );

  const attDataForGraph = transformedGraphData(
    cumulativebrandOrProductAttributesData?.filteredData
  );

  const pastAttDataForGraph = transformedGraphData(
    cumulativebrandOrProductAttributesData?.remainingData
  );

  return {
    data,
    remainingData,
    categoryDimData,
    pastCategoryDimData,
    attDataForGraph,
    pastAttDataForGraph,
  };
};

export const getComparisonDashboardData = (
  result,
  result2,
  selectedBrandItems,
  selectedProductItems,
  value,
  selectedSubFrequencyItems,
  selectedOptionShow,
  brands,
  brandProducts
) => {
  const data = transformDataForAttributes(
    result?.cumulativeData,
    value,
    selectedBrandItems,
    selectedProductItems
  );

  const rawIndexScoreData = splitDataObjByDayAndMonth(
    result2?.realPastDate?.day,
    result2?.realPastDate?.month,
    result2?.realPastDate?.year,
    result2?.brandIndexcumulativeData,
    selectedSubFrequencyItems === "Current"
  );

  let currentBrandIndexScore = getComparisonBrandIndexScore(
    rawIndexScoreData?.filteredData,
    selectedOptionShow,
    value,
    selectedProductItems,
    selectedBrandItems
  );

  let pastBrandIndexScore = getComparisonBrandIndexScore(
    rawIndexScoreData?.remainingData,
    selectedOptionShow,
    value,
    selectedProductItems,
    selectedBrandItems
  );

  let cumulativebrandOrProductCoreAttData =
    selectedOptionShow === "Product"
      ? result2?.coreAttributescumulativeData?.product
      : result2?.coreAttributescumulativeData?.brand;

  cumulativebrandOrProductCoreAttData = splitByDayAndMonth(
    result2?.realPastDate?.day,
    result2?.realPastDate?.month,
    result2?.realPastDate?.year,
    cumulativebrandOrProductCoreAttData,
    selectedSubFrequencyItems === "Current"
  );
  const coreAttdata = brandCoreAttDataTransform(
    cumulativebrandOrProductCoreAttData?.filteredData,
    selectedOptionShow,
    value,
    selectedProductItems
  );
  const pastCoreAttdata = brandCoreAttDataTransform(
    cumulativebrandOrProductCoreAttData?.remainingData,
    selectedOptionShow,
    value,
    selectedProductItems
  );

  let cumulativebrandOrProductCategoryDimData =
    selectedOptionShow === "Product"
      ? result2?.categoryDimCumulativeData?.product
      : result2?.categoryDimCumulativeData?.brand;

  cumulativebrandOrProductCategoryDimData = splitByDayAndMonth(
    result2?.realPastDate?.day,
    result2?.realPastDate?.month,
    result2?.realPastDate?.year,
    cumulativebrandOrProductCategoryDimData,
    selectedSubFrequencyItems === "Current"
  );

  const categoryDimdata = brandCategoryDimDataTransform(
    cumulativebrandOrProductCategoryDimData?.filteredData,
    selectedOptionShow,
    value,
    selectedProductItems
  );
  const pastCategoryDimdata = brandCategoryDimDataTransform(
    cumulativebrandOrProductCategoryDimData?.remainingData,
    selectedOptionShow,
    value,
    selectedProductItems
  );

  const brandIndexFromFavAndRach = combineData(
    calculateAverageData(currentBrandIndexScore?.brandFavData)?.["avg"],
    calculateAverageData(currentBrandIndexScore?.brandReachData)?.["avg"]
  );

  const pastBrandIndexFromFavAndRach = combineData(
    calculateAverageData(pastBrandIndexScore?.brandFavData)?.["avg"],
    calculateAverageData(pastBrandIndexScore?.brandReachData)?.["avg"]
  );

  const selectedBrandCategory = brands?.data?.data?.filter(
    (data) => data?.brand?.name === selectedBrandItems[0]
  )[0]?.category?.name;

  const selectedBrandProductCategory = brandProducts?.data?.data?.filter(
    (data) => data?.brand?.name === selectedProductItems[0]
  )[0]?.customer_brand_product_categories[0]?.category?.name;

  return {
    data,
    coreAttdata,
    pastCoreAttdata,
    categoryDimdata,
    pastCategoryDimdata,
    brandIndexFromFavAndRach,
    pastBrandIndexFromFavAndRach,
    selectedBrandCategory,
    selectedBrandProductCategory,
    currentBrandIndexScore,
    pastBrandIndexScore,
  };
};
