import { useEffect, useState } from "react";
import axios from "axios";

const FALLBACK_IMAGE_URL =
  "https://tse1.mm.bing.net/th?id=OIP.WK5-vaL-Km-aumD0JGCp1QHaHa&w=474&h=474&c=7";

export default function useBingImage(entity, category) {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");

  async function generateImage() {
    const searchTerm = `${category}: ${entity} professional Image`;
    try {
      let response = await axios.get(
        `https://api.bing.microsoft.com/v7.0/images/search?q=${searchTerm}&count=2&aspect=tall&size=large&imageType=photo&license=All&color=ColorOnly&safeSearch=Moderate&mkt=en-US`,
        {
          headers: {
            "Ocp-Apim-Subscription-Key":
              process.env.REACT_APP_BING_SEARCH_API_KEY,
          },
        }
      );
      if (response.status === 200) {
        const data = response.data;
        if (data?.value && data?.value.length > 0) {
          setImage(data.value[0].contentUrl);
        } else {
          setImage(FALLBACK_IMAGE_URL);
        }
      } else {
        setImage(FALLBACK_IMAGE_URL);
      }
    } catch (error) {
      console.log(`Error: generating image ${error.message}`);
      setImage(FALLBACK_IMAGE_URL);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!entity) return;
    generateImage();
  }, [entity, category]);

  return { loading, image };
}
