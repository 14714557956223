import React, { useEffect, useContext, useState, useRef } from "react";
import { Skeleton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { DiscoveryContext } from "../discovery-context";
import MUICard from "./../../../Component/UI/MUICard/MUICard";
import CompetitorItem from "./CompetitorItem";
import classes from "./Competitors.module.css";
import ErrorView from "../../../Component/ErrorView/ErrorView";
import api from "../../../BrandOnBoarding/api";
import ProductItem from "../../CategoryDiscovery/Products/ProductItem";
import MUIIconButton from "../../../Component/UI/MUIIconButton/MUIIconButton";
import useClassifyProduct from "./../../CategoryDiscovery/Products/useClassifyProduct";
import useCustomerReviews from './../../CategoryDiscovery/CustomerReviews/useCustomerReviews';

export default function Competitors({ category, brand }) {
  const [error, setError] = useState();
  const containerRef = useRef(null);

  const abortControllersRef = useRef([]);
  const discoveryCtx = useContext(DiscoveryContext);
  const { classifiedProducts } = useClassifyProduct();
  const { loading: reviewsLoading, reviews } = useCustomerReviews();

  let competitors = discoveryCtx.competitors
    .slice(discoveryCtx.type == "category" ? 0 : 1, 4)
    .map((c) => c.name);

  useEffect(() => {
    if (discoveryCtx.brand_scores.length > 0) return;
    getBrandIndexScores();

    return () => {
      //abortControllersRef.current.forEach((controller) => controller.abort());
    };
  }, [discoveryCtx.brand_scores.length, competitors.length]);

  useEffect(() => {
    if (discoveryCtx.abort_launch) {
      abortControllersRef.current.forEach((controller) => controller.abort());
    }
  }, [discoveryCtx.abort_launch]);

  function createPromise(product, index) {
    const controller = new AbortController();
    abortControllersRef.current[index] = controller;

    const payload = {
      llm: discoveryCtx.llm,
      category: category,
      count: 5,
      brand: discoveryCtx.type === "brand" ? "companies" : "products",
      persona: "General Profile",
      return_meta: true,
      rank_exec_count: discoveryCtx.type === "category" ? 1 : 5,
      sentiment_exec_count: discoveryCtx.type === "category" ? 1 : 5,
      market: discoveryCtx.market,
      product: product,
    };
    return api.post("/metrics/v4/get-brand-index-score", payload, {
      signal: controller.signal,
    });
  }

  async function getBrandIndexScores() {
    setError(null);
    const promiseArr = [];
    abortControllersRef.current = [];

    try {
      // for (let competitor of competitors) {
      //   if (discoveryCtx.llm === "Perplexity") {
      //     promiseArr.push(
      //       new Promise((res) => setTimeout(() => res(""), 60000))
      //     );
      //   }
      //   promiseArr.push(createPromise(competitor));
      // }
      const promiseArr = competitors.map((competitor, index) => {
        if (discoveryCtx.llm === "Perplexity") {
          return new Promise((res) => setTimeout(() => res(""), 60000));
        }
        return createPromise(competitor, index);
      });

      promiseArr.forEach((promise, index) => {
        promise
          .then((result) => {
            discoveryCtx.updateBrandScores({
              data: { ...result.data.data[0], extras: result.data.extras },
              index: index + (discoveryCtx.type === "category" ? 0 : 1),
            });
          })
          .catch((error) => {
            console.error(`Promise ${index + 1} rejected:`, error);
            discoveryCtx.updateBrandScores({
              data: error?.response?.data,
              index: index + (discoveryCtx.type === "category" ? 0 : 1),
            });
          });
      });
    } catch (error) {
      setError(error.message);
    }
  }

  async function BrandIndexRetryHandler(index) {
    console.log(competitors[index]);
    discoveryCtx.updateBrandScores({ data: null, index: index + 1 });
    const promiseArr = [];
    promiseArr.push(createPromise(competitors[index]));

    try {
      const [response] = await Promise.all(promiseArr);
      discoveryCtx.updateBrandScores({
        data: { ...response.data.data[0], extras: response.data.extras },
        index: index + 1,
      });
    } catch (error) {
      discoveryCtx.updateBrandScores({
        data: error?.response?.data,
        index: index + 1,
      });
    }
  }

  let brandData = discoveryCtx.brand_scores;

  const scroll = (direction) => {
    if (containerRef.current) {
      const scrollAmount = 480;
      const newScrollPosition =
        direction === "next"
          ? containerRef.current.scrollLeft + scrollAmount
          : containerRef.current.scrollLeft - scrollAmount;
      containerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <MUICard
      title={
        discoveryCtx.type === "category" ? "Products" : "Competitor Analysis"
      }
      variant="elevated"
      elevation={0}
      className={classes.card}
      titleClassName={classes.title}
      width={"100%"}
    >
      {discoveryCtx.type === "category" && (
        <>
          <MUIIconButton
            className={`${classes.chevron_btn} ${classes.prev}`}
            onClick={() => scroll("prev")}
          >
            <ArrowBackIosNewIcon />
          </MUIIconButton>
          <MUIIconButton
            className={`${classes.chevron_btn} ${classes.next}`}
            onClick={() => scroll("next")}
          >
            <ArrowForwardIosIcon />
          </MUIIconButton>
        </>
      )}
      <div
        ref={containerRef}
        style={{ position: "relative" }}
        className={
          discoveryCtx.type === "category"
            ? classes.products_container
            : classes.container
        }
      >
        {!error &&
          competitors.map((competitor, index) => {
            return (
              <React.Fragment key={index}>
                {discoveryCtx.type === "category" ? (
                  <ProductItem
                    classifiedProducts={classifiedProducts}
                    index={index}
                    name={competitor}
                    brandIndex={
                      brandData[
                        index + (discoveryCtx.type === "category" ? 0 : 1)
                      ]
                    }
                    reviews={reviews[index]}
                    reviewLoading={reviewsLoading}
                  />
                ) : (
                  <React.Fragment key={index}>
                    {brandData[index + 1] ? (
                      <CompetitorItem
                        competitor={competitor}
                        brandIndex={brandData[index + 1]}
                        brand={brand}
                        index={index}
                        onRetry={BrandIndexRetryHandler}
                      />
                    ) : (
                      <Skeleton
                        variant="rounded"
                        style={{
                          flexGrow: 1,
                          height: 220,
                          flexBasis: "calc(50% - 12.5px)",
                        }}
                      />
                    )}
                    {index < competitors.length - 1 && (
                      <div className={classes.separator}></div>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            );
          })}
        {error && (
          <div className={classes.error}>
            <ErrorView
              title={"Error fetching competitor analysis"}
              message={error}
              retry
              onRetry={() => getBrandIndexScores()}
            />
          </div>
        )}
      </div>
    </MUICard>
  );
}
