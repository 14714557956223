import { useContext, useEffect, useRef } from "react";

import { DiscoveryContext } from "./discovery-context";
import api from "../../BrandOnBoarding/api";

export default function useGenerateProductTaxonomies() {
  const discoveryCtx = useContext(DiscoveryContext);
  const abortControllerRef = useRef();

  useEffect(() => {
    if (discoveryCtx.abort_launch) {
      discoveryCtx.updateCategoryTaxonomies([]);
    }
  }, [discoveryCtx.abort_launch]);

  async function generateProductTaxonomies() {
    abortControllerRef.current = new AbortController();
    try {
      const response = await api.post(
        "/metrics/product-dimensions",
        {
          llm: "gpt-4o",
          category: discoveryCtx.category,
        },
        {
          signal: abortControllerRef.current.signal,
        }
      );
      discoveryCtx.updateCategoryTaxonomies(response.data.data);
    } catch (error) {
      console.error("Error getting taxonomies:", error);
    }
  }

  return {
    custom_taxonomies: discoveryCtx.category_taxonomies,
    generateProductTaxonomies,
  };
}
