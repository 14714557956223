import { useEffect, useState, useContext } from "react";

import MUICard from "./../../../Component/UI/MUICard/MUICard";
import classes from "./History.module.css";
import HistoryItem from "./HistoryItem";
import { fetchDataFromFirebase } from "../../../DatabaseFirebase/firebaseService";
import LoadingSkeleton from "./LoadingSkeleton";
import EmptyView from "./../../../Component/EmptyView/EmptyView";
import { AuthContext } from "../../../context/auth-context";

export default function History({ filter = "brand" }) {
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState([]);
  const [allHistory, setAllHistory] = useState([]);
  const [filterOption, setFilterOption] = useState(filter);

  useEffect(() => {
    setFilterOption(filter);
  }, [filter]);

  const { authUserEmail } = useContext(AuthContext);

  function processDiscoveryHistory(data) {
    const historyArr = [];
    for (let key in data) {
      historyArr.push({
        id: key,
        ...data[key],
      });
    }
    return historyArr
      .filter((item) => item.authUserEmail === authUserEmail)
      .sort((a, b) => {
        if (!a.date) return 1;
        if (!b.date) return -1;
        return b.date - a.date;
      });
  }

  useEffect(() => {
    if(allHistory.length === 0) return;
    if (filterOption === "all") {
      setHistory(allHistory);
    } else {
      const filteredHistory = allHistory.filter(
        (item) => item.type === filterOption
      );
      setHistory(filteredHistory);
    }
  }, [filterOption, allHistory.length]);

  useEffect(() => {
    setLoading(true);
    fetchDataFromFirebase((data) => {
      const processedHistory = processDiscoveryHistory(data);

      setTimeout(() => {
        setHistory(
          processedHistory.filter((item) => item.type === filterOption)
        );
        setAllHistory(processedHistory);
        setLoading(false);
      }, 100);
    }, "discovery_history");
  }, []);

  return (
    <MUICard variant="elevated" elevation={0} className={classes.card}>
      <div className={classes.header}>
        <span className={classes.title}>History</span>
      </div>
      {!loading && history.length > 0 && (
        <div className={classes.container}>
          {history.map((historyItem) => {
            return <HistoryItem key={historyItem.id} item={historyItem} />;
          })}
        </div>
      )}
      {loading && <LoadingSkeleton />}
      {!loading && history.length === 0 && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70%" }}
        >
          <EmptyView message={"Nothing here."} />
        </div>
      )}
    </MUICard>
  );
}
