import React, { useContext, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import HistoryIcon from "@mui/icons-material/History";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import _ from "lodash";

import api from "../../BrandOnBoarding/api";
import LLM from "./LLM";
import classes from "./PromptEngine.module.css";
import Summary from "./Summary/Summary";
import { useDailyLimits } from "../../hooks/useDailyLimits";
import MUIButton from "../../Component/UI/MUIButton/MUIButton";
import Modal from "./../../Component/Modal/Modal";
import History from "./History/History";
import { AuthContext } from "../../context/auth-context";
import Form from "./Form";

const LLMs = [
  {
    id: 1,
    name: "GPT-4o",
    model: "gpt-4o",
    url: "https://brandluminaire.eastus.cloudapp.azure.com/api/llm-execute",
    img: "https://cdn.oaistatic.com/assets/apple-touch-icon-mz9nytnj.webp",
    text: "",
    messages: [
      {
        role: "system",
        content: "Hello! How can I assist you today?",
      },
    ],
  },
  {
    id: 2,
    name: "Gemini-1.5",
    model: "Gemini",
    url: "https://brandluminaire.eastus.cloudapp.azure.com/api/llm-execute",
    img: "https://www.gstatic.com/lamda/images/gemini_sparkle_v002_d4735304ff6292a690345.svg",
    text: "",
    messages: [
      {
        role: "model",
        content: "Hello! How can I assist you today?",
      },
    ],
  },
  {
    id: 3,
    name: "Claude-3.5",
    model: "Claude3",
    url: "https://brandluminaire.eastus.cloudapp.azure.com/api/llm-execute",
    img: "https://claude.ai/images/claude_app_icon.png",
    text: "",
    messages: [
      {
        role: "assistant",
        content: "Hello! How can I assist you today?",
      },
    ],
  },
  {
    id: 4,
    name: "Perplexity (llama-3.1)",
    model: "Perplexity",
    url: "https://brandluminaire.eastus.cloudapp.azure.com/api/llm-execute",
    img: "https://mintlify.s3-us-west-1.amazonaws.com/perplexity/_generated/favicon/favicon-32x32.png?v=3",
    text: "",
    messages: [
      {
        role: "system",
        content: "Hello How can I assist you today?",
      },
    ],
  },
];

export default function Dashboard() {
  const [messageList, setMessageList] = useState(_.cloneDeep(LLMs));
  const [userPrompt, setUserPrompt] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [isExistingChat, setIsExistingChat] = useState(false);
  const [conversationId, setConversationId] = useState(null);

  const { user } = useContext(AuthContext);
  const dataArrivedRef = useRef(false);

  const {
    launchText,
    launchesRemaining,
    updateLaunches,
    isSpecial,
    frequency: launchLimitFrequency,
  } = useDailyLimits("PromptEngine");

  const { pathname } = useLocation();

  async function getConversationName(messageList) {
    let firstConversation = messageList[0].slice(messageList[0].length - 2);
    let message = ``;
    firstConversation.forEach((item, index) => {
      message += `Message ${index + 1}: ${item.content}\n`;
    });

    const prompt = `Based on these two messages. Name this conversation in 3 to 5 words
      ${message}
      Provide response in following JSON format
      {
        data: NAME_OF_CONVERSATION (string)
      }
    `;

    const payload = {
      llm: "gpt-4o",
      prompt: prompt,
    };
    const response = await api.post("/llm-execute", payload);

    return response.data.data.data;
  }

  async function createNewConversation(messageList) {
    const title = await getConversationName(messageList);
    const response = await api.post("/llm-conversations", {
      userId: user.id,
      title: title,
    });
    setConversationId(response.data.mainConversation.id);
    setConversations(response.data.subConversations);
    const subConvo = response.data.subConversations;
    prepareAndStoreMessages(messageList, subConvo);
  }

  async function prepareAndStoreMessages(messageList, subConvo) {
    const conversations = [];

    messageList.forEach((list, index) => {
      conversations.push({
        id: subConvo[index].id,
        messages: list.map((msg) => {
          return {
            aiAssistantId: index + 1,
            isUserMessage: msg.role === "user",
            content: msg.content,
          };
        }),
      });
    });

    for (let i = 0; i < conversations.length; i++) {
      await addNewMessage(conversations[i].id, {
        messages: conversations[i].messages,
      });
    }
  }

  function addNewMessage(id, payload) {
    return api.post(`/llm-conversations/${id}`, payload);
  }

  useEffect(() => {
    resetConversation();
  }, [pathname]);

  const handleSend = (e) => {
    e.preventDefault();
    if (launchesRemaining <= 0 && !isSpecial) {
      toast.warn(`You have reached your ${launchLimitFrequency} limit`);
      return;
    }

    if (!userPrompt) {
      toast.warn("Please enter query");
      return;
    }

    if (!isSpecial) updateLaunches();
    setLoading(true);

    const newMessageList = _.cloneDeep(messageList);

    newMessageList.forEach((llm) => {
      llm.messages.push({
        id: crypto.randomUUID(),
        role: "user",
        content: userPrompt,
      });
    });
    setMessageList(newMessageList);
    setUserPrompt("");
    dataArrivedRef.current = false;
  };

  function resetConversation() {
    if (isLoading) return;
    setMessageList(_.cloneDeep(LLMs));
    setConversationId(null);
    setConversations([]);
  }

  function handleMessageReceived(llmName, message) {
    const newMessageList = [...messageList];
    const llmIndex = newMessageList.findIndex((llm) => llm.name === llmName);
    newMessageList[llmIndex].messages.push(message);
    setMessageList(newMessageList);
  }

  function historyItemSelectHandler(item) {
    setConversationId(item.id);
    setIsExistingChat(true);
    setConversations(item.subConversations.map((i) => ({ id: i.id })));
    setMessageList((previousList) => {
      const updatedList = _.cloneDeep(previousList);
      updatedList.map((list) => {
        const subConvo = item.subConversations.find(
          (conv) => conv.llm_messages[0].ai_assistant_id === list.id
        );
        list.messages = subConvo.llm_messages.map((msg, index) => {
          return {
            id: msg.id,
            role: msg.is_user_message
              ? "user"
              : index == 0
              ? msg.ai_assistant_id === 3
                ? "assistant"
                : "system"
              : "assistant",
            content: msg.content,
          };
        });
      });
      return updatedList;
    });
    setShowHistory(false);
  }

  const messages = messageList.map((list) => list.messages);

  useEffect(() => {
    if (messages.every((msg) => msg.length === 0)) {
      setLoading(false);
      return;
    }
    const isDataArrived = messages.every(
      (msgs) => msgs.length > 0 && msgs[msgs.length - 1].role === "assistant"
    );
    if (isDataArrived && !dataArrivedRef.current) {
      dataArrivedRef.current = true;
      if (
        messages.every((list) => list.length === 3) &&
        conversations.length === 0
      ) {
        createNewConversation(messages);
      } else if (!isExistingChat) {
        const messageList = messages.map((msgList) => {
          return msgList.slice(msgList.length - 2);
        });
        prepareAndStoreMessages(messageList, conversations);
      }
      if (isExistingChat) {
        setIsExistingChat(false);
      }

      setLoading(false);
    }
  }, [messages]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <ToastContainer />
        <Modal
          title="History"
          width={800}
          height="70%"
          open={showHistory}
          handleClose={() => setShowHistory(false)}
          handleConfirm={() => setShowHistory(false)}
        >
          <History
            onItemSelected={historyItemSelectHandler}
            onDelete={resetConversation}
          />
        </Modal>
        <div className={classes.header}>
          <div>
            <MUIButton
              className={classes.history}
              variant="outlined"
              startIcon={<DriveFileRenameOutlineOutlinedIcon />}
              onClick={resetConversation}
            >
              New Chat
            </MUIButton>
            <span>&nbsp;</span>
            <MUIButton
              className={classes.history}
              variant="outlined"
              startIcon={<HistoryIcon />}
              onClick={() => setShowHistory(true)}
            >
              History
            </MUIButton>
          </div>
          <span className={classes.action_txt}>{launchText}</span>
        </div>
        <Form
          onSubmit={handleSend}
          prompt={userPrompt}
          isLoading={isLoading}
          setPrompt={(val) => setUserPrompt(val)}
        />
        <div className={classes.grid}>
          {messageList.map((llm) => (
            <LLM key={llm.name} llm={llm} onMessage={handleMessageReceived} />
          ))}
        </div>
      </div>

      <Summary
        messages={messageList.map((list) => ({
          msgs: list.messages,
          llm: list.name,
        }))}
        conversationId={conversationId}
        enabled={conversations.length > 0}
        loading={isLoading}
      />
    </div>
  );
}
